import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { PICTURE_URL } from "../../../helpers/api.routes";
import { ISelect } from "../../../shared/interface";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { IForm } from "../interface";
import { AdminEventService } from "../service";

export const CreateForm = ({
  state,
  setImage,
  categories,
  setState,
}: IForm) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const fileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files && setImage(e.target.files[0]);
  };

  const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const selectHandler = (e: ISelect | null) => {
    setState((prev) => ({ ...prev, category: e!.value }));
  };

  const mappedData = categories?.map((category: any) => ({
    label: category.name,
    value: category.id,
  }));

  function handleDate(date: Date) {
    const value = new Date(date);
    value.setHours(12);
    setStartDate(date);
    setState((prev) => ({ ...prev, date: value as unknown as string }));
  }
  useEffect(() => {
    if (!!Number(id)) {
      AdminEventService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;

          setState({ ...data, category: data.category.id });
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6} className="mb-3">
            <label htmlFor="name" className="required-field">
              {t("global.title")}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              required
              onChange={(e) => changeHandler(e)}
              value={state?.id && state.title}
            />
          </Col>
          <Col md={6} className="mb-3">
            <label htmlFor="name" className="required-field">
              {t("global.location")}
            </label>
            <input
              type="text"
              name="location"
              className="form-control"
              required
              onChange={(e) => changeHandler(e)}
              value={state?.id && state.location}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="category" className="required-field">
              {t("table_headers.category")}
            </label>
            <Select
              className="react-select-field"
              onChange={selectHandler}
              options={mappedData}
              value={mappedData?.find(
                (category) => category.value === state.category
              )}
            />
          </Col>

          <Col md={8}>
            <label htmlFor="url" className="required-field">
              {t("table_headers.url")}
            </label>
            <input
              type="text"
              name="url"
              className="form-control"
              required
              onChange={(e) => changeHandler(e)}
              value={state?.id && state.url}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="date" className="required-field">
              {t("global.date")}
            </label>
            <div className="datepicker-container">
              <DatePicker
                className="date-picker"
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                value={state.date}
                onChange={(date) => handleDate(date as Date)}
                isClearable
                placeholderText="Choose date..."
              />
            </div>
          </Col>
          <Col md={2} className="form-check form-switch p-0">
            <label
              className="d-block text-start"
              htmlFor="flexSwitchCheckDefault"
            >
              {t("global.active")}
            </label>
            <div className="row">
              <input
                className="form-check-input switch-custom ms-4 mt-2"
                type="checkbox"
                name="isActive"
                checked={state.isActive}
                onChange={switchHandler}
                role="switch"
                id="flexSwitchCheckDefault"
              />
            </div>
          </Col>
        </Row>

        <div className="mt-3">
          <label htmlFor="imageFile"> {t("download.image")}</label>
          <input
            onChange={fileHandler}
            className="form-control"
            type="file"
            accept="image/*"
            id="imageFile"
          />
        </div>
        {state?.image && (
          <div className="col-md-6 py-3">
            <img
              className="rounded"
              alt="200x200"
              width={200}
              src={PICTURE_URL + state?.image.imagePath}
              data-holder-rendered="true"
            />
          </div>
        )}

        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                navigate("/admin/event");
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
