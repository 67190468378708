import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {MagazineSettingsService} from "./service";
import "../../assets/scss/EditorStyle.css";
import {InitEditorMulti} from "../../shared/tinyMce/InitEditorMulti";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
// @ts-ignore
import Editor from "ckeditor5-custom-build/build/ckeditor";
interface IState {
    text: string;
}


export const MagazineSettings = () => {
    const [state, setState] = useState<IState>({
        text: "",
    });
    const {t} = useTranslation();
    const [selectEditor, setSelectEditor] = useState<boolean>(false);
    const submitHandler = (e: any) => {
        e.preventDefault();
        MagazineSettingsService.update(state.text)
            .then((response) => {
                SuccessToast(t(`toasts.successfully_created`));
            })
            .catch((error) => ErrorToast(error));
    };

    const handleText = (text: string) => {
        setState((prev: any) => ({...prev, text}));
    };

    useEffect(() => {
        MagazineSettingsService.get()
            .then((response) => {
                const {data} = response;
                setState(data);
                InitEditorMulti(data?.text ?? undefined, handleText, ".text");
            })
            .catch((error) => ErrorToast(error));
    }, []);

    function removeElementsByClass(className: string) {
        const elements = document.getElementsByClassName(className);
        while (elements.length > 0) {
            elements && elements[0]?.parentNode?.removeChild(elements[0]);
        }
        setSelectEditor(false);
    }

    useEffect(() => {
        if (selectEditor) {
            removeElementsByClass(
                "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
            );
        }
    }, [selectEditor]);
    return (
        <div className="card">
            <div className="card-body">
                <Row>
                    <Col md={12} className="mb-3" id="custom-editor">
                        <div>
                            <label htmlFor="title" className="required-field">
                                {t("global.text")}
                            </label>
                        </div>
                        <textarea
                            className="editor text"
                            onChange={console.log}
                        ></textarea>
                    </Col>
                </Row>
                <Row>
                    <div className="col-12 mt-4 d-flex justify-content-end text-align-center">
                        <button
                            className="btn btn-primary"
                            onClick={(e) => submitHandler(e)}
                        >
                            {t("global.save")}
                        </button>
                    </div>
                </Row>
            </div>
        </div>
    );
};
