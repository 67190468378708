import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CreateForm } from "./CreateForm";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { AdminNewsletterEmailService } from "../service";

export const UpdateNewsletterEmail = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState<any>();
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const [password, setPassword] = useState("");

  const submitHandler = (event: React.FormEvent, id?: number) => {
    if (state?.id) {
      AdminNewsletterEmailService.update(state)
        .then((response) => {
          SuccessToast(t(`newsletter_email.successfully_created`));
          navigate(
            `/admin/newsletter-email/${
              state?.active ? "registered" : "deregistered"
            }`
          );
          setTriggerUpdate(!triggerUpdate);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    }
  };

  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e, state?.id);
        }}
      >
        <CreateForm
          state={state}
          setState={setState}
          setPassword={setPassword}
        />
      </Form>
    </div>
  );
};
