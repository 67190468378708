import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IProfile } from "../dto/IProfile";
// import { IAllStates } from "../../../store/rootReducer";
// import { getCorrectLanguage } from "../../../shared/functions/Functions";
import Select, { SingleValue } from "react-select";
// import { themeSelect } from "../../../shared/components/Select2";

interface IPersonalDetailFormProps {
  state: IProfile | undefined;
  changeHandler: (
    event: React.ChangeEvent<HTMLInputElement>,
    isContact?: boolean
  ) => void;
}
export const PersonalDetail = ({
  state,
  changeHandler,
}: IPersonalDetailFormProps) => {
  const { t } = useTranslation();
  // const { country } = useSelector((state: IAllStates) => state.enums);

  return (
    <div className="row">
      <div className="col-md-6 my-2">
        <label htmlFor="firstName" className="required-field">
          {t("users.firstName")}
        </label>
        <input
          type="text"
          name="firstName"
          required
          value={state?.firstName}
          // placeholder={t("users.firstName")}
          onChange={changeHandler}
          className="form-control"
        />
      </div>
      <div className="col-md-6 my-2">
        <label htmlFor="lastName">{t("users.lastName")}</label>
        <input
          type="text"
          name="lastName"
          value={state?.lastName}
          onChange={changeHandler}
          // placeholder={t("users.lastName")}
          className="form-control"
        />
      </div>
      <div className="col-md-6 my-2">
        <label htmlFor="email" className="required-field">
          {t("users.email")}
        </label>
        <input
          type="text"
          name="email"
          value={state?.email}
          required
          // placeholder={t("users.email")}
          onChange={changeHandler}
          className="form-control"
        />
      </div>
      <div className="col-md-6 my-2">
        <label htmlFor="phone">{t("users.phone")}</label>
        <input
          type="tel"
          name="phone"
          value={state?.phone}
          // placeholder={t("users.phone")}
          onChange={(e) => changeHandler(e, true)}
          className="form-control"
        />
      </div>
      <div className="col-md-3 my-2">
        <label htmlFor="city">{t("users.city")}</label>
        <input
          type="text"
          name="city"
          value={state?.city}
          // placeholder={t("users.city")}
          onChange={(e) => changeHandler(e, true)}
          className="form-control"
        />
      </div>

      <div className="col-md-3 my-2">
        <label htmlFor="fax">{t("global.postCode")}</label>
        <input
          type="text"
          name="postCode"
          value={state?.postCode}
          // placeholder={t("users.postCode")}
          onChange={(e) => changeHandler(e, true)}
          className="form-control"
        />
      </div>
      <div className="col-md-3 my-2">
        <label htmlFor="street">{t("global.street")}</label>
        <input
          type="text"
          name="street"
          value={state?.street}
          // placeholder={t("users.address")}
          onChange={(e) => changeHandler(e, true)}
          className="form-control"
        />
      </div>
      <div className="col-md-3 my-2">
        <label htmlFor="fax">{t("global.fax")}</label>
        <input
          type="text"
          name="fax"
          value={state?.fax}
          // placeholder={t("users.fax")}
          onChange={(e) => changeHandler(e, true)}
          className="form-control"
        />
      </div>
      <div className="col-12 my-2 d-flex justify-content-end">
        <Link to="/app">
          <button className="btn btn-info">{t("global.cancel")}</button>
        </Link>
        <button className="btn btn-primary ms-3" type="submit">
          {t("global.update")}
        </button>
      </div>
    </div>
  );
};
