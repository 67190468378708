import { MARKET_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminMarketService = {
  getAll: (params: any) => requests.get(`${MARKET_URL}`, params),
  create: (params: any) => requests.post(`${MARKET_URL}`, params),
  getSingle: (id: number) => requests.get(`${MARKET_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.put(`${MARKET_URL}/${id}`, params),
  delete: (id: number) => requests.delete(`${MARKET_URL}/${id}`),
  updatePosition: (params: any) => requests.patch(`${MARKET_URL}`, params),
};
