import { CreateForm } from "./CreateForm";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";

import { useLocation, useNavigate } from "react-router-dom";

import { AdminNewsletterEmailService } from "../service";

export const CreateNewsletterEmail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>();
  const submitHandler = () => {
    AdminNewsletterEmailService.create({
      ...state,
      active: state?.active ?? false,
    })
      .then((response) => {
        SuccessToast(t(`newsletter_email.successfully_created`));
        navigate(`/admin/newsletter-email/registered`);
        setTriggerUpdate(!triggerUpdate);
        setState({});
      })
      .catch((error) => ErrorToast(error));
  };
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm state={state} setState={setState} />
      </Form>
    </div>
  );
};
