import { MENU_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminMenuService = {
  getAll: (params: any) => requests.get(`${MENU_URL}`, params),
  create: (params: any) => requests.post(`${MENU_URL}`, params),
  getSingle: (id: number) => requests.get(`${MENU_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.put(`${MENU_URL}/${id}`, params),
  updatePosition: (params: any) => requests.patch(`${MENU_URL}`, params),
  delete: (id: number) => requests.delete(`${MENU_URL}/${id}`),
};
