import { combineReducers } from "@reduxjs/toolkit";
import { enumSlice } from "./enum.slice";
import { projectVersionSlice } from "./project-version.slice";
import { themeSlice } from "./theme.slice";
import { authSlice } from "./auth.slice";
import { companySlice } from "./company.slice";
import { numberNewsletterSlice } from "./numberNewsletter.slice";

export const rootReducer = combineReducers({
  enum: enumSlice.reducer,
  theme: themeSlice.reducer,
  auth: authSlice.reducer,
  company: companySlice.reducer,
  projectVersion: projectVersionSlice.reducer,
  numberNewsletter: numberNewsletterSlice.reducer,
});
