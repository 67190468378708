import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorToast } from "../../shared/toasters/toasters";
import { AdminNewsLetterService } from "./service";
import warningIcon from "../../shared/icons/warningIcon.json";
import { Button, Modal } from "react-bootstrap";
import { AdminNewsLetterCreate } from "./steps/adminNewsletterStep1";
import { AdminNewsletterStep2 } from "./steps/adminNewsletterStep2";
import AdminNewsletterStep3 from "./steps/adminNewsletterStep3";
import AdminNewsletterStep4 from "./steps/adminNewsletterStep4";
import Lottie from "lottie-react";
import { NewsletterSettingsService } from "./adminNewsletterSettings/service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { numberNewsletterSlice } from "../../store/slices/numberNewsletter.slice";

interface AdminBannerProps {}

interface BannerItem {
  // Define the properties of a newsletter item
  // Adjust these based on your actual data structure
  id: number;
  // ... other properties
}

interface BannerListResponse {
  items: BannerItem[];
  totalItems: number;
  perPage: number;
}

interface IState {
  subject: string;
  misterText: string;
  test: false;
  breakingNews: false;
  womanText: string;
  othersText: string;
  news: any[];
}

const AdminNewsLetter: React.FC<AdminBannerProps> = () => {
  const numberOfNewsletter = useSelector(
    (state: any) => state.numberNewsletter
  );
  const { t } = useTranslation();
  const [count, setCount] = useState({});
  const navigate = useNavigate();
  const [selectedNews, setSelectedNews] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const [sentNumber, setSentNumber] = useState<number>(0);
  const [state, setState] = useState<any>({ news: [], subject: "" });
  const [step, setStep] = useState(1);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const changeStep = (next: boolean) => {
    if (
      step === 2 &&
      state.subject &&
      state.textMan &&
      state.textWoman &&
      state.textOther
    ) {
      setStep((prev) => (next ? prev + 1 : prev - 1));
    }

    if (step !== 2) {
      setStep((prev) => (next ? prev + 1 : prev - 1));
    }
  };
  const sendNewsLetter = () => {
    AdminNewsLetterService.create({
      ...state,
      misterText: state?.textMan,
      womanText: state?.textWoman,
      othersText: state?.textOther,
    })
      .then((response) => {
        const { data } = response;
        changeStep(true);
        setSentNumber(data?.newsletterInfo?.totalCreated as unknown as number);
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    setState((prev: any) => ({ ...prev, news: selectedNews }));
  }, [selectedNews]);

  return (
    <>
      <div className="card-body">
        <ul
          className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <a
              className={`nav-link ${step === 1 && "active"}`}
              data-bs-toggle="tab"
              // href="#home1"
              role="tab"
              // aria-selected="true"
              aria-disabled="true"
            >
              Step 1
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className={`nav-link ${step === 2 && "active"}`}
              // data-bs-toggle="tab"
              // href="#profile1"
              role="tab"
              aria-selected="false"
              tabIndex={-1}
              aria-disabled="true"
            >
              Step 2
            </a>
          </li>
          <li
            className="nav-item"
            // onClick={() => previewHandler()}
            role="presentation"
          >
            <a
              className={`nav-link ${step === 3 && "active"}`}
              // data-bs-toggle="tab"
              // href="#messages1"
              role="tab"
              aria-selected="false"
              tabIndex={-1}
              aria-disabled="true"
            >
              Step 3
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              // data-bs-toggle="tab"
              href="#settings1"
              role="tab"
              aria-selected="false"
              tabIndex={-1}
              aria-disabled="true"
            >
              Step 4
            </a>
          </li>
        </ul>
        {/* Tab panes */}
        <div className="card">
          <form onSubmit={(e: any) => e.preventDefault()}>
            <div className="tab-content text-muted">
              <div
                className={`tab-pane ${step === 1 && "active"}`}
                id="home1"
                role="tabpanel"
              >
                <AdminNewsLetterCreate
                  setSelectedNews={setSelectedNews}
                  // selectedNews={selectedNews}
                  state={state}
                  setState={setState}
                />
              </div>
              <div
                className={`tab-pane ${step === 2 && "active"}`}
                id="profile1"
                role="tabpanel"
              >
                <AdminNewsletterStep2
                  count={count}
                  setCount={setCount}
                  setSelectedNews={setSelectedNews}
                  selectedNews={selectedNews}
                  state={state}
                  setState={setState}
                  step={step}
                />
              </div>
              <div
                className={`tab-pane ${step === 3 && "active"}`}
                id="messages1"
                role="tabpanel"
              >
                <AdminNewsletterStep3
                  count={count}
                  state={state}
                  step={step}
                  changeStep={changeStep}
                  handleShow={handleShow}
                />
              </div>
              <div
                id="settings1"
                role="tabpanel"
                className={`tab-pane ${step === 4 && "active"}`}
              >
                <AdminNewsletterStep4 sentNumber={sentNumber} />
              </div>
              {step === 4 ? (
                <>
                  <div className="d-flex p-3 justify-content-between">
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate("/app")}
                    >
                      {t("errors.back_to_dashboard")}
                    </button>

                    <button
                      className="btn btn-success ms-auto"
                      onClick={() => {
                        window.location.reload();
                        // setStep(1);
                        // setState({});
                        // setSelectedNews([]);
                      }}
                    >
                      {t("newsletter.create_new_newsletter")}
                    </button>
                  </div>
                </>
              ) : (
                <div className="d-flex p-3 justify-content-between">
                  {step !== 1 && (
                    <button
                      className="btn btn-primary"
                      onClick={() => changeStep(false)}
                    >
                      {t("global.back")}
                    </button>
                  )}

                  {step !== 4 && (
                    <button
                      className="btn btn-primary ms-auto"
                      onClick={() =>
                        step === 3 ? handleShow() : changeStep(true)
                      }
                    >
                      {t("global.next")}
                    </button>
                  )}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Body className="d-flex align-items-center flex-column">
          <Lottie
            animationData={warningIcon}
            loop={true}
            style={{ width: 100 }}
          />
          <h5>{t("modals.are_you_sure")}</h5>
          <p className="text-center text-muted modal-title-break">
            {t(`modals.newsletter_message_1`)}
          </p>
          <p className="text-center text-muted modal-title-break">
            {t(`modals.newsletter_message_2`)}
          </p>
          Number for send: {numberOfNewsletter}
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end gap-2">
          <Button variant="light" size="sm" onClick={handleClose}>
            {t(`modals.close`)}
          </Button>
          <Button
            variant="warning"
            size="sm"
            onClick={() => {
              sendNewsLetter();
              handleClose();
            }}
          >
            {t(`modals.send_newsletter`)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminNewsLetter;
