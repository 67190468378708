import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import _debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";
import { AdminNewsLetterService } from "../service";
import {
  ErrorToast,
  SuccessToast,
} from "../../../../../shared/toasters/toasters";
import { Loader } from "../../../../../shared/components/Loader";
import { EmptyState } from "../../../../../shared/components/EmptyState";
import { Pagination } from "../../../../../shared/paginations/Paginations";

export const List = ({
  list,
  totalPages,
  page,
  handlePages,
  setSelectedNews,
  state,
  setState,
  setPagination,
}: any) => {
  const { t } = useTranslation();
  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setPagination((prev: any) => ({
        ...prev,
        term: value,
        page: 1,
      }));
    },
    200
  );
  const checkboxHandler = (e: any, item: any) => {
    if (e.target.checked) {
      setState((prev: any) => ({ ...prev, news: [...prev?.news, item] }));
      setSelectedNews((prev: any) => [...prev, item]);
    } else {
      setState((prev: any) => ({
        ...prev,
        news: prev.news.filter((prev: any) => prev.id !== item.id),
      }));
      setSelectedNews((prev: any) =>
        prev.filter((prev: any) => prev.id !== item.id)
      );
    }
  };

  return (
    <div className="">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="card-title mb-0">
            {t("newsletter.listOfNewsletters")}
          </h4>
          <div className="search-box">
            <input
              style={{ height: "38px" }}
              type="text"
              className="form-control"
              id="searchMemberList"
              onChange={(e: any) => changeFilterHandler(e)}
              placeholder={t("global.search")}
            />
            <i className="ri-search-line search-icon"></i>
          </div>
        </div>
      </div>
      <div className="card-body">
        {list == undefined ? (
          <Loader />
        ) : list.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr className="">
                  <th scope="col">{t(`table_headers.id`)}</th>
                  <th scope="col">{t(`table_headers.title`)}</th>

                  <th scope="col">{t(`table_headers.series`)}</th>
                  <th scope="col">{t(`table_headers.sent`)}</th>
                  <th scope="col">{t(`table_headers.actions`)}</th>
                </tr>
              </thead>
              <tbody>
                {list?.map((prompt: any) => {
                  return (
                    <tr key={prompt?.id} className="">
                      <td>
                        <Link to="#" className="fw-medium">
                          #{prompt?.id}
                        </Link>
                      </td>
                      <td>{prompt?.title ?? "-"}</td>
                      <td>{prompt?.series ?? "-"}</td>
                      <td>
                        {prompt?.mailed ? (
                          <i className="ri-check-double-line fs-2"></i>
                        ) : (
                          "-"
                        )}
                      </td>

                      <td>
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="form-check mb-3">
                            <input
                              onChange={(event) =>
                                checkboxHandler(event, prompt)
                              }
                              className="form-check-input"
                              checked={state?.news?.some(
                                (news: any) => news.id === prompt?.id
                              )}
                              type="checkbox"
                              id="formCheck6"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}

        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      </div>
    </div>
  );
};
