import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { AdminProductCategoryService } from "./service";
import { CreateForm } from "./components/CreateForm";
import { useTranslation } from "react-i18next";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";

export const AdminProductCategory = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>({ isActive: false });
  const [list, setList] = useState<any>(undefined);
  const [productCategoryId, setProductCategoryId] = useState(0);
  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminProductCategoryService.updatePosition({ ids: newListUpdated });
  };

  const deleteHandler = () => {
    selectedPrompt?.id &&
      AdminProductCategoryService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`event_cat.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };

  const editHandler = (brandId: number) => {
    setIsMenuOpened(true);
    setProductCategoryId(brandId);
    AdminProductCategoryService.getSingle(brandId)
      .then((response) => {
        const { data } = response;
        setState(data);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };
  const submitHandler = () => {
    if (state.id) {
      AdminProductCategoryService.update(state, state.id)
        .then((response) => {
          SuccessToast(t(`event_cat.successfully_updated`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    } else {
      AdminProductCategoryService.create(state)
        .then((response) => {
          SuccessToast(t(`event_cat.successfully_created`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    AdminProductCategoryService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;
        setList(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate]);
  return (
    <>
      {!isMenuOpened && (
        <div className="col-12">
          <div className="card">
            <div className="card-body d-flex align-items-center justify-content-between">
              <h4 className="card-title mg-b-0">
                {t("product.create_new_category")}
              </h4>
              <button
                className="btn btn-primary"
                onClick={() => setIsMenuOpened(true)}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        </div>
      )}

      {isMenuOpened && (
        <div className="col-12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <CreateForm
              setIsMenuOpened={setIsMenuOpened}
              state={state}
              setState={setState}
              productCategoryId={productCategoryId}
            />
          </Form>
        </div>
      )}
      <div className="col-12">
        <DraggableBootstrapTable
          list={list}
          setList={setList}
          headers={["id", "name", "isActive"]}
          title="category"
          onEdit={editHandler}
          selectedPrompt={selectedPrompt}
          setSelectedPrompt={setSelectedPrompt}
          handleShow={handleShow}
          show={show}
          setShow={setShow}
          deleteHandler={deleteHandler}
          updatePosition={updatePosition}
          preview={true}
          removeDeleteBtn={true}
        />
      </div>
    </>
  );
};
