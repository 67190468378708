import {Table} from "react-bootstrap";
import React from "react";
import {Loader} from "../../../shared/components/Loader";
import {EmptyState} from "../../../shared/components/EmptyState";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formatDate} from "../../../shared/functions/formatDate";

export const List = ({
                         dashboardListNews,
                         list,
                         editHandler,
                         selectedIds,
                         setSelectedIds,
                         ctaHeaderBtnTitle,
                         ctaHeaderBtnClick,
                         handleCheckAll
                     }: any) => {
    const {t} = useTranslation();
    const domain = useSelector((state: any) => state.company.clientDomain);

    return (
        <div className="card">
            <div className="card-header pb-0">
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="card-title mg-b-0">
                        {dashboardListNews
                            ? t("dashboard.latest_ten")
                            : t("news.listOfNewss")}
                    </h4>

                    <button className={'btn btn-primary btn-sm mb-2'} onClick={ctaHeaderBtnClick}>
                        {ctaHeaderBtnTitle}
                    </button>
                </div>
            </div>
            <div className="card-body">
                {list === undefined ? (
                    <Loader/>
                ) : list.length === 0 ? (
                    <EmptyState/>
                ) : (
                    <div className="table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                            <thead className="table-light ">
                            <tr className="">
                                <th scope="col">
                                    <input
                                        style={{border: "1px solid black"}}
                                        checked={selectedIds.length === list?.length}
                                        type="checkbox"
                                        onChange={handleCheckAll}
                                    />
                                </th>
                                <th scope="col">{t(`global.id`)}</th>
                                <th scope="col">{t(`global.theme`)}</th>
                                <th scope="col">{t(`global.title`)}</th>
                                <th scope="col">{t(`global.date`)}</th>
                                <th className="text-center" scope="col">
                                    {t(`table_headers.actions`)}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {list?.map((prompt: any) => {
                                return (
                                    <tr key={prompt?.id} className="">
                                        <td>
                                            {setSelectedIds &&
                                                <input
                                                    type="checkbox"
                                                    name="isSelected"
                                                    checked={selectedIds?.includes(prompt?.id)}
                                                    onChange={() => {
                                                        const isSelected = selectedIds?.includes(prompt?.id);
                                                        if (isSelected) {
                                                            setSelectedIds((prev: number[]) => prev.filter(id => id !== prompt.id));
                                                        } else {
                                                            setSelectedIds((prev: number[]) => prev.concat(prompt.id));
                                                        }
                                                    }}
                                                    role="switch"
                                                    id="flexSwitchCheckDefault"
                                                />
                                            }
                                        </td>
                                        <td>
                                            <a
                                                href={domain + "news/" + prompt?.slug}
                                                target="_blank"
                                                className="fw-medium"
                                            >
                                                {prompt?.id}
                                            </a>
                                        </td>
                                        <td className="text-start">
                                            {prompt?.themeText
                                                ? prompt?.themeText
                                                : prompt?.theme?.title ?? "-"}
                                        </td>
                                        <td className="text-start">{prompt?.title ?? "-"}</td>
                                        <td className="text-start">{!!prompt?.date ? formatDate(prompt?.date) : "-"}</td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <a
                                                    href={domain + "news/" + prompt?.slug}
                                                    target="_blank"
                                                    className="btn btn-sm btn-info d-flex align-items-center me-2"
                                                >
                                                    <i className="ri-edit-2-fill me-1"/>
                                                    {t("global.preview")}
                                                </a>
                                                <button
                                                    className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                                    onClick={() => editHandler(prompt?.id)}
                                                >
                                                    <i className="ri-edit-2-fill me-1"/>
                                                    {t("global.edit")}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </div>
                )}
            </div>
        </div>
    );
};
