import Lottie from "lottie-react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import approveAll from "../icons/approveAll.json";
import { projectVersionSlice } from "../../store/slices/project-version.slice";

export const Confirm505Err = ({}: any) => {
  const dispatch = useDispatch();
  const { openRefreshModal } = useSelector(
    (state: any) => state.projectVersion
  );
  //   const reloadVersion = () => {
  //     window.location.reload();
  //     dispatch(projectVersionSlice.actions.setReloadModal(false));
  // };
  return (
    <Modal size="sm" centered show={openRefreshModal}>
      <Modal.Body className="d-flex align-items-center flex-column">
        <Lottie animationData={approveAll} loop={true} style={{ width: 100 }} />
        <h5>The version not supported</h5>
        <p className="text-center text-muted">
          Please update to the latest version of the application
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center justify-content-center">
        <Button
          variant="primary"
          size="sm"
          onClick={() => {
            window.location.reload();
            // dispatch(projectVersionSlice.actions.setReloadModal(false));
          }}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
