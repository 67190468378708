import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import { N1ED } from "@edsdk/n1ed-react";
// import { Editor } from "tinymce";
import { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { editorConfigurationCustom } from "../../../shared/functions/editorConfigurationCustom";
// @ts-ignore
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { InitEditorMulti } from "../../../shared/tinyMce/InitEditorMulti";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { AdminProductCategoryService } from "../service";
export const CreateForm = ({
  setIsMenuOpened,
  state,
  setState,
  productCategoryId,
}: any) => {
  const { t } = useTranslation();
  const [editorData, setEditorData] = useState<any>();
  const firstLoad = useRef(true);
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  // const changeEditorHandler = (content: string, editor: Editor) => {
  //   setState((prev: any) => ({ ...prev, [editor?.id]: content }));
  //   if (!firstLoad.current) {
  //     setEditorData(editor);
  //   }
  //   firstLoad.current = false;
  // };
  const changeHandlerEditor = (value: string, eventName: string) => {
    setState((prev: any) => ({ ...prev, [eventName]: value }));
  };
  const handleText = (text: string) => {
    setState((prev: any) => ({ ...prev, text }));
  };
  const handleTextFooter = (textFooter: string) => {
    setState((prev: any) => ({ ...prev, textFooter }));
  };
  useEffect(() => {
    if (productCategoryId > 0) {
      AdminProductCategoryService.getSingle(productCategoryId)
        .then((response) => {
          const { data } = response;
          InitEditorMulti(data?.text, handleText, ".text");
          InitEditorMulti(data?.textFooter, handleTextFooter, ".textFooter");
        })
        .catch((error) => ErrorToast(error));
    } else {
      console.log("a");

      InitEditorMulti(undefined, handleText, ".text");
      InitEditorMulti(undefined, handleTextFooter, ".textFooter");
    }
  }, [productCategoryId]);
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6}>
            <label htmlFor="name" className="required-field">
              {t("global.name")}
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.name}
            />
          </Col>
          <Col md={2} className="form-check form-switch p-0">
            <label
              className="d-block text-start"
              htmlFor="flexSwitchCheckDefault"
            >
              {t("global.active")}
            </label>
            <div className="row">
              <input
                className="form-check-input switch-custom ms-4 mt-2"
                type="checkbox"
                name="isActive"
                checked={state.isActive}
                onChange={switchHandler}
                role="switch"
                id="flexSwitchCheckDefault"
              />
            </div>
          </Col>
          <Col md={12} className="mb-3" id="custom-editor">
            <div>
              <label htmlFor="text" className="required-field">
                {t("global.text")}
              </label>
            </div>
            <textarea className="editor text" onChange={console.log}></textarea>
            {/* <N1ED
              id={`text`}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              initialValue={editorData ? editorData : state ? state?.text : ""}
              onEditorChange={changeEditorHandler}
            /> */}
            {/* <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.text ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "text");
              }}
            /> */}
          </Col>
          <Col md={12} className="mb-3" id="custom-editor">
            <div>
              <label htmlFor="title" className="required-field">
                {t("global.textFooter")}
              </label>
            </div>
            <textarea
              className="editor textFooter"
              onChange={console.log}
            ></textarea>
            {/* <N1ED
              id={`textFooter`}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              initialValue={
                editorData ? editorData : state ? state?.textFooter : ""
              }
              onEditorChange={changeEditorHandler}
            /> */}
            {/* <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.textFooter ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "textFooter");
              }}
            /> */}
          </Col>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                setIsMenuOpened(false);
                setState({} as any);
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
