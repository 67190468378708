import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AdminUserService } from "../service";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { themeSelect } from "../../../shared/components/Select";
import { SingleValue } from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import Select from "react-select";
import DatePicker from "react-datepicker";

export const CreateForm = ({
  state,
  setState,
  // setPassword,
  // changePassword,
  submitHandler,
}: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { userStatus } = useSelector((state: RootState) => state.enum);
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  function handleDate(date: Date) {
    const value = new Date(date);
    value.setHours(12);
    setStartDate(date);
    console.log(value);
    if (
      (value as any) !=
      "Thu Jan 01 1970 12:00:00 GMT+0100 (Central European Standard Time)"
    ) {
      setState({
        ...state,
        endDate: value,
      } as any);
    } else {
      setState({
        ...state,
        endDate: null,
      } as any);
    }
  }
  const handleSelectStatus = (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => {
    if (selectedSingle?.value as string) {
      setState({
        ...state,
        status: selectedSingle?.value as string,
      } as any);
    } else {
      setState({
        ...state,
        status: null,
      } as any);
    }
  };
  const changePassword = (e: any) => {
    e.preventDefault();
    console.log(password);
    AdminUserService.changePassword(id, { password })
      .then((response) => {
        SuccessToast(t(`admin.successfully_updated`));
        navigate("/admin");
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminUserService.getSingle(+id!)
        .then((response) => {
          const { data } = response;
          setState(data);
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);
  return (
    <>
      <ul
        className="nav nav-tabs nav-tabs-custom rounded card-header-tabs"
        id="myTab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active padding-active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            {t("global.user")}
          </button>
        </li>
        {id && (
          <li className="nav-item" role="presentation">
            <button
              className="nav-link padding-active"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              {t("global.password")}
            </button>
          </li>
        )}
      </ul>
      <div className="tab-content mt-1" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          {" "}
          <Card>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <label htmlFor="firstName" className="required-field-news">
                    {t("global.firstName")}
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    required
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.firstName}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <label htmlFor="lastName" className="required-field-news">
                    {t("global.lastName")}
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    required
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.lastName}
                  />
                </Col>
                <Col md={6}>
                  <label htmlFor="username" className="required-field-news">
                    {t("global.username")}
                  </label>
                  <input
                    type="text"
                    name="username"
                    required
                    className="form-control"
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.username}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <label htmlFor="email" className="required-field-news">
                    {t("global.email")}
                  </label>
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    required
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.email}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <label htmlFor="lastName">{t("global.phone")}</label>
                  <input
                    type="tel"
                    name="phone"
                    className="form-control"
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.phone}
                  />
                </Col>
                {!id && (
                  <Col md={6}>
                    <label htmlFor="password" className="required-field-news">
                      {t("global.password")}
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      required
                      onChange={(e) => changeHandler(e as any)}
                      value={state?.id && state?.password}
                    />
                  </Col>
                )}

                <Col md={3} className="mb-3">
                  <label htmlFor="username">{t("global.company")}</label>
                  <input
                    type="text"
                    name="company"
                    className="form-control"
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.company}
                  />
                </Col>
                <Col md={3}>
                  <label htmlFor="street">{t("global.street")}</label>
                  <input
                    type="text"
                    name="street"
                    className="form-control"
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.street}
                  />
                </Col>
                <Col md={id ? 4 : 2}>
                  <label htmlFor="city">{t("global.city")}</label>
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.city}
                  />
                </Col>
                <Col md={id ? 4 : 2}>
                  <label htmlFor="street">{t("global.postCode")}</label>
                  <input
                    type="text"
                    name="postCode"
                    className="form-control"
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.postCode}
                  />
                </Col>
                <Col md={id ? 4 : 2} className="">
                  <label htmlFor="street">{t("global.status")}</label>
                  <Select
                    isClearable={true}
                    theme={themeSelect}
                    placeholder={t("global.status")}
                    value={
                      state?.status && {
                        label: t(`global.${state?.status?.toLowerCase()}`),
                        value: state?.status as string,
                      }
                    }
                    options={userStatus?.map((item: string) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(e) => {
                      handleSelectStatus(
                        e as SingleValue<{ label: string; value: string }>
                      );
                    }}
                  />
                </Col>
                <Col md={1} className=" mt-2">
                  <label
                    className="d-block text-start mb-3"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    {t("global.active")}
                  </label>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input switch-custom"
                      type="checkbox"
                      name="isActive"
                      checked={state?.isActive}
                      onChange={switchHandler}
                      role="switch"
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </Col>
                <Col md={3} className="mt-2">
                  <label htmlFor="date" className="required-field">
                    {t("global.end_date")}
                  </label>
                  <div className="datepicker-container">
                    <DatePicker
                      isClearable
                      className="date-picker custom_zindex"
                      dateFormat="dd/MM/yyyy"
                      selected={startDate}
                      value={state?.endDate}
                      onChange={(date) => handleDate(date as Date)}
                      placeholderText="Choose date..."
                    />
                  </div>
                </Col>
              </Row>
              <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                <div>
                  <button
                    className="btn btn-info"
                    onClick={() => navigate("/admin/user")}
                  >
                    {t("global.cancel")}
                  </button>
                  <button
                    className="btn btn-primary ms-3"
                    onClick={(e: any) => submitHandler(e, state.id)}
                  >
                    {state?.id
                      ? `  ${t("global.update")}`
                      : `${t("global.create")}`}
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        {id && (
          <div
            className="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <Card>
              <Card.Body>
                <Col md={4}>
                  <label htmlFor="password" className="required-field">
                    {t("global.password")}
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Col>
                <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                  <div>
                    <button
                      className="btn btn-primary ms-3"
                      onClick={(e) => changePassword(e)}
                    >
                      {`${t("global.save")}`}
                    </button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        )}{" "}
      </div>
    </>
  );
};
