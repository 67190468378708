import React, {useEffect, useState} from "react";
import {ErrorToast} from "../../shared/toasters/toasters";
import {List} from "./components/List";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ModelGalleryService} from "./service";

export const ModelGallery = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [list, setList] = useState(undefined);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const editHandler = (id: number) => {
    navigate(`/admin/model-gallery/update/${id}`);
  };

  useEffect(() => {
    ModelGalleryService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body d-flex align-items-center justify-content-between">
            <h4 className="card-title mg-b-0">{t("model.add_gallery")}</h4>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/admin/model-gallery/create")}
              // onClick={() => setIsMenuOpened(true)}
            >
              {t("global.add")}
            </button>
          </div>
        </div>
      </div>

      {/* {isMenuOpened && (
        <div className="col-12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <CreateForm
              setImage={setImage}
              image={image}
              setIsMenuOpened={setIsMenuOpened}
              state={state}
              setState={setState}
              imageRef={imageRef}
              categories={categories}
            />
          </Form>
        </div>
      )} */}
      <div className="col-12">
        <List
          page={page}
          handlePages={handlePages}
          totalPages={totalPages}
          list={list}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
        />
      </div>
    </>
  );
};
