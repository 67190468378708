import { CreateForm } from "./CreateForm";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AdminBannerService } from "../service";

export const CreateBanner = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>();
  const [zones, setZones] = useState<any[]>();

  const submitHandler = () => {
    AdminBannerService.create({ ...state, isActive: state?.active ?? false })
      .then((response) => {
        SuccessToast(t(`banner.successfully_created`));
        navigate("/admin/banner");
        setTriggerUpdate(!triggerUpdate);
        setState({});
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    AdminBannerService.getAllZone({
      page: 0,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setZones(data?.items);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm state={state} setState={setState} zones={zones} />
      </Form>
    </div>
  );
};
