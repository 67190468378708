import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CreateForm } from "./CreateForm";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { AdminBannerService } from "../service";

export const UpdateBanner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [zones, setZones] = useState<any[]>();
  const [state, setState] = useState<any>();
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const submitHandler = (event: React.FormEvent, id?: number) => {
    if (state?.id) {
      AdminBannerService.update({
        ...state,
        zone: state?.zone?.id ? state?.zone?.id : state?.zone,
        isActive: state?.active ?? false,
      })
        .then((response) => {
          SuccessToast(t(`banner.successfully_created`));
          navigate("/admin/banner");
          setTriggerUpdate(!triggerUpdate);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    AdminBannerService.getAllZone({
      page: 0,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setZones(data?.items);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e, state?.id);
        }}
      >
        <CreateForm state={state} setState={setState} zones={zones} />
      </Form>
    </div>
  );
};
