import { ALTERNATIVE_DRIVES_CATEGORY_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminAlternativeDrivesCategoryService = {
  getAll: (params: any) =>
    requests.get(`${ALTERNATIVE_DRIVES_CATEGORY_URL}`, params),
  create: (params: any) =>
    requests.post(`${ALTERNATIVE_DRIVES_CATEGORY_URL}`, params),
  updatePosition: (params: any) =>
    requests.patch(`${ALTERNATIVE_DRIVES_CATEGORY_URL}`, params),
  getSingle: (id: number) =>
    requests.get(`${ALTERNATIVE_DRIVES_CATEGORY_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.put(`${ALTERNATIVE_DRIVES_CATEGORY_URL}/${id}`, params),
  //   delete: (id: number) => requests.delete(`${ALTERNATIVE_DRIVES_CATEGORY_URL}/${id}`),
};
