import { Form, Table } from "react-bootstrap";
import React, { useState } from "react";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { AdminNewsService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { Loader } from "../../../shared/components/Loader";
import { EmptyState } from "../../../shared/components/EmptyState";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { handleNewsIsActive } from "../../../shared/functions/handleNewsIsActive";
import { formatDate } from "../../../shared/functions/formatDate";

export const List = ({
  setTriggerNewsList,
  dashboardListNews,
  list,
  setList,
  totalPages,
  page,
  handlePages,
  setTriggerUpdate,
  editHandler,
  selectedIds,
  setSelectedIds,
  ctaHeaderBtnTitle,
  ctaHeaderBtnClick,
  handleCheckAll,
}: any) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const domain = useSelector((state: any) => state.company.clientDomain);
  const handleShow = () => setShow(true);
  const [selectedPrompt, setselectedPrompt] = useState<any>();

  const deleteHandler = () => {
    selectedPrompt?.id &&
      AdminNewsService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`news.successfully_deleted`));
            setTriggerNewsList && setTriggerNewsList((prev: boolean) => !prev);
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const moveNews = (id: Number) => {
    AdminNewsService.updateOnFirstPosition(Number(id))
      .then((response) => {
        if (response?.status === "success") {
          SuccessToast(t(`global.successfully_updated`));
          setTriggerUpdate((prev: boolean) => !prev);
        }
      })
      .catch((error) => ErrorToast(error));
  };
  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="card-title mg-b-0">
            {dashboardListNews
              ? t("dashboard.latest_ten")
              : t("news.listOfNewss")}
          </h4>
          {/*<button*/}
          {/*  className="btn btn-primary mb-1"*/}
          {/*  onClick={() => console.log(checkedValues)}*/}
          {/*>*/}
          {/*  test*/}
          {/*</button>*/}
          {ctaHeaderBtnClick && (
            <button
              className={"btn btn-primary btn-sm mb-2"}
              onClick={ctaHeaderBtnClick}
            >
              {ctaHeaderBtnTitle}
            </button>
          )}
        </div>
      </div>
      <div className="card-body">
        {list === undefined ? (
          <Loader />
        ) : list?.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light ">
                <tr className="">
                  {setSelectedIds && (
                    <th scope="col" className="text-center">
                      <input
                        checked={selectedIds?.length === list?.length}
                        type="checkbox"
                        onChange={handleCheckAll}
                      />
                    </th>
                  )}

                  <th scope="col" className="text-center">
                    {t(`global.move`)}
                  </th>
                  <th scope="col">{t(`global.id`)}</th>
                  <th scope="col">{t(`global.theme`)}</th>
                  <th scope="col">{t(`global.title`)}</th>
                  <th scope="col">{t(`global.date`)}</th>
                  <th scope="col">{t(`global.active`)}</th>

                  <th className="text-center" scope="col">
                    {t(`table_headers.actions`)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {list?.map((prompt: any) => {
                  return (
                    <tr key={prompt?.id} className="">
                      {setSelectedIds && (
                        <td>
                          <input
                            type="checkbox"
                            name="isSelected"
                            checked={selectedIds?.includes(prompt?.id)}
                            onChange={() => {
                              const isSelected = selectedIds?.includes(
                                prompt?.id
                              );
                              if (isSelected) {
                                setSelectedIds((prev: number[]) =>
                                  prev.filter((id) => id !== prompt.id)
                                );
                              } else {
                                setSelectedIds((prev: number[]) =>
                                  prev.concat(prompt.id)
                                );
                              }
                            }}
                          />
                        </td>
                      )}

                      <td
                        className="text-center pointer"
                        onClick={(e) => moveNews(prompt?.id)}
                      >
                        <button
                          type="button"
                          className="btn p-0"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={`${t(`global.move_to_first_position`)}`}
                        >
                          <i
                            className="bi bi-arrow-up"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <a
                          href={domain + "news/" + prompt?.slug}
                          target="_blank"
                          className="fw-medium"
                        >
                          {prompt?.id}
                        </a>
                      </td>
                      <td className="text-start">
                        {prompt?.themeText
                          ? prompt?.themeText
                          : prompt?.theme?.title ?? "-"}
                      </td>
                      <td className="text-start title-ellipsis">
                        {prompt?.title ?? "-"}
                      </td>
                      <td className="text-start">
                        {!!prompt?.date ? formatDate(prompt?.date) : "-"}
                      </td>
                      <td>
                        <Form.Check
                          type="switch"
                          className={"cursor-pointer"}
                          checked={prompt?.isActive}
                          onClick={() =>
                            handleNewsIsActive(prompt?.id, setList, t)
                          }
                        />
                      </td>
                      <td>
                        <div className="d-flex align-items-center justify-content-center">
                          <a
                            href={domain + "news/" + prompt?.slug}
                            target="_blank"
                            className="btn btn-sm btn-info d-flex align-items-center me-2"
                          >
                            <i className="ri-edit-2-fill me-1" />
                            {t("global.preview")}
                          </a>
                          <button
                            className="btn btn-sm btn-warning d-flex align-items-center me-2"
                            onClick={() => editHandler(prompt?.id)}
                          >
                            <i className="ri-edit-2-fill me-1" />
                            {t("global.edit")}
                          </button>
                          <button
                            className="btn btn-sm btn-danger d-flex align-items-center"
                            onClick={() => {
                              handleShow();
                              setselectedPrompt(prompt);
                            }}
                          >
                            <i className="ri-delete-bin-fill me-1" />
                            {t("global.delete")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        {/* {page && (
          <div className="d-flex justify-content-end ">
            {totalPages <= 1 ? (
              ""
            ) : (
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePagination={handlePages}
              />
            )}
          </div>
        )} */}
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedPrompt?.title}
          deleteHandler={deleteHandler}
          product={selectedPrompt}
          selectedProduct={selectedPrompt}
        />
      </div>
    </div>
  );
};
