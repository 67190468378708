import { EDUCATION_CONTINUING_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const EducationContinuingService = {
  getAll: (params: any) => requests.get(`${EDUCATION_CONTINUING_URL}`, params),
  create: (params: any) =>
    requests.post(`${EDUCATION_CONTINUING_URL}`, params, true),
  getSingle: () => requests.get(`${EDUCATION_CONTINUING_URL}`),
  update: (params: any) =>
    requests.post(`${EDUCATION_CONTINUING_URL}`, params, true),
  delete: (id: number) => requests.delete(`${EDUCATION_CONTINUING_URL}/${id}`),
  deleteImg: (id: number) =>
    requests.delete(`${EDUCATION_CONTINUING_URL}/file/${id}`),
};
