import { PRODUCT_CATEGORY_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminProductCategoryService = {
  getAll: (params: any) => requests.get(`${PRODUCT_CATEGORY_URL}`, params),
  create: (params: any) => requests.post(`${PRODUCT_CATEGORY_URL}`, params),
  updatePosition: (params: any) =>
    requests.patch(`${PRODUCT_CATEGORY_URL}`, params),
  getSingle: (id: number) => requests.get(`${PRODUCT_CATEGORY_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.put(`${PRODUCT_CATEGORY_URL}/${id}`, params),
  delete: (id: number) => requests.delete(`${PRODUCT_CATEGORY_URL}/${id}`),
};
