import {requests} from "../../../../helpers/api.services";
import {EMPLOYEE_URL} from "../../../../helpers/api.routes";

export const AdminEmployeeService = {
    getAll: (params: any) => requests.get(`${EMPLOYEE_URL}`, params),
    create: (params: any) => requests.post(`${EMPLOYEE_URL}`, params, true),
    getSingle: (id: number) => requests.get(`${EMPLOYEE_URL}/${id}`),
    update: (params: any, id: number) => requests.post(`${EMPLOYEE_URL}/${id}`, params, true),
    delete: (id: number) => requests.delete(`${EMPLOYEE_URL}/${id}`),
    updatePosition: (params: any) => requests.patch(`${EMPLOYEE_URL}`, params),
};