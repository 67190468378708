import { SETTINGS_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminSettingsService = {
  getAll: () => requests.get(`${SETTINGS_URL}`),
  getWebsiteModules: () => requests.get(`${SETTINGS_URL}/website-modules`),
  create: (params: any) => requests.post(`${SETTINGS_URL}`, params),
  getSingle: (id: number) => requests.get(`${SETTINGS_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.put(`${SETTINGS_URL}/${id}`, params),
  delete: (id: number) => requests.delete(`${SETTINGS_URL}/${id}`),
};
