import { CreateForm } from "./CreateForm";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { multipartFormData } from "../../../helpers/api.services";
import { MAGAZINE_URL } from "../../../helpers/api.routes";
import { useNavigate } from "react-router-dom";
import { AdminMagazineCategoryService } from "../../magazineCategory/service";

export const CreateMagazine = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [categories, setCategories] = useState();
  const [state, setState] = useState<any>();
  const [image, setImage] = useState<File>();
  const [document, setDocument] = useState<File>();
  const submitHandler = () => {
    multipartFormData(
      image,
      `${MAGAZINE_URL}`,
      JSON.stringify({
        ...state,
      }),
      "post"
    )
      .then((response) => {
        // setIsMenuOpened(false);
        setTriggerUpdate(!triggerUpdate);
        setState({});
        SuccessToast(t(`magazine.successfully_created`));
        navigate("/admin/magazine");
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    AdminMagazineCategoryService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm
          image={image}
          document={document}
          setImage={setImage}
          setDocument={setDocument}
          categories={categories}
          state={state}
          setState={setState}
        />
      </Form>
    </div>
  );
};
