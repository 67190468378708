import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ADMIN_URL } from "../../../helpers/api.routes";
import { multipartFormData } from "../../../helpers/api.services";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AdminAdminService } from "../service";
import { CreateForm } from "./CreateForm";
export const CreateAdmin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("aw");
  const [checkedValue, setCheckedValue] = useState([] as any);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [categories, setCategories] = useState();
  const [state, setState] = useState<any>();
  const [image, setImage] = useState<File>();
  const [document, setDocument] = useState<File>();

  const submitHandler = () => {
    multipartFormData(
      image,
      `${ADMIN_URL}`,
      JSON.stringify({
        ...state,
        website: activeTab,
        modules: checkedValue,
      }),
      "post",
    )
      .then((response) => {
        SuccessToast(t(`admin.successfully_created`));
        setTriggerUpdate(!triggerUpdate);
        setState({});
        navigate("/admin");
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    AdminAdminService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12">
      <CreateForm
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        checkedValue={checkedValue}
        setCheckedValue={setCheckedValue}
        image={image}
        document={document}
        setImage={setImage}
        setDocument={setDocument}
        categories={categories}
        state={state}
        setState={setState}
        submitHandler={submitHandler}
      />
    </div>
  );
};
