import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {AdminSubscriptionService} from "./service";
import {CreateForm} from "./components/CreateForm";
import {useTranslation} from "react-i18next";
import DraggableBootstrapTable, {DataRow} from "../../shared/components/draggableList/Draggable";

export const AdminSubscription = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>({ isActive: true, isPro: false });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [list, setList] = useState<DataRow[] | undefined>(undefined);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const editHandler = (brandId: number) => {
    setIsMenuOpened(true);
    AdminSubscriptionService.getSingle(brandId)
      .then((response) => {
        const { data } = response;
        setState(data);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };
  const submitHandler = () => {
    if (state.id) {
      AdminSubscriptionService.update(state, state.id)
        .then((response) => {
          SuccessToast(t(`subscription.successfully_updated`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    } else {
      AdminSubscriptionService.create(state)
        .then((response) => {
          SuccessToast(t(`subscription.successfully_created`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    }
  };
    const updatePosition = (newList: any) => {
        const newListUpdated = newList?.map((item: any) => item.id);
        AdminSubscriptionService.updatePosition({ ids: newListUpdated });
    };
  useEffect(() => {
    AdminSubscriptionService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);

    const deleteHandler = () => {
        selectedPrompt?.id &&
        AdminSubscriptionService.delete(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    SuccessToast(t(`subscription.successfully_deleted`));
                    setTriggerUpdate((prev: boolean) => !prev);
                }
            })
            .catch((error) => ErrorToast(error));
    };

  return (
    <>
      {!isMenuOpened && (
        <div className="col-12">
          <div className="card">
            <div className="card-body d-flex align-items-center justify-content-between">
              <h4 className="card-title mg-b-0">
                {t("subscription.create_new_subscription")}
              </h4>
              <button
                className="btn btn-primary"
                onClick={() => setIsMenuOpened(true)}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        </div>
      )}

      {isMenuOpened && (
        <div className="col-12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <CreateForm
              setIsMenuOpened={setIsMenuOpened}
              state={state}
              setState={setState}
            />
          </Form>
        </div>
      )}
      <div className="col-12">
          <DraggableBootstrapTable
              preview={false}
              list={list || []}
              setList={setList}
              headers={["id", "name", "category", "isActive", "isPro"]}
              title="subscription"
              onEdit={editHandler}
              selectedPrompt={selectedPrompt}
              setSelectedPrompt={setSelectedPrompt}
              handleShow={handleShow}
              show={show}
              setShow={setShow}
              deleteHandler={deleteHandler}
              updatePosition={updatePosition}
              setTriggerUpdate={setTriggerUpdate}
          />
      </div>
    </>
  );
};
