import { useTranslation } from "react-i18next";

import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import bcgUser from "../../assets/images/small/img-6.jpg";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { ChangePassword } from "./components/ChangePassword";
import { PersonalDetail } from "./components/PersonalDetails";
import { IProfile } from "./dto/IProfile";
import { ProfileDetailsService } from "./service";

import { useDispatch } from "react-redux";
import { CustomImageHandler } from "../../shared/components/CustomImageHandler";

import "../../App.scss";
import { USER_URL } from "../../helpers/api.routes";
import { multipartFormData } from "../../helpers/api.services";
import { authSlice } from "../../store/slices/auth.slice";

export const Profile = () => {
  const fileRef = useRef<any>(null);
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [profileDetails, setProfileDetails] = useState<IProfile>();
  const [currentProfileDetails, setCurrentProfileDetails] = useState<
    IProfile | undefined
  >();

  const [imagePath, setImagePath] = useState<string>("");
  const { t } = useTranslation();

  function handleImageInput(evt: any) {
    if (evt.target.files.length > 0) {
      setImagePath(URL.createObjectURL(evt.target.files[0]));
    }
  }

  const submitHandler = (event: any) => {
    event.preventDefault();
    multipartFormData(
      fileRef?.current?.files?.[0],
      `${USER_URL}`,
      JSON.stringify(currentProfileDetails),
      "post",
      "userImage",
    )
      .then((response) => {
        const { data } = response?.data;
        dispatch(authSlice.actions.setAuth(data));
        setProfileDetails(currentProfileDetails);
        SuccessToast(`${t("profile.successfully_profile")}`);
      })
      .catch((error) => ErrorToast(error));
  };
  const changeProfileHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    isContact = false,
  ) => {
    const { value, name } = event.target;
    setCurrentProfileDetails((prev: any) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    ProfileDetailsService.getProfileDetails()
      .then((response) => {
        setProfileDetails(response as unknown as IProfile);
        setCurrentProfileDetails(response as unknown as IProfile);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    // <PageTemplate title={`${t("side_bar.profile")}`}>
    <>
      <div className="row full-img">
        <div className="col-12 full-img">
          <div className="profile-wid-bg profile-setting-img">
            <img src={bcgUser} alt="profile-bg" className="profile-wid-img" />
          </div>
        </div>
      </div>
      <div className="row mt-n5">
        <div className="col-md-3">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <div className="profile-user d-inline-block mx-auto  mb-4">
                  {(currentProfileDetails as any)?.image?.path || imagePath ? (
                    <CustomImageHandler
                      path={
                        !!imagePath?.length
                          ? imagePath
                          : `${(currentProfileDetails as any)?.image?.path}`
                      }
                      classes="rounded-circle avatar-xl img-thumbnail user-profile-image"
                      alt="user-profile-image"
                    />
                  ) : (
                    <img
                      src="/assets/images/img-placeholder.png"
                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                      alt="user_profile_image"
                    />
                  )}

                  <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                    <input
                      id="profile-img-file-input"
                      type="file"
                      accept="image/*"
                      ref={fileRef}
                      className="profile-img-file-input"
                      onChange={handleImageInput}
                    />
                    <label
                      htmlFor="profile-img-file-input"
                      className="profile-photo-edit avatar-xs"
                    >
                      <span className="avatar-title rounded-circle bg-light text-body">
                        <i className="ri-camera-fill" />
                      </span>
                    </label>
                  </div>
                </div>

                <div>
                  <h5 className="fs-16 mb-1">
                    {profileDetails?.firstName} {profileDetails?.lastName}
                  </h5>
                  <p className="text-muted mb-0">{profileDetails?.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="card">
            <div className="card-header">
              <ul
                className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                role="tablist"
              >
                <li className="nav-item" onClick={() => setStep(0)}>
                  <a
                    href="/"
                    role="tab"
                    data-bs-toggle="tab"
                    className="nav-link active"
                    aria-selected="true"
                  >
                    {`${t("users.personal_details")}`}
                  </a>
                </li>
                <li className="nav-item" onClick={() => setStep(1)}>
                  <a
                    href="/"
                    role="tab"
                    data-bs-toggle="tab"
                    className="nav-link"
                    aria-selected="true"
                  >
                    {`${t("users.change_password")}`}
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              {step === 0 ? (
                <Form onSubmit={submitHandler}>
                  <PersonalDetail
                    state={currentProfileDetails}
                    changeHandler={changeProfileHandler}
                  />
                </Form>
              ) : (
                <ChangePassword />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
    // </PageTemplate>
  );
};
