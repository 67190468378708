import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import React from "react";
import Lottie from "lottie-react";
import edit from "../../../shared/icons/edit.json";
import {IUpdateGalleryImage} from "./Gallery";

interface EditModalProps {
    show: boolean;
    onHide: () => void;
    selectedItem:  IUpdateGalleryImage;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onSave: () => void;
}

export const EditModal: React.FC<EditModalProps> = ({
                                                        show,
                                                        onHide,
                                                        selectedItem,
                                                        onChange,
                                                        onSave
                                                    }) => {
    const {t} = useTranslation();
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Body className="d-flex align-items-center flex-column">
                <Lottie animationData={edit} loop={true} style={{width: 50}}/>
                <h5 className={'my-3'}>{t(`gallery.add_description`)}</h5>
                <textarea
                    className="form-control fs-15"
                    rows={3}
                    name="description"
                    value={selectedItem?.description}
                    onChange={onChange}
                ></textarea>
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-center">
                <Button variant="light" size="sm" onClick={onHide}>
                    {t(`modals.close`)}
                </Button>
                <Button variant="primary" size="sm" onClick={onSave}>
                    {t(`global.save`)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
