import axios, { AxiosRequestConfig } from "axios";
import { useTranslation } from "react-i18next";

export const ExportNewsletter = () => {
  const { t } = useTranslation();
  const downloadXLSFile = async () => {
    const website = localStorage.getItem("website");
    const appVersion = localStorage.getItem("appVersion");
    const headers = {
      "Content-Type": "blob",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "app-version": appVersion,
      "Aw-Web-Site": website ?? "aw",
    };
    const config: AxiosRequestConfig = {
      method: "PATCH",
      url: `${
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_DEV_MODE
          : process.env.REACT_APP_PRO_MODE
      }admin/newsletter-subscriber/download`,
      responseType: "arraybuffer",
      headers,
      // params: true,
    };
    const response = await axios(config);
    const url = URL.createObjectURL(new Blob([(response as any)?.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${Date.now()}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <div className="card">
      <div className="card-body">
        {" "}
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-success mx-auto"
            onClick={() => downloadXLSFile()}
          >
            {t("global.export")}
          </button>
        </div>
      </div>
    </div>
  );
};
