import { BANNER_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminBannerService = {
  getAll: (params: any) => requests.get(`${BANNER_URL}`, params),
  update: (params: any) => requests.put(`${BANNER_URL}/${params.id}`, params),
  create: (params: any) => requests.post(`${BANNER_URL}`, params, true),
  getSingle: (id: number) => requests.get(`${BANNER_URL}/${id}`),
  delete: (id: number) => requests.delete(`${BANNER_URL}/${id}`),
  getAllZone: (params: any) => requests.get(`${BANNER_URL}-zone`, params),
  updateZone: (params: any) =>
    requests.put(`${BANNER_URL}-zone/${params.id}`, params),
  createZone: (params: any) =>
    requests.post(`${BANNER_URL}-zone`, params, true),
  getSingleZone: (id: number) => requests.get(`${BANNER_URL}-zone/${id}`),
  deleteZone: (id: number) => requests.delete(`${BANNER_URL}-zone/${id}`),
};
