import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
export const CreateForm = ({ setIsMenuOpened, state, setState }: any) => {
  const { t } = useTranslation();
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const { subscriberCategory } = useSelector((state: RootState) => state.enum);
  const selectHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, category: e.value }));
  };
  const mappedData = subscriberCategory?.map((category: any) => ({
    label: t(`enums.${category}`),
    value: category,
  }));
  const switchHandler2 = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6}>
            <label htmlFor="name" className="required-field">
              {t("global.name")}
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.name}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="category" className="required-field">
              {t("table_headers.category")}
            </label>
            <Select
              className="react-select-field"
              onChange={selectHandler}
              options={mappedData}
              value={mappedData?.find(
                (category: any) => category.value === state?.category
              )}
            />
          </Col>
          <Col md={1} className=" mt-2">
            <label
              className="d-block text-start"
              htmlFor="flexSwitchCheckDefault"
            >
              {t("global.active")}
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input switch-custom"
                type="checkbox"
                name="isActive"
                checked={state?.isActive}
                onChange={switchHandler2}
                role="switch"
                id="flexSwitchCheckDefault"
              />
            </div>
          </Col>
          <Col md={1} className=" mt-2">
            <label
                className="d-block text-start"
                htmlFor="flexSwitchCheckDefault"
            >
              {t(`table_headers.isPro`)}
            </label>
            <div className="form-check form-switch">
              <input
                  className="form-check-input switch-custom"
                  type="checkbox"
                  name="isPro"
                  checked={state?.isPro}
                  onChange={switchHandler2}
                  role="switch"
                  id="flexSwitchCheckDefault"
              />
            </div>
          </Col>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                setIsMenuOpened(false);
                setState({} as any);
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
