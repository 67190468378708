import {AdminNewsService} from "../../app/news/service";
import {ErrorToast, SuccessToast} from "../toasters/toasters";
import React, {SetStateAction} from "react";

export const handleNewsIsActive = (id: number, setList: React.Dispatch<SetStateAction<any>>, t: any) => {
    id &&
    AdminNewsService.updateActiveStatus(id)
        .then((response) => {
            if (response?.status === "success") {
                SuccessToast(t(`news.successfully_updated`));
                setList((prevState: any) => prevState.map((listItem: any) => {
                    if (listItem?.id === id) {
                        return {
                            ...listItem,
                            isActive: !listItem.isActive
                        }
                    }
                    return listItem;
                }) as [])
            }
        })
        .catch((error) => ErrorToast(error));
}