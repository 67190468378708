import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PICTURE_URL } from "../../../helpers/api.routes";
import { IForm } from "../interface";

export const CreateForm = ({
  setIsMenuOpened,
  state,
  setFile,
  setState,
}: IForm) => {
  const { t } = useTranslation();

  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const fileHandler = (e: any) => {
    setFile(e.target.files[0]);
  };
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4}>
            <label htmlFor="name" className="required-field">
              {t("global.name")}
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.name}
            />
          </Col>
        </Row>
        <div className="mt-3">
          <label htmlFor="documentFile">{t("download.image")}</label>
          <input
            accept="image/*"
            onChange={fileHandler}
            className="form-control"
            type="file"
            id="formFile"
          />
        </div>

        {state?.image && (
          <div className="col-md-6 py-3">
            <img
              className="rounded"
              alt="200x200"
              width={200}
              src={PICTURE_URL + state?.image.imagePath}
              data-holder-rendered="true"
            />
          </div>
        )}

        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                setIsMenuOpened(false);
                setState({} as any);
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
