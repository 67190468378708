import { CreateForm } from "./CreateForm";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { AdminGalleryService } from "../service";

export const CreatePhoto = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>();
  const [images, setImages] = useState<File[]>();

  const submitHandler = () => {
    const formData = new FormData();
    if (images) {
      for (let image of images!) {
        formData.append("image[]", image as File);
      }
    }

    formData.append("body", JSON.stringify(state));
    AdminGalleryService.create(formData)
      .then((response) => {
        SuccessToast(t(`gallery.successfully_added_photo`));
        setTriggerUpdate(!triggerUpdate);
        setState({});
        navigate("/admin/gallery");
      })
      .catch((error) => ErrorToast(error));
  };

  const fileHandler = (e: any) => {
    setImages(e.target.files);
  };

  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm
          state={state}
          setState={setState}
          fileHandler={fileHandler}
        />
      </Form>
    </div>
  );
};
