import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const CreateForm = ({
  setIsMenuOpened,
  state,
  setState,
  image,
  setImage,
}: any) => {
  const { t } = useTranslation();
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const fileHandler = (e: any) => {
    setImage(e.target.files[0]);
  };
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4}>
            <label htmlFor="name" className="required-field">
              {t("global.name")}
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.name}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="name" className="required-field">
              {t("global.link")}
            </label>
            <input
              type="text"
              name="link"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.link}
            />
          </Col>

          <div className="mt-3">
            <label htmlFor="imageFile"> {t("download.image")}</label>
            <input
              onChange={fileHandler}
              className="form-control"
              type="file"
              accept="image/*"
              id="imageFile"
            />
          </div>
          {state?.image && (
            <div className="d-flex align-items-center mt-3  ">
              <i className="bi bi-file-earmark display-3"></i>
              {image?.name ?? state.image.imageName}
            </div>
          )}
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                setIsMenuOpened(false);
                setState({} as any);
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
