import { CreateForm } from "./CreateForm";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { AdminCategoryDownloadService } from "../../downloadCategory/service";
import { useNavigate } from "react-router-dom";
import { IModel } from "../interface";
import { AdminMarketService } from "../service";
export const CreateMarket = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [state, setState] = useState<IModel>({
    title: "",
    description: "",
    isActive: true,
  });

  const submitHandler = () => {
    AdminMarketService.create(state)
      .then((response) => {
        SuccessToast(t(`settings.successfully_saved`));
        navigate("/admin/market");
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm categories={categories} state={state} setState={setState} />
      </Form>
    </div>
  );
};
