import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateForm } from "./CreateForm";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AdminMenuService } from "../service";
import { useTranslation } from "react-i18next";

export const UpdateMenu = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState();
  const { t } = useTranslation();
  const [state, setState] = useState<any>();
  const [image, setImage] = useState<File>();
  const [document, setDocument] = useState<File>();
  const submitHandler = (event: React.FormEvent, id?: number) => {
    AdminMenuService.update(state, state.id)
      .then((response) => {
        navigate("/admin/menu");
        SuccessToast(t(`menu.successfully_updated`));
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    AdminMenuService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e, state?.id);
        }}
      >
        <CreateForm
          image={image}
          document={document}
          setImage={setImage}
          setDocument={setDocument}
          categories={categories}
          state={state}
          setState={setState}
        />
      </Form>
    </div>
  );
};
