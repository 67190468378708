import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../App.scss";
import { PICTURE_URL } from "../../helpers/api.routes";
import { ErrorToast } from "../../shared/toasters/toasters";
import { List } from "../news/components/List";
import { AdminDashboardService } from "./service";

export const Dashboard = () => {
  const { t } = useTranslation();
  const getAuth = useSelector((state: any) => state.auth);
  const [modules, setModules] = useState({});
  const [listNews, setListNews] = useState([]);
  const [triggerNewsList, setTriggerNewsList] = useState<boolean>(false);
  const navigate = useNavigate();
  const editHandler = (id: number): void => {
    navigate(`/admin/news/update/${id}`);
  };

  useEffect(() => {
    AdminDashboardService.getAll()
      .then((response) => {
        const { data } = response;
        setModules(data?.modules);
        setListNews(data?.newses);
      })
      .catch((error) => ErrorToast(error));
  }, [triggerNewsList]);
  const linkNavigate = (link: string) => {
    const linkNavigate = link.replace("_", "-");
    navigate(`/admin/${link}`);
    switch (link) {
      case "company_details":
        return navigate(`/admin/company`);
      case "event_summary_category":
        return navigate(`admin/event-summary/category`);
      case "news_category":
        return navigate(`/admin/category`);
      case "site_settings":
        return navigate(`/admin/home-settings`);
      default:
        return navigate(`/admin/${linkNavigate}`);
    }
  };
  const iconForWidget = (icon: string) => {
    switch (icon) {
      case "company_details":
        return "ri-building-2-fill";
      case "user":
        return "ri-user-search-line";
      case "subscription":
        return "ri-money-dollar-box-fill";
      case "news_category":
        return "ri-article-line";
      case "news":
        return "ri-article-fill";
      case "newsletter":
        return "ri-mail-send-fill";
      case "menu":
        return "ri-book-read-fill";
      case "magazine_category":
        return "ri-book-read-line";
      case "magazine":
        return "ri-book-read-fill";
      case "event_category":
        return "ri-calendar-line";
      case "event":
        return "ri-calendar-fill";
      case "download_category":
        return "ri-download-2-line";
      case "download":
        return "ri-download-line";
      case "brand":
        return "ri-vip-crown-fill";
      case "event_summary":
        return "ri-coupon-5-fill";
      case "event_summary_category":
        return "ri-coupon-fill";
      case "gallery":
        return "ri-image-line";
      case "page":
        return "bx bx-user-circle";
      default:
        return "bx bx-user-circle";
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-8 col-md-6">
          <div className="card bg-primary">
            <div className="card-body">
              <div className="d-flex border-bottom pb-3 border-light border-opacity-25">
                <div className="flex-grow-1 overflow-hidden">
                  <h4 className="fs-17 fw-semibold text-white">
                    Hello, {getAuth.firstName}
                  </h4>
                  <p className="text-white text-truncate mb-0">
                    {" "}
                    Welcome to Dashboard
                  </p>
                </div>

                <div className="flex-shrink-0">
                  <div className="avatar-sm">
                    <span className="avatar-title rounded fs-2">🎉</span>
                  </div>
                </div>
              </div>
              <div className="mt-2 pt-1">
                <p className="text-white-50 mb-0 fs-14 mb-0 text-truncate">
                  <span className="text-white">#35</span> Leaderboard position
                  to reach level 3
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex border-bottom pb-3">
                <div className="flex-grow-1">
                  <h4 className="fs-17 fw-semibold">
                    {getAuth.firstName} {getAuth.lastName}
                  </h4>
                  <p className="text-muted mb-0">All Time Winner</p>
                </div>

                <div className="flex-shrink-0">
                  <div className="avatar-sm">
                    <span className=" rounded fs-2">
                      <img
                        className="rounded img-fluid"
                        src={
                          getAuth?.image?.path
                            ? PICTURE_URL + getAuth.image.path
                            : "assets/images/img-placeholder.png"
                        }
                        alt="Header Avatar"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-2 pt-1">
                <p className="text-muted mb-0 fs-14 mb-0 text-truncate">
                  {getAuth?.companyData?.name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {Object.entries(modules)?.map((item: any) => {
          if (item[0] === "company_details" || item[0] === "site_settings") {
            return;
          } else
            return (
              <div className="col-md-3">
                <div className="card card-animate">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <p className="text-uppercase fw-medium text-muted mb-0 fs-13">
                          {item[0].replaceAll("_", " ")}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-end justify-content-between mt-4">
                      <div>
                        <h4 className="fs-22 fw-semibold mb-4">
                          <span className="counter-value" data-target="183.35">
                            {item[1].total ?? 0}
                          </span>
                        </h4>
                        <a
                          onClick={() => linkNavigate(item[0])}
                          className="cursor-pointer text-decoration-underline"
                        >
                          {t("dashboard.see_details")}
                        </a>
                      </div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-warning-subtle rounded fs-3">
                          <i
                            className={`${iconForWidget(item[0])} text-warning`}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
        })}
      </div>
      {listNews?.length && (
        <div className="col-12">
          <List
            setTriggerNewsList={setTriggerNewsList}
            dashboardListNews={true}
            list={listNews}
            setTriggerUpdate={null}
            editHandler={editHandler}
            setList={setListNews}
          />
        </div>
      )}
    </>
  );
};
