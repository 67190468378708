import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorToast } from "../../shared/toasters/toasters";
import { List } from "./components/List";
import { AdminBannerService } from "./service";

interface AdminBannerProps {}

interface BannerItem {
  // Define the properties of a banner item
  // Adjust these based on your actual data structure
  id: number;
  // ... other properties
}

interface BannerListResponse {
  items: BannerItem[];
  totalItems: number;
  perPage: number;
}

const AdminBanner: React.FC<AdminBannerProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [triggerUpdate, setTriggerUpdate] = useState<boolean>(false);
  const [list, setList] = useState<BannerItem[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const perPage: number = 10;

  const handlePages = (updatePage: number): void => {
    setPage(updatePage);
  };

  const editHandler = (id: number): void => {
    navigate(`/admin/banner/update/${id}`);
  };

  const fetchBannerList = async (updatePage: number): Promise<void> => {
    try {
      const response = await AdminBannerService.getAll({
        page: updatePage,
        perPage,
      });

      const { data } = response as { data: BannerListResponse };
      setList(data?.items);
      setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
    } catch (error) {
      ErrorToast(error);
    }
  };

  useEffect(() => {
    fetchBannerList(page);
  }, [triggerUpdate, page]);

  const createNewBanner = (): void => {
    navigate("/admin/banner/create");
  };

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body d-flex align-items-center justify-content-between">
            <h4 className="card-title mg-b-0">
              {t("banner.create_new_banner")}
            </h4>
            <button className="btn btn-primary" onClick={createNewBanner}>
              {t("global.add")}
            </button>
          </div>
        </div>
      </div>

      <div className="col-12">
        <List
          page={page}
          handlePages={handlePages}
          totalPages={totalPages}
          list={list}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
        />
      </div>
    </>
  );
};

export default AdminBanner;
