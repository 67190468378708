import { EVENT_CATEGORY_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminEventCategoryService = {
  getAll: (params: any) => requests.get(`${EVENT_CATEGORY_URL}`, params),
  create: (params: any) => requests.post(`${EVENT_CATEGORY_URL}`, params),
  updatePosition: (params: any) =>
    requests.patch(`${EVENT_CATEGORY_URL}`, params),
  getSingle: (id: number) => requests.get(`${EVENT_CATEGORY_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.put(`${EVENT_CATEGORY_URL}/${id}`, params),
  delete: (id: number) => requests.delete(`${EVENT_CATEGORY_URL}/${id}`),
};
