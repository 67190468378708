import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { PICTURE_URL } from "../../../helpers/api.routes";
import { ISelect } from "../../../shared/interface";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { IForm } from "../interface";
import { AdminDownloadService } from "../service";

export const CreateForm = ({
  state,
  setDocument,
  setImage,
  categories,
  setState,
}: IForm) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const changeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const fileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === "documentFile") {
      setDocument(e.target.files![0]);
    } else {
      setImage(e.target.files![0]);
    }
  };

  const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const selectHandler = (e: ISelect | null) => {
    setState((prev) => ({ ...prev, category: e!.value }));
  };

  const mappedData = categories?.map((category) => ({
    label: category.name,
    value: category.id,
  }));

  useEffect(() => {
    if (!!Number(id)) {
      AdminDownloadService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;
          setState({ ...data, category: data.category.id });
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4}>
            <label htmlFor="name" className="required-field">
              {t("global.name")}
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              required
              onChange={changeHandler}
              value={state?.id && state.name}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="category" className="required-field">
              {t("table_headers.category")}
            </label>
            <Select
              className="react-select-field"
              onChange={selectHandler}
              options={mappedData}
              value={mappedData?.find(
                (category: any) => category.value === state?.category,
              )}
            />
          </Col>
          <Col md={4} className="">
            <div className="form-check form-switch p-0">
              <label
                className="d-block text-start"
                htmlFor="flexSwitchCheckDefault"
              >
                {t("download.pro_member")}
              </label>
              <div className="row">
                <input
                  className="form-check-input switch-custom ms-4 mt-2"
                  type="checkbox"
                  name="isProMember"
                  checked={state.isProMember}
                  onChange={switchHandler}
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </Col>
          <Col md={12} className="my-3">
            <div>
              <label
                htmlFor="exampleFormControlTextarea5"
                className="form-label"
              >
                {t("download.description")}
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea5"
                rows={3}
                name="description"
                value={state?.description}
                onChange={changeHandler}
              ></textarea>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="">
              <label htmlFor="documentFile">{t("download.document")}</label>
              <input
                onChange={fileHandler}
                className="form-control"
                type="file"
                id="documentFile"
                accept="application/*"
              />
            </div>
            {state?.document && (
              <div className="d-flex align-items-center mt-3">
                <i className="bi bi-file-earmark display-3"></i>
                <a
                  target="_blank"
                  download
                  href={PICTURE_URL + state.document.documentPath}
                >
                  {state.document.documentName}
                </a>
              </div>
            )}
          </Col>
          <Col md={6}>
            <div className="">
              <label htmlFor="imageFile">{t("download.image")}</label>
              <input
                onChange={fileHandler}
                className="form-control"
                type="file"
                accept="image/*"
                id="imageFile"
              />
            </div>

            {state?.image && (
              <div className="col-md-6 py-3">
                <img
                  className="rounded"
                  alt="200x200"
                  width={200}
                  src={PICTURE_URL + state?.image.imagePath}
                  data-holder-rendered="true"
                />
              </div>
            )}
          </Col>
        </Row>

        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                navigate("/admin/download");
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
