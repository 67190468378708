import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DOWNLOAD_URL } from "../../../helpers/api.routes";
import { DocImgFormData } from "../../../helpers/api.services";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AdminCategoryDownloadService } from "../../downloadCategory/service";
import { IDownload } from "../interface";
import { CreateForm } from "./CreateForm";
export const CreateDownload = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [state, setState] = useState<IDownload>({
    website: "",
    name: "",
    category: null,
    description: "",
    isProMember: false,
  });
  const [image, setImage] = useState<File>();
  const [document, setDocument] = useState<File>();
  const submitHandler = () => {
    DocImgFormData(
      document ?? state?.document,
      image ?? state?.image,
      `${DOWNLOAD_URL}`,
      JSON.stringify({
        ...state,
      }),
      "post",
    )
      .then((response) => {
        const { code }: any = response;
        SuccessToast(t(`download.successfully_created`));
        setState({
          website: "",
          name: "",
          category: null,
          description: "",
          isProMember: false,
        });
        setImage(undefined);
        setDocument(undefined);
        navigate("/admin/download");
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    AdminCategoryDownloadService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm
          setImage={setImage}
          setDocument={setDocument}
          categories={categories}
          state={state}
          setState={setState}
        />
      </Form>
    </div>
  );
};
