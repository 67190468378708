import { TESTS_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminTestsService = {
  getAll: (params: any) => requests.get(`${TESTS_URL}`, params),
  getAllAuthors: () => requests.get(`${TESTS_URL}/authors`),
  create: (params: any) => requests.post(`${TESTS_URL}`, params, true),
  getSingle: (id: number) => requests.get(`${TESTS_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.post(`${TESTS_URL}/${id}`, params, true),
  delete: (id: number) => requests.delete(`${TESTS_URL}/${id}`),
  deleteAdditional: (id: number) => requests.delete(`${TESTS_URL}/file/${id}`),
  updateFeatured: (id: number) => requests.put(`${TESTS_URL}/featured/${id}`),
  copy: (params: any, id: number) =>
    requests.post(`${TESTS_URL}/copy/${id}`, params),
  updatePosition: (params: any) =>
    requests.patch(`${TESTS_URL}/sort-latest`, params),
};
