import { USER_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";
import { IProfileDTO, IProfile, IProfilePassword } from "./dto/IProfile";
// import { BaseModel } from "../../model/shared/BaseModel";
// import { requests } from "../../utils/helpers/api.services";

export interface BaseModel<Type> {
  count: number;
  data: Type;
  page: number;
  perPage: number;
  administrationData?: any;
  companyData?: any;
  projectVersion: string;
}
export const ProfileDetailsService = {
  getProfileDetails: (): Promise<BaseModel<IProfile>> =>
    requests.get(`${USER_URL}`),
  updateProfileDetails: (params: any) => requests.post(`${USER_URL}`, params),
  // updateProfileDetails: (params: IProfileDTO) =>
  //   requests.put(`${USER_URL}/edit-user-profile`, params),
  updateProfilePassword: (params: IProfilePassword) =>
    requests.post(`${USER_URL}/password-change`, params),
  // getAllNotifications: (params: any): Promise<BaseModel<any>> =>
  //   requests.get(`${USER_URL}/notifications`, params),
  // changeSeenStatus: (id: number): Promise<BaseModel<any>> =>
  //   requests.get(`${USER_URL}/notifications/${id}`),
};
