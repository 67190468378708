import { ALTERNATIVE_DRIVES_SETTINGS_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminAlternativeDrivesSettingsService = {
  getAll: () => requests.get(`${ALTERNATIVE_DRIVES_SETTINGS_URL}`),
  create: (params: any) =>
    requests.post(`${ALTERNATIVE_DRIVES_SETTINGS_URL}`, params, true),
  getSingle: () => requests.get(`${ALTERNATIVE_DRIVES_SETTINGS_URL}`),
  update: (params: any, id: number) =>
    requests.put(`${ALTERNATIVE_DRIVES_SETTINGS_URL}/${id}`, params),
  // delete: (id: number) =>
  //   requests.delete(`${ALTERNATIVE_DRIVES_SETTINGS_URL}/file/${id}`),
  deleteFile: (id: number) =>
    requests.delete(`${ALTERNATIVE_DRIVES_SETTINGS_URL}/file/${id}`),
};
