import { CreateForm } from "./CreateForm";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { AdminVideoService } from "../service";
import { multipartFormData } from "../../../helpers/api.services";
import { VIDEO_URL } from "../../../helpers/api.routes";

export const CreateVideo = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>();
  const [image, setImage] = useState<File>();

  const submitHandler = () => {
    multipartFormData(
      image,
      `${VIDEO_URL}`,
      JSON.stringify({ ...state, isActive: state?.isActive ?? false }),
      "post"
    )
      .then((response) => {
        SuccessToast(t(`event.successfully_created`));

        setTriggerUpdate(!triggerUpdate);

        navigate("/admin/video-gallery");
      })
      .catch((error) => ErrorToast(error));
  };
  // }

  // const submitHandler = () => {
  //   const formData = new FormData();
  //   if (images) {
  //     for (let image of images!) {
  //       formData.append("image[]", image as File);
  //     }
  //   }

  //   formData.append("body", JSON.stringify(state));
  //   AdminVideoService.create(formData)
  //     .then((response) => {
  //       SuccessToast(t(`video_gallery.successfully_added_photo`));
  //       setTriggerUpdate(!triggerUpdate);
  //       setState({});
  //       navigate("/admin/video-gallery");
  //     })
  //     .catch((error) => ErrorToast(error));
  // };

  // const fileHandler = (e: any) => {
  //   setImage(e.target.files);
  // };

  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm
          state={state}
          setImage={setImage}
          setState={setState}
          // fileHandler={fileHandler}
        />
      </Form>
    </div>
  );
};
