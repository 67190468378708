import { PICTURE_URL } from "../../helpers/api.routes";

interface ICustomImageHandlerProps {
  path: string;
  alt?: string;
  classes?: string;
  style?: any;
}

export const CustomImageHandler = ({
  path,
  alt,
  classes,
  style,
}: ICustomImageHandlerProps) => {
  return (
    <>
      {/* <img src="../" /> */}
      <img
        src={
          !!path?.length
            ? path.slice(0, 4) === "blob"
              ? path
              : PICTURE_URL + path
            : PICTURE_URL + `/images/img-placeholder.png`
        }
        alt={alt}
        // onError={({ currentTarget }) => {
        //   currentTarget.onerror = null;
        //   currentTarget.src = "/images/img-placeholder.png";
        // }}
        className={classes}
        style={style}
      />
    </>
  );
};
