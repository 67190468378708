import {MODEL_URL} from "../../helpers/api.routes";
import {requests} from "../../helpers/api.services";

export const AdminModelService = {
  getAll: (params: any) => requests.get(`${MODEL_URL}`, params),
  create: (params: any) => requests.post(`${MODEL_URL}`, params, true),
  getSingle: (id: number) => requests.get(`${MODEL_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.post(`${MODEL_URL}/${id}`, params, true),
  delete: (id: number) => requests.delete(`${MODEL_URL}/${id}`),
};
