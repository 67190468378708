import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ADMIN_URL } from "../../../helpers/api.routes";
import { multipartFormData } from "../../../helpers/api.services";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AdminAdminService } from "../service";
import { CreateForm } from "./CreateForm";

export const UpdateAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [state, setState] = useState<any>();
  const [activeTab, setActiveTab] = useState("aw");
  const [image, setImage] = useState<File>();
  const [document, setDocument] = useState<File>();

  const submitHandler = (event: React.FormEvent, id?: number) => {
    multipartFormData(
      image,
      `${ADMIN_URL}/${id}`,
      JSON.stringify({ ...state, website: activeTab }),
      "post"
    )
      .then((response) => {
        SuccessToast(t(`admin.successfully_updated`));
        // setIsMenuOpened(false);
        // setTriggerUpdate(!triggerUpdate);
        setState({});
        navigate("/admin");
      })
      .catch((error) => ErrorToast(error));
  };
  return (
    <div className="col-12">
      <CreateForm
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        image={image}
        document={document}
        setImage={setImage}
        setDocument={setDocument}
        state={state}
        setState={setState}
        // setPassword={setPassword}
        // changePassword={changePassword}
        submitHandler={submitHandler}
      />
    </div>
  );
};
