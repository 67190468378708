import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { N1ED } from "@edsdk/n1ed-react";
import { Editor } from "tinymce";
import { useRef, useState } from "react";
import Compact from "@uiw/react-color-compact";
import Select from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export const CreateForm = ({ setIsMenuOpened, state, setState }: any) => {
  const { t } = useTranslation();
  const [editorData, setEditorData] = useState<any>();
  const firstLoad = useRef(true);
  const { language } = useSelector((state: RootState) => state.enum);
  const mappedLang = language?.map((item: string) => ({
    label: `${t(`languages.${item?.toLowerCase()}`)}`,
    value: item?.toLowerCase(),
  }));

  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const changeEditorHandler = (content: string, editor: Editor) => {
    setState((prev: any) => ({ ...prev, [editor?.id]: content }));
    if (!firstLoad.current) {
      setEditorData(editor);
    }
    firstLoad.current = false;
  };
  const selectSingleHandler = (e: any, name: string) => {
    setState((prev: any) => ({
      ...prev,
      [name]: e.value,
    }));
  };
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6}>
            <label htmlFor="title" className="required-field">
              {t("global.title")}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.title}
            />
          </Col>
          <Col md={3} className="">
            <label htmlFor="language" className="required-field">
              {t("global.language")}
            </label>
            <Select
              className="react-select-field"
              onChange={(e) => selectSingleHandler(e, "language")}
              options={mappedLang as any[]}
              value={mappedLang?.find(
                (lang: any) =>
                  lang?.value?.toLowerCase() === state?.language?.toLowerCase()
              )}
            />
          </Col>
          <Col md={3} className="form-check form-switch p-0">
            <label
              className="d-block text-start"
              htmlFor="flexSwitchCheckDefault"
            >
              {t("global.active")}
            </label>
            <div className="row">
              <input
                className="form-check-input switch-custom ms-4 mt-2"
                type="checkbox"
                name="isActive"
                checked={state.isActive}
                onChange={switchHandler}
                role="switch"
                id="flexSwitchCheckDefault"
              />
            </div>
          </Col>
          <Col md={6} className="my-3">
            <label htmlFor="color" className="required-field">
              {t("alternative_drives.color")}
            </label>
            <Compact
              style={{ marginLeft: 20 }}
              color={state.color}
              onChange={(color) => {
                setState((prev: any) => ({ ...prev, color: color.hex }));
              }}
            />
          </Col>
          <Col md={6} className="my-3">
            <label htmlFor="textColor" className="required-field">
              {t("alternative_drives.textColor")}
            </label>
            <Compact
              style={{ marginLeft: 20 }}
              color={state.textColor}
              onChange={(color) => {
                setState((prev: any) => ({ ...prev, textColor: color.hex }));
              }}
            />
          </Col>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                setIsMenuOpened(false);
                setState({} as any);
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
