import { EVENT_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminEventService = {
  getAll: (params: any) => requests.get(`${EVENT_URL}`, params),
  create: (params: any) => requests.post(`${EVENT_URL}`, params, true),
  getSingle: (id: number) => requests.get(`${EVENT_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.post(`${EVENT_URL}/${id}`, params, true),
  delete: (id: number) => requests.delete(`${EVENT_URL}/${id}`),
  updatePosition: (params: any) => requests.patch(`${EVENT_URL}`, params),
  updatePositionCategory: (params: any) =>
    requests.patch(`${EVENT_URL}-category`, params),
};
