import { createSlice } from "@reduxjs/toolkit";

const initialState: number = 0;

export const numberNewsletterSlice = createSlice({
  name: "numberNewsletter",
  initialState,
  reducers: {
    setNumberNewsletter: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});
