import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CreateForm } from "./CreateForm";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AdminAlbumService } from "../service";
import { useTranslation } from "react-i18next";

export const UpdateAlbum = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState<any>();

  const submitHandler = (event: React.FormEvent, id?: number) => {
    AdminAlbumService.update(state, state.id)
      .then((response) => {
        SuccessToast(t(`album.successfully_updated`));
        navigate("/admin/album");

        setState({});
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e, state?.id);
        }}
      >
        <CreateForm state={state} setState={setState} />
      </Form>
    </div>
  );
};
