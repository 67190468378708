import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorToast } from "../../shared/toasters/toasters";
import { AdminNewsThemeService } from "./service";
import { List } from "./components/List";

interface AdminBannerProps {}

interface BannerItem {
  // Define the properties of a news-theme item
  // Adjust these based on your actual data structure
  id: number;
  // ... other properties
}

interface BannerListResponse {
  items: BannerItem[];
  totalItems: number;
  perPage: number;
}

const AdminNewsTheme: React.FC<AdminBannerProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [triggerUpdate, setTriggerUpdate] = useState<boolean>(false);
  const [list, setList] = useState<BannerItem[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const perPage: number = 10;

  const handlePages = (updatePage: number): void => {
    setPage(updatePage);
  };

  const editHandler = (id: number): void => {
    navigate(`/admin/news-theme/update/${id}`);
  };

  const fetchBannerList = async (updatePage: number): Promise<void> => {
    try {
      const response = await AdminNewsThemeService.getAll({
        page: updatePage,
        perPage,
      });

      const { data } = response as { data: BannerListResponse };
      setList(data?.items);
      setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
    } catch (error) {
      ErrorToast(error);
    }
  };

  useEffect(() => {
    fetchBannerList(page);
  }, [triggerUpdate, page]);

  const createNewTheme = (): void => {
    navigate("/admin/news-theme/create");
  };

  return (
      <>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row g-2">
                <div className="col-sm-4">
                  <div className="search-box">
                    <input type="text" className="form-control" id="searchMemberList"
                           placeholder="Search for name or designation..."/>
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </div>
                <div className="col-sm-auto ms-auto">
                  <div className="list-grid-nav hstack gap-1">
                    <button className="btn btn-info"  onClick={createNewTheme}>
                      {t("global.add")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <List
              page={page}
              handlePages={handlePages}
              totalPages={totalPages}
              list={list}
              setTriggerUpdate={setTriggerUpdate}
              editHandler={editHandler}
          />
        </div>
      </>
  );
};

export default AdminNewsTheme;
