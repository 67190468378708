import {
  NEWSLETTER_ERROR_URL,
  NEWSLETTER_URL,
} from "../../../helpers/api.routes";
import { requests } from "../../../helpers/api.services";

export const AdminNewsLetterSentService = {
  getAll: (params: any) => requests.get(`${NEWSLETTER_URL}s/list`, params),
  getSingle: (id: number) => requests.get(`${NEWSLETTER_URL}/${id}`),
  getAllErrors: (params: any) =>
    requests.get(`${NEWSLETTER_ERROR_URL}`, params),
  //   update: (params: any) =>
  //     requests.put(`${NEWSLETTER_URL}/step1/${params.id}`, params),
  //   create: (params: any) =>
  //     requests.post(`${NEWSLETTER_URL}/create`, params),
  //   preview: (params: any) => requests.post(`${NEWSLETTER_URL}/preview`, params),

  //   delete: (id: number) => requests.delete(`${NEWSLETTER_URL}/step1/${id}`),
};
