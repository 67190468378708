import { PRODUCT_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminProductService = {
  getAll: (params: any) => requests.get(`${PRODUCT_URL}`, params),
  create: (params: any) => requests.post(`${PRODUCT_URL}`, params, true),
  getSingle: (id: number) => requests.get(`${PRODUCT_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.post(`${PRODUCT_URL}/${id}`, params, true),
  delete: (id: number) => requests.delete(`${PRODUCT_URL}/${id}`),
  updatePosition: (params: any) => requests.patch(`${PRODUCT_URL}`, params),
};
