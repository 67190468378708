import { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorToast } from "../../shared/toasters/toasters";
import { AuthService } from "../service";

export interface ILogin {
  username: string;
  password: string;
}

export function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [state, setState] = useState<ILogin>({
    username: "",
    password: "",
  });
  const [visibilityPassword, setVisibility] = useState(false);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value.trim(),
    });
  }
  const login = (event: any) => {
    event.preventDefault();
    AuthService.login(state)
      .then((res) => {
        localStorage.setItem("language", "en");
        localStorage.setItem("token", res.token);
        window.location.reload();
      })
      .catch((error) => ErrorToast(error));
  };

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      navigate("/app");
    }
  }, []);
  return (
    <section className="auth-page-wrapper py-5 position-relative d-flex align-items-center justify-content-center min-vh-100 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row g-0">
                  <div className="col-lg-5">
                    <div className="card auth-card bg-primary h-100 border-0 shadow-none p-sm-3 overflow-hidden mb-0">
                      <div className="card-body p-4 d-flex justify-content-between flex-column">
                        <div className="auth-image mb-3">
                          <img
                            src="assets/images/logo-white.png"
                            alt=""
                            height="26"
                          />
                          <img
                            src="assets/images/effect-pattern/auth-effect-2.png"
                            alt=""
                            className="auth-effect-2"
                          />
                          <img
                            src="assets/images/effect-pattern/auth-effect.png"
                            alt=""
                            className="auth-effect"
                          />
                          <img
                            src="assets/images/effect-pattern/auth-effect.png"
                            alt=""
                            className="auth-effect-3"
                          />
                        </div>

                        <div>
                          <h3 className="text-white">
                            Start your journey with us.
                          </h3>
                          <p className="text-white-75 fs-15">
                            It brings together your tasks, projects, timelines,
                            files and more
                          </p>
                        </div>
                        <div className="text-center text-white-75">
                          <p className="mb-0">
                            &copy;
                            {currentYear} itMedia
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-7">
                    <div className="card mb-0 border-0 shadow-none">
                      <div className="card-body px-0 p-sm-5 m-lg-4">
                        <div className="text-center mt-2">
                          <h5 className="text-primary fs-20">
                            {t("login.welcome_back")}!
                          </h5>
                        </div>
                        <div className="p-2 mt-5">
                          <form action="index.html">
                            <div className="mb-3">
                              <label htmlFor="username" className="form-label">
                                {t("global.username")}
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="username"
                                name="username"
                                placeholder="Enter username"
                                onChange={(e) => handleChange(e)}
                              />
                            </div>

                            <div className="mb-3">
                              <div className="float-end mt-2 mb-3">
                                <Link to="/forgot" className="text-muted">
                                  {t("login.forgot_password")}?
                                </Link>
                              </div>
                              <label
                                className="form-label"
                                htmlFor="password-input"
                              >
                                {t("login.password")}
                              </label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <input
                                  name="password"
                                  onChange={handleChange}
                                  type={`${
                                    visibilityPassword ? "text" : "password"
                                  }`}
                                  className="form-control pe-5"
                                  onKeyDown={(event) =>
                                    event.key === "Enter" && login(event)
                                  }
                                  placeholder="Enter password"
                                  id="password-input"
                                />
                                <button
                                  className="btn btn-link position-absolute end-0 top-12 text-decoration-none text-muted"
                                  type="button"
                                  id="password-addon"
                                  onClick={() =>
                                    setVisibility((prev: boolean) => !prev)
                                  }
                                >
                                  <i
                                    className={`${
                                      visibilityPassword
                                        ? "ri-eye-line"
                                        : "ri-eye-off-line"
                                    }`}
                                  />
                                </button>
                              </div>
                            </div>

                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="auth-remember-check"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="auth-remember-check"
                              >
                                Remember me
                              </label>
                            </div>

                            <div className="mt-4">
                              <button
                                className="btn btn-primary w-100"
                                type="submit"
                                onClick={login}
                              >
                                Sign In
                              </button>
                            </div>
                          </form>

                          <div className="text-center mt-5">
                            <p className="mb-0">
                              Don't have an account ?{" "}
                              <a
                                href="auth-signup-basic.html"
                                className="fw-semibold text-secondary text-decoration-underline"
                              >
                                SignUp
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
