import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MenuPositionService } from "../service";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorToast } from "../../../shared/toasters/toasters";

export const CreateForm = ({ state, setState }: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (!!Number(id)) {
      MenuPositionService.getSingle(+id!)
        .then((response) => {
          const { data } = response;
          setState(data);
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);

  return (
    <>
      {" "}
      <Card>
        <Card.Body>
          <Row>
            <Col md={6} className="mb-3">
              <label htmlFor="title" className="required-field">
                {t("global.location")}
              </label>
              <input
                type="text"
                name="location"
                className="form-control"
                required
                onChange={changeHandler}
                value={state?.id && state?.location}
              />
            </Col>
          </Row>
          <div className="col-12 my-2 d-flex justify-content-end text-align-center">
            <div>
              <button
                className="btn btn-info"
                onClick={() => navigate("/admin/menu-position")}
              >
                {t("global.cancel")}
              </button>
              <button className="btn btn-primary ms-3" type="submit">
                {state?.id
                  ? `  ${t("global.update")}`
                  : `${t("global.create")}`}
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
