import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CreateForm } from "./CreateForm";
import { Form } from "react-bootstrap";

import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";

import { useTranslation } from "react-i18next";

import { MenuPositionService } from "../service";

export const UpdateMenuPosition = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState<any>();
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const [password, setPassword] = useState("");

  const submitHandler = (event: React.FormEvent, id?: number) => {
    if (state?.id) {
      MenuPositionService.update(state)
        .then((response) => {
          SuccessToast(t(`menu_position.successfully_updated`));
          navigate("/admin/menu-position");
          setTriggerUpdate(!triggerUpdate);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    }
  };

  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e, state?.id);
        }}
      >
        <CreateForm
          state={state}
          setState={setState}
          setPassword={setPassword}
        />
      </Form>
    </div>
  );
};
