import { NEWS_THEME_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminNewsThemeService = {
  getAll: (params: any) => requests.get(`${NEWS_THEME_URL}`, params),
  update: (params: any) =>
    requests.put(`${NEWS_THEME_URL}/${params.id}`, params),
  create: (params: any) => requests.post(`${NEWS_THEME_URL}`, params, true),
  getSingle: (id: number) => requests.get(`${NEWS_THEME_URL}/${id}`),
  delete: (id: number) => requests.delete(`${NEWS_THEME_URL}/${id}`),
};
