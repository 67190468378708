import { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { PICTURE_URL } from "../../../helpers/api.routes";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { IForm } from "../interface";
import { AdminEventSummaryService } from "../service";
import { ISelect } from "../../../shared/interface";

export const CreateForm = ({
  state,
  setImage,
  categories,
  setState,
}: IForm) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const fileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImage(e.target.files![0]);
  };
  const selectHandler = (e: ISelect | null) => {
    setState((prev) => ({ ...prev, category: e!.value }));
  };

  const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const mappedData = categories?.map((category) => ({
    label: category.name,
    value: category.id,
  }));
  useEffect(() => {
    if (!!Number(id)) {
      AdminEventSummaryService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;

          setState({ ...data, category: data.category.id });
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={12} className="mb-3">
            <label htmlFor="title" className="required-field">
              {t("global.title")}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              required
              onChange={changeHandler}
              value={state?.id && state?.title}
            />
          </Col>
          <Col md={12}>
            <label htmlFor="subtitle" className="required-field">
              {t("global.subtitle")}
            </label>
            <input
              type="text"
              name="subtitle"
              className="form-control"
              required
              onChange={changeHandler}
              value={state?.id && state?.subtitle}
            />
          </Col>
          <Col md={6}>
            <label htmlFor="url" className="required-field">
              {t("table_headers.url")}
            </label>
            <input
              type="text"
              name="url"
              className="form-control"
              required
              onChange={changeHandler}
              value={state?.id && state?.url}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="category" className="required-field">
              {t("table_headers.category")}
            </label>
            <Select
              className="react-select-field"
              onChange={selectHandler}
              options={mappedData}
              value={mappedData?.find(
                (category: any) => category.value === state?.category,
              )}
            />
          </Col>
          <Col md={2} className="">
            <div className="form-check form-switch p-0">
              <label
                className="d-block text-start"
                htmlFor="flexSwitchCheckDefault"
              >
                {t("global.active")}
              </label>
              <div className="row">
                <input
                  className="form-check-input switch-custom ms-4 mt-2"
                  type="checkbox"
                  name="isActive"
                  checked={state?.isActive}
                  onChange={switchHandler}
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </Col>
          <Col md={6} className="my-3">
            <div>
              <label
                htmlFor="exampleFormControlTextarea5"
                className="form-label"
              >
                {t("download.description")}
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea5"
                rows={3}
                name="description"
                value={state?.description}
                onChange={changeHandler}
              ></textarea>
            </div>
          </Col>
          <Col md={6} className="my-3">
            <div>
              <label htmlFor="imageLegend" className="form-label">
                {t("admin_event_summary.image_legend")}
              </label>
              <textarea
                className="form-control"
                id="imageLegend"
                rows={3}
                name="imageLegend"
                value={state?.imageLegend}
                onChange={changeHandler}
              ></textarea>
            </div>
          </Col>
        </Row>

        <div className="mt-3">
          <label htmlFor="imageFile"> {t("download.image")}</label>
          <input
            onChange={fileHandler}
            className="form-control"
            type="file"
            accept="image/*"
            id="imageFile"
          />
        </div>
        {state?.image && (
          <div className="col-md-6 py-3">
            <img
              className="rounded"
              alt="200x200"
              width={200}
              src={PICTURE_URL + state?.image.imagePath}
              data-holder-rendered="true"
            />
          </div>
        )}
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                navigate("/admin/event-summary");
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
