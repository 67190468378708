import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateForm } from "./CreateForm";
import { Form } from "react-bootstrap";
import { AdminCategoryDownloadService } from "../../downloadCategory/service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AdminMagazineService } from "../service";
import { EVENT_SUMMARY_URL, MAGAZINE_URL } from "../../../helpers/api.routes";
import { multipartFormData } from "../../../helpers/api.services";
import { useTranslation } from "react-i18next";
import { AdminMagazineCategoryService } from "../../magazineCategory/service";

export const UpdateMagazine = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState();
  const { t } = useTranslation();
  const [state, setState] = useState<any>();
  const [image, setImage] = useState<File>();
  const [document, setDocument] = useState<File>();
  const submitHandler = (event: React.FormEvent, id?: number) => {
    if (state?.id) {
      multipartFormData(
        image,
        `${MAGAZINE_URL}/${state?.id}`,
        JSON.stringify({
          ...state,
        }),
        "post"
      )
        .then((response) => {
          SuccessToast(t(`magazine.successfully_updated`));
          // setIsMenuOpened(false);
          setState({});
          navigate("/admin/magazine");
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    AdminMagazineCategoryService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e, state?.id);
        }}
      >
        <CreateForm
          image={image}
          document={document}
          setImage={setImage}
          setDocument={setDocument}
          categories={categories}
          state={state}
          setState={setState}
        />
      </Form>
    </div>
  );
};
