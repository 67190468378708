export function removeElementsByClass(className: string) {
  const elements = document.getElementsByClassName(className);
  while (elements.length > 0) {
    elements && elements[0]?.parentNode?.removeChild(elements[0]);
  }
}

export function InitEditorMulti(
  value: string = "",
  cb: (value: string) => void,
  className: string
) {
  setTimeout(() => {
    (window as any).tinymce.init({
      selector: className,
      apiKey: "a5kCyOAV4ME42hXaJ6m5GIpk",
      width: "100%",
      height: 500,
      sidebar: undefined,
      menubar: "file edit view insert format tools table tc help",
      plugins:
          "image link file-manager print code preview importcss searchreplace autolink autosave save directionality  visualblocks visualchars fullscreen image link media  template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists  wordcount imagetools textpattern noneditable help   charmap  quickbars emoticons  ",
      statusbar: true,
      toolbar:
          "Upload undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist  | forecolor backcolor  removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media  template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment code",
      extended_valid_elements: "i[class]",
      init_instance_callback: (editor: any) => {
        console.log(editor, 'editor')
        editor?.on("input", (e: any) => {
          console.log(e, 'e')
          cb(e.target.innerHTML);
        });
        editor?.on("focus", (e: any) => {
          removeElementsByClass(
              "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
          );
        });
        const observer = new MutationObserver((mutationsList) => {
          for (const mutation of mutationsList) {
            if (mutation.type === "childList") {
              cb(editor.getContent());
            }
          }
        });
        const editorElement = editor.getContainer();
        observer.observe(editorElement, {childList: true, subtree: true});
      },
      setup: (editor: any) => {
        editor.on("init", () => {
          editor.setContent(value);
        });
      },
    });
  }, 0)
}
