export const themeSelect = (theme: any) => {
  return {
    ...theme,
    borderRadius: 5,
    minHeight: 38,
    colors: {
      ...theme.colors,
      text: "white",
      primary25: "var(--vz-input-border)",
      primary: "var(--vz-input-border)",
    },
  };
};
