import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PICTURE_URL } from "../../../helpers/api.routes";
// import { N1ED } from "@edsdk/n1ed-react";
// import { Editor } from "tinymce";
import { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { editorConfigurationCustom } from "../../../shared/functions/editorConfigurationCustom";
// @ts-ignore
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { AdminMediadatenService } from "../service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { InitEditor } from "../../../shared/tinyMce/InitEditor";
export const CreateForm = ({
  state,
  setState,
  setDocument,
  triggerUpdate,
}: any) => {
  const { t } = useTranslation();
  const firstLoad = useRef(true);
  const [selectEditor, setSelectEditor] = useState<boolean>(false);
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const [editorData, setEditorData] = useState<any>();
  const fileHandler = (e: any) => {
    if (e.target.id === "documentFile") {
      setDocument(e.target.files[0]);
    }
  };

  // const changeEditorHandler = (content: string, editor: Editor) => {
  //   setState((prev: any) => ({ ...prev, [editor?.id]: content }));
  //   if (!firstLoad.current) {
  //     setEditorData(editor);
  //   }
  //   firstLoad.current = false;
  // };
  const changeHandlerEditor = (value: string, eventName: string) => {
    setState((prev: any) => ({ ...prev, [eventName]: value }));
  };
  function removeElementsByClass(className: string) {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      elements && elements[0]?.parentNode?.removeChild(elements[0]);
    }
    setSelectEditor(false);
  }
  const handleText = (text: string) => {
    setState((prev: any) => ({ ...prev, text }));
  };
  useEffect(() => {
    if (selectEditor) {
      removeElementsByClass(
        "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
      );
    }
  }, [selectEditor]);
  useEffect(() => {
    AdminMediadatenService.getAll()
      .then((response) => {
        const { data } = response;
        InitEditor(data?.text ?? undefined, handleText);
        setState(data);
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate]);
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6} className="mb-2">
            <label htmlFor="title" className="required-field">
              {t("global.title")}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.title}
            />
          </Col>
          <Col md={12} className="mb-2">
            <label htmlFor="title" className="required-field">
              {t("global.text")}
            </label>
            <textarea className="editor" onChange={console.log}></textarea>
            {/* <N1ED
              id={`text`}
              onFocus={(e) => setSelectEditor(true)}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              initialValue={editorData ? editorData : state ? state?.text : ""}
              onEditorChange={changeEditorHandler}
            /> */}
            {/* <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.text ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "text");
              }}
            /> */}
          </Col>

          <Col md={12}>
            <div className="">
              <label htmlFor="documentFile">{t("download.document")}</label>
              <input
                onChange={fileHandler}
                className="form-control"
                type="file"
                id="documentFile"
                accept="application/*"
              />
            </div>
            {state?.document && (
              <div className="d-flex align-items-center mt-3">
                <i className="bi bi-file-earmark display-3"></i>
                <a
                  target="_blank"
                  download
                  href={PICTURE_URL + state.document.documentPath}
                >
                  {state.document.documentName}
                </a>
              </div>
            )}
          </Col>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button className="btn btn-primary ms-3" type="submit">
              {t("global.save")}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
