import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { AdminGalleryService } from "../service";
import DatePicker from "react-datepicker";
import { AdminVideoService } from "../../../galleryVideo/service";
import { AdminAlbumService } from "../../../album/service";
import { useRef } from "react";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { CustomImageHandler } from "../../../../shared/components/CustomImageHandler";
import Cropper, { ReactCropperElement } from "react-cropper";
import { nanoid } from "nanoid";
import { EducationContinuingService } from "../../service";

export const CreateForm = ({
  setFiles,
  files,
  state,
  setImage,
  setState,
}: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [mainImage, setMainImage] = useState<any>("");
  const navigate = useNavigate();
  const cropperRef = useRef<ReactCropperElement>(null);
  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;

    setState((prev: any) => ({
      ...prev,
      mainImage: {
        ...prev.mainImage,
        base64: cropper!.getCroppedCanvas()?.toDataURL(),
      },
    }));
  };
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const handleImageInput = (i: number, e: any, element: any) => {
    if (e.target.files.length) {
      let newFormValuesImg = [
        ...state.fileData.map((file: any, index: any) =>
          index === i
            ? { ...file, index: files.length, name: e.target.files[0]?.name }
            : file
        ),
      ];
      (newFormValuesImg as any)[i][e.target.name] = URL.createObjectURL(
        e.target.files[0]
      );

      setFiles((prev: any) => [...prev, e.target.files[0]]);
      setState((prev: any) => ({ ...prev, fileData: newFormValuesImg }));
    }
  };
  const addFormFields = () => {
    setState((prev: any) => ({
      ...prev,
      fileData: [
        ...prev.fileData,
        {
          id: null,
          path: "",
          name: "",
          index: null,
        },
      ],
    }));
    const newFormData = [...state.fileData];
    const id = nanoid();
    newFormData.push({
      id: null,
      path: "",
      name: "",
      index: null,
    });
    setState((prev: any) => ({ ...prev, fileData: newFormData }));
  };
  const removeFormFields = (index: any, id: any, item: any) => {
    if (state?.fileData.length === 1) {
      setState((prev: any) => ({
        ...prev,
        fileData: [
          ...prev.fileData,
          {
            id: null,
            path: "",
            name: "",
            index: null,
          },
        ],
      }));
    }
    if (id === null) {
      // console.log(item?.name, "item");
      // console.log(
      //   files?.map((item: any) => console.log(item?.name)),
      //   "fileName"
      // );
      setState((prev: any) => ({
        ...prev,
        fileData: prev.fileData.filter((input: any, idx: any) => idx !== index),
      }));
      setFiles((prev: any) =>
        prev.filter((input: any) => input?.name != item?.name)
      );
    } else {
      EducationContinuingService.deleteImg(id)
        .then((response) => {
          setState((prev: any) => ({
            ...prev,
            fileData: prev.fileData.filter(
              (input: any, idx: any) => idx !== index
            ),
          }));
          SuccessToast(t(`download.successfully_deleted`));
        })
        .catch((error) => ErrorToast(error));
    }
  };
  const handleMainImage = (e: any) => {
    setImage(e.target.files[0]);
    setMainImage(URL.createObjectURL(e.target.files[0]!));
  };
  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  useEffect(() => {
    EducationContinuingService.getSingle()
      .then((response) => {
        const { data } = response;
        if (data?.id) {
          setState({
            ...data,
            fileData: data?.additionalFiles?.length
              ? data?.additionalFiles
              : [
                  {
                    id: null,
                    path: "",
                    name: "",
                    index: null,
                  },
                ],
          });
          setFiles(
            data?.additionalFiles?.length
              ? data?.additionalFiles
              : [
                  {
                    id: null,
                    path: "",
                    name: "",
                    index: null,
                  },
                ]
          );
          window.scrollTo(0, 0);
        }
      })
      .catch((error) => ErrorToast(error));
  }, []);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={9} className="mt-2">
            <label>{t("global.title")}</label>
            <input
              name="title"
              type="text"
              className="form-control"
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.title}
            />
          </Col>
          <Col md={3} className=" mt-2">
            <label
              className="d-block text-start"
              htmlFor="flexSwitchCheckDefault"
            >
              {t("global.active")}
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input switch-custom"
                type="checkbox"
                name="isActive"
                checked={state?.isActive}
                onChange={switchHandler}
                role="switch"
                id="flexSwitchCheckDefault"
              />
            </div>
          </Col>
          <div className="mt-2">
            <div>
              <div className="form-inline">
                <Row>
                  <label htmlFor=""> {t("news.main_image")}</label>
                  <Col md={12}>
                    <div className=" mt-2 mx-auto ">
                      <div className="card mb-0 p-3">
                        <div className="">
                          <div className="">
                            <div className="text-center">
                              <div className="profile-user position-relative d-inline-block mx-auto">
                                {mainImage || state?.mainImage?.path ? (
                                  <CustomImageHandler
                                    path={
                                      mainImage
                                        ? mainImage
                                        : state?.mainImage?.path
                                    }
                                    classes="avatar-xl img-thumbnail user-profile-image"
                                    alt="user-profile-image"
                                  />
                                ) : (
                                  <img
                                    src="/assets/images/img-placeholder.png"
                                    className="avatar-xl img-thumbnail user-profile-image"
                                    alt="user-profile-image"
                                  />
                                )}
                                <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                  <input
                                    name="path"
                                    type="file"
                                    accept="image/*"
                                    id="main"
                                    // ref={imageRef}
                                    className="profile-img-file-input "
                                    onChange={(e) => handleMainImage(e)}
                                  />
                                  <label
                                    // htmlFor="profile-img-file-input"\
                                    htmlFor="main"
                                    className="profile-photo-edit avatar-xs"
                                  >
                                    <span className="avatar-title rounded-circle bg-light text-body">
                                      <i className="ri-camera-fill" />
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {mainImage && (
                          <Col md={12} className="mt-3">
                            <Cropper
                              src={mainImage}
                              style={{
                                maxHeight: 400,
                                width: "90%",
                                margin: "0 auto",
                              }}
                              initialAspectRatio={6 / 4}
                              guides={false}
                              crop={onCrop}
                              ref={cropperRef}
                            />
                          </Col>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div className="mt-2">
            <label>{t("global.otherImages")}</label>
            {state?.fileData?.map((element: any, index: any) => {
              return (
                <div className="form-inline" key={element?.id}>
                  <Row>
                    <Col md={12}>
                      <div className=" mt-2 mx-auto ">
                        <div className="card mb-0">
                          <div className="card-body">
                            <div className="">
                              <div className="text-center">
                                <div className="profile-user position-relative d-inline-block mx-auto">
                                  {(element as any)?.path ? (
                                    <CustomImageHandler
                                      path={
                                        element?.path
                                          ? (element as any)?.path
                                          : `${state?.profilePicture?.path}`
                                      }
                                      classes="avatar-xl img-thumbnail user-profile-image"
                                      alt="user-profile-image"
                                    />
                                  ) : (
                                    <img
                                      src="/assets/images/img-placeholder.png"
                                      className="avatar-xl img-thumbnail user-profile-image"
                                      alt="user-profile-image"
                                    />
                                  )}
                                  <div
                                    key={index}
                                    className="avatar-xs p-0 rounded-circle profile-photo-edit"
                                  >
                                    <input
                                      name="path"
                                      id={index as any}
                                      type="file"
                                      accept="image/*"
                                      // ref={imageRef}
                                      className="profile-img-file-input "
                                      onChange={(e) =>
                                        handleImageInput(index, e, element)
                                      }
                                    />
                                    <label
                                      // htmlFor="profile-img-file-input"\
                                      htmlFor={index as any}
                                      className="profile-photo-edit avatar-xs"
                                    >
                                      <span className="avatar-title rounded-circle bg-light text-body">
                                        <i className="ri-camera-fill" />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* {index ? ( */}

                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-danger  my-2"
                      onClick={() =>
                        removeFormFields(index, element?.id, element)
                      }
                    >
                      {t("global.remove")}
                    </button>
                    {index == state.fileData.length - 1 && (
                      <button
                        className="btn btn-success my-2 ms-2"
                        type="submit"
                        onClick={() => {
                          addFormFields();
                        }}
                      >
                        {t("global.add_another")}
                      </button>
                    )}
                  </div>

                  {/* ) : index === 0 && state.fileData.length === 1 ? (
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-success my-2"
                        type="submit"
                        onClick={() => {
                          addFormFields();
                        }}
                      >
                        {t("global.add_another")}
                      </button>
                    </div>
                  ) : null} */}
                </div>
              );
            })}
          </div>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                navigate("/admin/education-continuing");
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {t("global.save")}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
