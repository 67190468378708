import React, {useCallback, useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useNavigate, useParams} from "react-router-dom";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {arrayMoveImmutable} from "array-move";
import ImageViewer from "react-simple-image-viewer";
import {ModelGalleryService} from "../service";
import {ConfirmDelete} from "../../../shared/components/ConfirmDelete";

export interface IPhoto {
  src: string;
  width: number;
  height: number;
}

export const ModelGalleryUpdate = ({
  setIsMenuOpened,

  fileHandler,
}: any) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [show, setShow] = useState(false);
  const currentTheme = useSelector((state: RootState) => state.theme);
  const [albums, setAlbums] = useState<any[]>([]);
  const [state, setState] = useState<any[]>();
  const [newIds, setNewIds] = useState<any[]>([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [selectedImage, setSelectedImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [selectedPrompt, setselectedPrompt] = useState<any>();
  const openImageViewer = useCallback((index: any) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const onSortEnd = (oldIndex: number, newIndex: number) => {
    updatePosition(arrayMoveImmutable(state as any, oldIndex, newIndex));
    setState((array: any) => arrayMoveImmutable(array, oldIndex, newIndex));
  };
  const handleShow = () => setShow(true);
  const updatePosition = (newIds: number[]) => {
    const newArray = newIds?.map((item: any) => item?.id);
    ModelGalleryService.updatePosition({ ids: newArray });
  };
  const deleteHandler = () => {
    selectedPrompt &&
    ModelGalleryService.delete(selectedPrompt)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`news.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
            setSelectedImage(0);
          }
        })
        .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    if (!!Number(id)) {
      ModelGalleryService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;
          const mapped = data?.data?.map((doc: any) => {
            return {
              id: doc.image?.id,
              path: doc.image.path,
            };
          });

          setState(mapped);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id, triggerUpdate]);
  return (
    <Card>
      <div>
        {/* <SortableList
          onSortEnd={onSortEnd}
          className="list"
          draggedItemClassName="dragged"
        > */}
        {state?.map((item, index) => (
          // <SortableItem key={item?.id}>
          <img
            onClick={() => setSelectedImage(item?.id)}
            src={item?.path}
            className="object-fit-cover"
            onDoubleClick={() => {
              openImageViewer(index);
              setSelectedImage(0);
            }}
            key={item?.id}
            width={250}
            height={250}
            style={{
              margin: "2px",
              border: `${selectedImage === item?.id ? "2px solid red" : "none"}`,
            }}
            alt=""
          />
          // </SortableItem>
        ))}
        {/* </SortableList> */}
        {isViewerOpen && (
          <ImageViewer
            src={state!?.map((img: any) => img?.path as unknown as string)}
            backgroundStyle={{ zIndex: 10000 }}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end my-3">
        <button
          className="btn btn-sm  btn-primary me-3"
          onClick={() => navigate(-1)}
        >
          {t("global.back")}
        </button>
        <button
          className="btn btn-sm btn-danger "
          disabled={selectedImage === 0}
          onClick={() => {
            handleShow();
            setselectedPrompt(selectedImage);
          }}
        >
          <i className="ri-delete-bin-fill me-1" />
          {t("global.delete_image")}
        </button>
      </div>
      <ConfirmDelete
        show={show}
        setShow={setShow}
        itemName={selectedPrompt?.title}
        deleteHandler={deleteHandler}
        product={selectedPrompt}
        selectedProduct={selectedPrompt}
      />
    </Card>
  );
};
