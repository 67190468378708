import { USERS_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminUserService = {
  getAll: (params: any) => requests.get(`${USERS_URL}`, params),
  create: (params: any) => requests.post(`${USERS_URL}`, params),
  changePassword: (id: any, params: any) =>
    requests.post(`${USERS_URL}/password-change/${id}`, params),
  getSingle: (id: number) => requests.get(`${USERS_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.put(`${USERS_URL}/${id}`, params),
  delete: (id: number) => requests.delete(`${USERS_URL}/${id}`),
};
