import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { themeSlice } from "../../store/slices/theme.slice";
import { BASE_URL } from "../../helpers/api.routes";
import { useTranslation } from "react-i18next";

export const MainHeader = () => {
  const { t } = useTranslation();
  const getAuth = useSelector((state: any) => state.auth);
  const domain = useSelector((state: any) => state.company.clientDomain);
  const dispatch = useDispatch();
  const selectedTheme = localStorage.getItem("currentTheme");
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const body = document?.querySelector("body");
  const navigate = useNavigate();
  const [isMenuHidden, setIsMenuHidden] = useState(
    document &&
      !!document.querySelector("html")?.getAttribute("data--size")?.length
  );
  const token = localStorage.getItem("token");

  const logoutUser = () => {
    localStorage.clear();
    window.location.reload();
  };
  const checkPermission = (permission: string) => {
    return getAuth?.administrationData?.[
      localStorage.getItem("website")!
    ]?.includes(permission);
  };

  const profilePage = () => {
    navigate("/app/profile");
  };

  useEffect(() => {
    if (token === null) {
      navigate("/");
    }
  }, []);

  // const changeTheme = (currTheme: "dark" | "light") => {
  //   if (currTheme === "light") {
  //     localStorage.setItem("currentTheme", "dark");
  //     dispatch(themeSlice.actions.setTheme("dark"));
  //     document.documentElement.setAttribute("data-sidebar", "dark");
  //     document.documentElement.setAttribute("data-topbar", "dark");
  //     document.documentElement.setAttribute("data-bs-theme", "dark");
  //   } else {
  //     dispatch(themeSlice.actions.setTheme("light"));
  //     localStorage.setItem("currentTheme", "light");
  //     document.documentElement.setAttribute("data-sidebar", "light");
  //     document.documentElement.setAttribute("data-topbar", "light");
  //     document.documentElement.setAttribute("data-bs-theme", "light");
  //   }
  // };

  const handleClickHamburger = () => {
    if (
      document.querySelector("body")?.classList?.value === "twocolumn-panel"
    ) {
      document.querySelector("body")?.classList.add("vertical-sidebar-enable");
    } else {
      document.documentElement.setAttribute(
        "data-sidebar-size",
        isMenuHidden ? "sm" : "lg"
      );
      setIsMenuHidden((prev) => !prev);
    }
  };
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  function menuToggle() {
    body?.classList.add("vertical-sidebar-enable");
  }
  function getWindowSize() {
    const { innerWidth } = window;
    return { innerWidth };
  }
  useEffect(() => {
    document.documentElement.setAttribute(
      "data-sidebar",
      selectedTheme ?? "light"
    );
    document.documentElement.setAttribute(
      "data-topbar",
      selectedTheme ?? "light"
    );
    document.documentElement.setAttribute(
      "data-bs-theme",
      selectedTheme ?? "light"
    );
    localStorage.setItem("currentTheme", selectedTheme ?? "light");
    dispatch(
      themeSlice.actions.setTheme(
        (selectedTheme as "light" | "dark") ?? "light"
      )
    );
  }, []);

  const headerHanlder = (web: string) => {
    localStorage.setItem("website", web);
    navigate("/app");
    window.location.reload();
  };

  const availableSites = Object.keys(
    getAuth.administrationData ? getAuth.administrationData : []
  ).filter((key) => (getAuth.administrationData[key].length ? key : ""));

  return (
    <>
      <header id="page-topbar">
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              {/* <!-- LOGO --> */}
              <div className="navbar-brand-box horizontal-logo">
                <a href="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src="assets/images/logo-sm.png" alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src="assets/images/logo-dark.png" alt="" height="25" />
                  </span>
                </a>

                <a href="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src="assets/images/logo-sm.png" alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img
                      src="assets/images/logo-light.png"
                      alt=""
                      height="25"
                    />
                  </span>
                </a>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn"
              >
                <a href={domain} target="_blank">
                  <i className="text-black fs-22 ri-home-4-line"></i>
                </a>
              </button>

              <button
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
                onClick={
                  windowSize.innerWidth < 767
                    ? menuToggle
                    : handleClickHamburger
                }
              >
                <span
                  className={`hamburger-icon
                    ${isMenuHidden ? "" : "open"}
                    `}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>
            <div className="dropdown d-flex align-items-center">
              <h2
                className="dropdown-toggle m-0 cursor-pointer"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t(`enums.${localStorage.getItem("website")}`) ??
                  t(`enums.${availableSites[0]}`)}
              </h2>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                {availableSites.map((ele) => (
                  <li onClick={() => headerHanlder(ele)} key={ele}>
                    <p className="dropdown-item m-0">{t(`enums.${ele}`)}</p>
                  </li>
                ))}
                {/* {Object.keys(getAuth.administrationData).map((ele) => {
                  return getAuth.administrationData[ele].length ? (
                    <li onClick={() => headerHanlder(ele)}>
                      <p className="dropdown-item m-0">{ele}</p>
                    </li>
                  ) : (
                    ""
                  );
                })} */}
              </ul>
            </div>

            <div className="d-flex align-items-center">
              {/* {getAuth?.roles?.includes("ROLE_SUPER_ADMIN")  */}
              {true && (
                <>
                  <div className="dropdown topbar-head-dropdown ms-1 header-item">
                    <Link
                      to="/admin"
                      type="button"
                      className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none"
                    >
                      <i className="text-black ri-user-settings-fill fs-22"></i>
                    </Link>
                  </div>
                  {/* {checkPermission("site_settings") && (
                    <>
                      <div className="dropdown topbar-head-dropdown ms-1 header-item">
                        <Link
                          to="/admin/home-settings"
                          type="button"
                          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none"
                        >
                          <i className="ri-home-gear-line fs-22"></i>
                        </Link>
                      </div>
                      {/* <div className="dropdown topbar-head-dropdown ms-1 header-item">
                        <Link
                          to="/admin/home-settings-second"
                          type="button"
                          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none"
                        >
                          <i className="ri-home-gear-fill fs-22"></i>
                        </Link>
                      </div> */}
                  {/* </>
                  )} */}

                  <div className="dropdown topbar-head-dropdown ms-1 header-item">
                    <Link
                      to="/admin/settings"
                      type="button"
                      className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none"
                    >
                      <i className="text-black ri-settings-5-line fs-22"></i>
                    </Link>
                  </div>
                </>
              )}

              {/*<div className="dropdown topbar-head-dropdown ms-1 header-item">*/}
              {/*  <button*/}
              {/*      onClick={() => changeTheme(theme)}*/}
              {/*      type="button"*/}
              {/*      className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none"*/}
              {/*  >*/}
              {/*    {localStorage.getItem("currentTheme") === "dark" ? (*/}
              {/*        <i className="text-white bx bx-sun fs-22"></i>*/}
              {/*    ) : (*/}
              {/*        <i className="text-black bx bx-moon fs-22"></i>*/}
              {/*    )}*/}
              {/*  </button>*/}
              {/*</div>*/}

              <div className="dropdown ms-sm-3 header-item topbar-user">
                <button
                  type="button"
                  className="btn"
                  id="page-header-user-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="d-flex align-items-center">
                    <img
                      className="rounded-circle header-profile-user"
                      src={
                        getAuth?.image?.path
                          ? `${BASE_URL?.slice(0, -4)}${getAuth?.image?.path}`
                          : "/assets/images/img-placeholder.png"
                      }
                      alt="Header Avatar"
                    />
                    <span className="text-start ms-xl-2">
                      <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                        {getAuth?.firstName} {getAuth?.lastName}
                      </span>
                      <span className="d-none d-xl-block ms-1 fs-13 text-reset user-name-sub-text">
                        Founder
                      </span>
                    </span>
                  </span>
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                  {/* <!-- item--> */}
                  <h6 className="dropdown-header">{`Welcome ${getAuth?.firstName}!`}</h6>
                  <a className="dropdown-item" onClick={profilePage}>
                    <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle cursor-pointer">
                      {" "}
                      {t("profile.profile")}
                    </span>
                  </a>

                  <a className="dropdown-item" onClick={logoutUser}>
                    <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
                    <span
                      className="align-middle cursor-pointer"
                      data-key="t-logout"
                    >
                      {t("global.logout")}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
