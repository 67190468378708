import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { AdminHomeSettingsService } from "./service";
import { CreateForm } from "./components/CreateForm";
import { useTranslation } from "react-i18next";
import { title } from "process";

export const AdminHomeSettings = () => {
  const { t } = useTranslation();

  const [triggerUpdate, setTriggerUpdate] = useState(false);
  // const [data, setData] = useState<any>();
  const [inputs, setInputs] = useState([{ title: "", category: null }]);
  const submitHandler = (indexKey: number) => {
    // const newArray = inputs?.map((input: any) => {
    //   return {
    //     title: input?.title,
    //     link: input?.link,
    //     category: input?.category?.id ?? input.category,
    //     key: input?.key,
    //   };
    // });
    AdminHomeSettingsService.create({
      ...inputs[indexKey],
      category:
        (inputs[indexKey]?.["category"] as any)?.id ??
        (inputs[indexKey]?.["category"] as any),
    })
      .then((response) => {
        SuccessToast(t(`settings.successfully_saved`));

        setTriggerUpdate(!triggerUpdate);
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    AdminHomeSettingsService.getAll()
      .then((response) => {
        const { data } = response;
        setInputs(data?.siteSettings);
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate]);

  return (
    <>
      <div className="col-12">
        {/* <Form
          onSubmit={(e) => {
            e.preventDefault();
            // submitHandler(positionId);
          }}
        > */}
        <CreateForm
          state={inputs}
          setState={setInputs}
          submitHandler={submitHandler}
        />
        {/* </Form> */}
      </div>
    </>
  );
};
