import { MAGAZINE_CATEGORY_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminMagazineCategoryService = {
  getAll: (params: any) => requests.get(`${MAGAZINE_CATEGORY_URL}`, params),
  create: (params: any) => requests.post(`${MAGAZINE_CATEGORY_URL}`, params),
  getSingle: (id: number) => requests.get(`${MAGAZINE_CATEGORY_URL}/${id}`),
  updatePosition: (params: any) =>
    requests.patch(`${MAGAZINE_CATEGORY_URL}`, params),
  update: (params: any, id: number) =>
    requests.put(`${MAGAZINE_CATEGORY_URL}/${id}`, params),
  delete: (id: number) => requests.delete(`${MAGAZINE_CATEGORY_URL}/${id}`),
};
