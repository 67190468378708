import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { BannerZonaList } from "./BannerZonaList";
import { BannerZonaCreate } from "./BannerZonaCreate";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AdminBannerService } from "../service";

export const BannerZona = () => {
  const { t } = useTranslation();

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [list, setList] = useState<any>(undefined);
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };

  const editHandler = (brandId: number) => {
    setIsMenuOpened(true);
    AdminBannerService.getSingleZone(brandId)
      .then((response) => {
        const { data } = response;
        setState(data);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };
  const submitHandler = () => {
    if (state.id) {
      AdminBannerService.updateZone(state)
        .then((response) => {
          SuccessToast(t(`toasts.successfully_updated`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    } else {
      AdminBannerService.createZone(state)
        .then((response) => {
          SuccessToast(t(`toasts.successfully_created`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    AdminBannerService.getAllZone(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);
  return (
    <>
      {!isMenuOpened && (
        <div className="col-12">
          <div className="card">
            <div className="card-body d-flex align-items-center justify-content-between">
              <h4 className="card-title mg-b-0">
                {" "}
                {t("banner.create_new_zone")}
              </h4>
              <button
                className="btn btn-primary"
                onClick={() => setIsMenuOpened(true)}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        </div>
      )}

      {isMenuOpened && (
        <div className="col-12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <BannerZonaCreate
              setIsMenuOpened={setIsMenuOpened}
              state={state}
              setState={setState}
            />
          </Form>
        </div>
      )}
      <div className="col-12">
        <BannerZonaList
          page={page}
          handlePages={handlePages}
          totalPages={totalPages}
          list={list}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
        />
      </div>
    </>
  );
};
