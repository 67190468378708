import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { CreateForm } from "./Form";
import { EducationContinuingService } from "../../service";
export const CreateFormContinuing = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>({
    title: "",
    isActive: true,
    fileData: [
      {
        id: null,
        name: "",
        path: "",
        legend: "",
        credit: "",
        index: null,
      },
    ],
  });
  const [image, setImage] = useState<File[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const submitHandler = (event: any) => {
    const newFileDataArr = state?.fileData
      ? state?.fileData?.filter((fileData: any) => fileData?.path !== "")
      : [];
    setState({ ...state, fileData: newFileDataArr });
    const formData = new FormData();
    formData.append("mainImage[]", image as any);
    // for (let i = 0; i < image.length; i++) {
    //   formData.append("mainImage[]", image[i]);
    // }

    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("additionalFile[]", files[i]);
      }
    }
    formData.append(
      "body",
      JSON.stringify({ title: state?.title, isActive: state?.isActive })
    );
    EducationContinuingService.create(formData)
      .then((response) => {
        SuccessToast(t(`education.successfully_created`));
        navigate("/admin/education-continuing");
        setState({});
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e);
        }}
      >
        <CreateForm
          setFiles={setFiles}
          files={files}
          state={state}
          setImage={setImage}
          setState={setState}
        />
      </Form>
    </div>
  );
};
