import { ALTERNATIVE_DRIVES_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminAlternativeDrivesService = {
  getAll: (params: any) => requests.get(`${ALTERNATIVE_DRIVES_URL}`, params),
  create: (params: any) =>
    requests.post(`${ALTERNATIVE_DRIVES_URL}`, params, true),
  getSingle: (id: number) => requests.get(`${ALTERNATIVE_DRIVES_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.put(`${ALTERNATIVE_DRIVES_URL}/${id}`, params),
  delete: (id: number) => requests.delete(`${ALTERNATIVE_DRIVES_URL}/${id}`),
  updatePosition: (params: any) =>
    requests.patch(`${ALTERNATIVE_DRIVES_URL}`, params),
};
