import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EVENT_SUMMARY_URL } from "../../../helpers/api.routes";
import { multipartFormData } from "../../../helpers/api.services";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { CreateForm } from "./CreateForm";
import { AdminEventSummaryCatService } from "../../eventSummaryCategory/service";
import { IEventSummary } from "../interface";

export const CreateEventSummary = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [categories, setCategories] = useState();
  const [state, setState] = useState<IEventSummary>({
    website: "",
    title: "",
    subtitle: "",
    description: "",
    imageLegend: "",
    url: "",
    category: {
      name: "",
      slug: "",
      description: "",
      imageLegend: "",
      date: "",
      isActive: true,
    },
    isActive: true,
  });
  const [image, setImage] = useState<File>();

  const submitHandler = () => {
    multipartFormData(
      image,
      `${EVENT_SUMMARY_URL}`,
      JSON.stringify({
        ...state,
      }),
      "post",
    )
      .then((response) => {
        SuccessToast(t(`admin_event_summary.successfully_created`));

        setTriggerUpdate(!triggerUpdate);

        navigate("/admin/event-summary");
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    AdminEventSummaryCatService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm
          setImage={setImage}
          categories={categories!}
          state={state}
          setState={setState}
        />
      </Form>
    </div>
  );
};
