import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DOWNLOAD_URL } from "../../../helpers/api.routes";
import { DocImgFormData } from "../../../helpers/api.services";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AdminCategoryDownloadService } from "../../downloadCategory/service";
import { IDownload } from "../interface";
import { CreateForm } from "./CreateForm";

export const UpdateDownload = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation();
  const [state, setState] = useState<IDownload>({
    website: "",
    name: "",
    category: null,
    description: "",
    isProMember: false,
  });

  const [image, setImage] = useState<File>();
  const [document, setDocument] = useState<File>();
  const submitHandler = (event: React.FormEvent, id?: number) => {
    event.preventDefault();
    DocImgFormData(
      document,
      image,
      `${DOWNLOAD_URL}/${state?.id}`,
      JSON.stringify({
        ...state,
        category: state?.category,
      }),
      "post",
    )
      .then((response) => {
        SuccessToast(t(`download.successfully_updated`));
        navigate("/admin/download");
        setState({
          website: "",
          name: "",
          category: null,
          description: "",
          isProMember: false,
        });
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    AdminCategoryDownloadService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e, state?.id);
        }}
      >
        <CreateForm
          setImage={setImage}
          setDocument={setDocument}
          categories={categories}
          state={state}
          setState={setState}
        />
      </Form>
    </div>
  );
};
