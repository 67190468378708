import { CreateForm } from "./CreateForm";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { AdminCategoryDownloadService } from "../../downloadCategory/service";
import { useNavigate } from "react-router-dom";
import { IFleet } from "../interface";
import { AdminFleetService } from "../service";
export const CreateFleet = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [state, setState] = useState<IFleet>({
    name: "",
    text: "",
    category: { name: "" },
  });

  const submitHandler = () => {
    const formData = new FormData();
    formData.append("body", JSON.stringify(state));

    AdminFleetService.create(formData)
      .then((response) => {
        SuccessToast(t(`settings.successfully_saved`));
        navigate("/admin/fleet");
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm categories={categories} state={state} setState={setState} />
      </Form>
    </div>
  );
};
