import React, {useCallback, useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {AdminGalleryService} from "../service";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import SortableList, {SortableItem} from "react-easy-sort";
import {arrayMoveImmutable} from "array-move";
import ImageViewer from "react-simple-image-viewer";
import {ConfirmDelete} from "../../../shared/components/ConfirmDelete";
import {EditModal} from "./EditModal";

export interface IUpdateGalleryImage {
    id?: number;
    description?: string;
}

export const Gallery = () => {
    const {id} = useParams();
    const {t} = useTranslation();
    const [state, setState] = useState<any[]>([]);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [selectedPrompt, setSelectedPrompt] = useState<any>();
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState<IUpdateGalleryImage>({});

    const openImageViewer = useCallback((index: any) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        updatePosition(arrayMoveImmutable(state as any, oldIndex, newIndex));
        setState((array: any) => arrayMoveImmutable(array, oldIndex, newIndex));
    };

    const updatePosition = (newIds: number[]) => {
        const newArray = newIds?.map((item: any) => item?.id);
        AdminGalleryService.updatePosition({ids: newArray});
    };

    const deleteHandler = (selectedPrompt: any) => {
        selectedPrompt?.id &&
        AdminGalleryService.delete(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    setState((prev: any) =>
                        prev.filter((item: any) => item.id !== selectedPrompt.id)
                    );
                    SuccessToast(t(`gallery.successfully_deleted`));
                }
            })
            .catch((error) => ErrorToast(error));
    };

    const openEditModal = async (imageId: number) => {
        if (imageId) {
            await AdminGalleryService.getImageDesc(imageId)
                .then((response) => {
                    if (response.data) {
                        setShowEditModal(true);
                        setSelectedImage(response?.data)
                    }
                }).catch(err => ErrorToast(err))
        }
    };

    const handleSaveDescription = async () => {
        if (selectedImage?.id) {
            await AdminGalleryService.updateImageDesc(selectedImage)
                .then(() => {
                    SuccessToast(t("gallery.successfully_updated"));
                    setShowEditModal(false);
                    setSelectedImage({})
                })
                .catch((error) => ErrorToast(error));
        }
    };

    const onEditChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = event.target;
        setSelectedImage(prev => ({...prev, [name]: value}))
    }

    useEffect(() => {
        if (!!Number(id)) {
            AdminGalleryService.getSingle(id as unknown as number)
                .then((response) => {
                    const {data} = response;
                    const mapped = data?.data?.map((doc: any) => {
                        return {
                            id: doc.image?.id,
                            path: doc.image.path,
                        };
                    });

                    setState(mapped);
                })
                .catch((error) => ErrorToast(error));
        }
    }, [id]);
    return (
        <Card>
            <div>
                <SortableList
                    onSortEnd={onSortEnd}
                    className="list"
                    draggedItemClassName="dragged"
                >
                    {state?.map((item) => (
                        <div className="d-inline-block position-relative" key={item?.id}>
                            <SortableItem>
                                <img
                                    src={item?.path}
                                    className="object-fit-cover cursor-move"
                                    onDoubleClick={() => openImageViewer(item?.id)}
                                    width={250}
                                    height={250}
                                    style={{margin: "2px"}}
                                    alt=""
                                />
                            </SortableItem>
                            <div className="icons-position">
                                <i onClick={() => openEditModal(item?.id)} className="ri-edit-fill bg-primary"></i>
                                <i onClick={() => {
                                    handleShow();
                                    setSelectedPrompt(item);
                                }}
                                   className="ri-delete-bin-fill bg-danger"
                                ></i>
                            </div>
                        </div>
                    ))}
                </SortableList>
                {isViewerOpen && (
                    <ImageViewer
                        src={state!.map((item) => item.path)}
                        backgroundStyle={{zIndex: 10000}}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                    />
                )}
            </div>
            <ConfirmDelete
                show={show}
                setShow={setShow}
                itemName={selectedPrompt?.title}
                deleteHandler={deleteHandler}
                product={selectedPrompt}
                selectedProduct={selectedPrompt}
            />
            <EditModal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                selectedItem={selectedImage}
                onChange={onEditChange}
                onSave={handleSaveDescription}
            />
        </Card>
    );
};
