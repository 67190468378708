import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {CreateForm} from "./CreateForm";
import {AdminBrandService} from "../../brand/service";
import {AdminModelService} from "../service";
import {AdminModelCategoryService} from "../../modelCategory/service";

export const CreateModel = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [brands, setBrands] = useState([]);
  const [modelCats, setModelCats] = useState([]);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>({
    title: "",
    description: "",
    specification: "",
    text: "",
    brand: 0,
    modelCategory: 0,
    isActive: true,
  });
  const [image, setImage] = useState<File>();
  const [logo, setLogo] = useState<File>();

  const submitHandler = () => {
    const formData = new FormData();
    formData.append("body", JSON.stringify(state));
    image && formData.append("image", image);
    AdminModelService.create(formData)
      .then((response) => {
        SuccessToast(t(`model.successfully_created`));
        navigate("/admin/model");
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    AdminBrandService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setBrands(data?.items ?? []);
      })
      .catch((error) => ErrorToast(error));
    AdminModelCategoryService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
          setModelCats(data?.items ?? []);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
      <div className="col-12">
          <Form
              onSubmit={(e) => {
                  e.preventDefault();
                  submitHandler();
              }}
          >
              <CreateForm
                  brands={brands}
                  modelCats={modelCats}
                  state={state}
                  setState={setState}
                  image={image}
                  setImage={setImage}
              />
          </Form>
      </div>
  );
};
