import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { AdminMenuService } from "./service";

import { CreateForm } from "./components/CreateForm";
import { useTranslation } from "react-i18next";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { MenuPositionService } from "../menuPosition/service";
export const AdminMenu = () => {
  const { t } = useTranslation();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const navigate = useNavigate();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>({
    isActive: true,
    parent: null,
    name: "",
  });
  const [positions, setPositions] = useState<any>([{}]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [show, setShow] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [list, setList] = useState<any>();
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const mappedData = positions?.map((position: any) => ({
    label: position.location,
    value: position.id,
  }));
  const handleShow = () => setShow(true);

  const deleteHandler = (selectedPrompt: any) => {
    selectedPrompt?.id &&
      AdminMenuService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            setList((prev: any) =>
              prev.filter((item: any) => item.id !== selectedPrompt.id)
            );
            // setTriggerUpdate(!triggerUpdate);
            SuccessToast(t(`menu.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };

  const titleClickHandler = (id: string | number) => {
    navigate(`/admin/menu/update/${id}`);
  };

  const editHandler = (id: number): void => {
    navigate(`/admin/menu/update/${id}`);
  };
  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminMenuService.updatePosition({ ids: newListUpdated });
  };

  const createNew = (): void => {
    navigate("/admin/menu/create");
  };

  useEffect(() => {
    AdminMenuService.getAll({ ...pagination, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setList(
          data?.items.map((item: any) => ({
            ...item,
            positionMenu: item?.positionMenu?.location,
          }))
        );
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);
  useEffect(() => {
    MenuPositionService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;

        setPositions(data.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body d-flex align-items-center ">
            <div className="d-flex align-items-center gap-3">
              <div className="search-box">
                <input
                  type="text"
                  className="form-control"
                  id="searchMemberList"
                  onChange={(e: any) =>
                    setPagination((prev: any) => ({
                      ...prev,
                      term: e.target.value,
                    }))
                  }
                  placeholder={t("global.search")}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
              <div>
                <Select
                  className="react-select-field"
                  onChange={(e: any) => {
                    setPagination((prev: any) => ({
                      ...prev,
                      positionMenu: e.value,
                    }));
                  }}
                  options={mappedData}
                  isSearchable={false}
                  placeholder={t("global.position")}
                  required
                />
              </div>
            </div>
            <button className="btn btn-primary ms-auto" onClick={createNew}>
              {t("global.add")}
            </button>
          </div>
        </div>
      </div>

      <div className="col-12">
        {!!list?.length && (
          <div>
            <DraggableBootstrapTable
              preview={true}
              haveMenuActions={true}
              list={list}
              setList={setList}
              headers={["id", "name", "positionMenu", "isActive"]}
              title="menu"
              onEdit={editHandler}
              selectedPrompt={selectedPrompt}
              setSelectedPrompt={setSelectedPrompt}
              handleShow={handleShow}
              show={show}
              setShow={setShow}
              deleteHandler={deleteHandler}
              updatePosition={updatePosition}
              titleClickHandler={titleClickHandler}
            />
          </div>
        )}
      </div>
    </>
  );
};
