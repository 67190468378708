import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from "./languages/en.json";
import de from "./languages/de.json";
import fr from "./languages/fr.json";
import it from "./languages/it.json";

const resources = {
    en,
    de,
    fr,
    it
};

export const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next).init({
    resources,
    defaultNS: 'app',
    fallbackLng: 'en',
});

export default i18n;

