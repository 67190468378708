import { ADMIN_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminAdminService = {
  getAll: (params: any) => requests.get(`${ADMIN_URL}`, params),
  create: (params: any) => requests.post(`${ADMIN_URL}`, params),
  changePassword: (id: any, params: any) =>
    requests.post(`${ADMIN_URL}/password-change/${id}/`, params),
  getSingle: (id: number) => requests.get(`${ADMIN_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.put(`${ADMIN_URL}/${id}`, params),
  delete: (id: number) => requests.delete(`${ADMIN_URL}/${id}`),
};
