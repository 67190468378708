import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IForm } from "../interface";

export const CreateForm = ({ setIsMenuOpened, state, setState }: IForm) => {
  const { t } = useTranslation();
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4}>
            <label htmlFor="name" className="required-field">
              {t("global.name")}
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.name}
            />
          </Col>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                setIsMenuOpened(false);
                setState({} as any);
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
