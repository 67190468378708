import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { AdminMagazineService } from "./service";
import { List } from "./components/List";
import { CreateForm } from "./components/CreateForm";
import { useTranslation } from "react-i18next";
import { multipartFormData } from "../../helpers/api.services";
import { MAGAZINE_URL } from "../../helpers/api.routes";
import { AdminMagazineCategoryService } from "../magazineCategory/service";
import { useNavigate } from "react-router-dom";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import { Pagination } from "../../shared/paginations/Paginations";
import Select, { SingleValue } from "react-select";
import {perPageOptions} from "../../shared/paginations/perPageOptions";

export const AdminMagazine = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const imageRef = useRef<any>(null);
  const [image, setImage] = useState<File>();
  const [categoryList, setCategoryList] = useState([]);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [categories, setCategories] = useState();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>({ isActive: true });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();

  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 25,
    category: null,
  });
  const [list, setList] = useState(undefined);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const editHandler = (id: number) => {
    navigate(`/admin/magazine/update/${id}`);
  };
  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminMagazineService.updatePosition({ ids: newListUpdated });
  };
  const handlePerPage = (e: any) => {
    setPagination((prev) => ({ ...prev, page: 1, perPage: e.value }));
  };
  const deleteHandler = () => {
    selectedPrompt?.id &&
      AdminMagazineService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`magazine.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const mappedData = categoryList?.map((position: any) => ({
    label: position.name,
    value: position.id,
  }));
  const submitHandler = () => {
    if (state?.id) {
      multipartFormData(
        image ?? state?.image,
        `${MAGAZINE_URL}/${state?.id}`,
        JSON.stringify({
          ...state,
          category: state?.category?.id,
        }),
        "post"
      )
        .then((response) => {
          SuccessToast(t(`magazine.successfully_updated`));
          setIsMenuOpened(false);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    } else {
      multipartFormData(
        image,
        `${MAGAZINE_URL}`,
        JSON.stringify({
          ...state,
        }),
        "post"
      )
        .then((response) => {
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setState({});
          SuccessToast(t(`magazine.successfully_created`));
        })
        .catch((error) => ErrorToast(error));
    }
  };
  const handleSelectCategory = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    if (selectedSingle?.value as number) {
      setPagination((prev: any) => ({
        ...prev,
        category: selectedSingle?.value,
        page: 1,
      }));
    } else {
      setPagination((prev: any) => ({
        ...prev,
        category: null,
        page: 1,
      }));
    }
  };
  useEffect(() => {
    AdminMagazineService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setPage(data?.page);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);
  useEffect(() => {
    AdminMagazineCategoryService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    AdminMagazineCategoryService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;

        setCategoryList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <>
      {!isMenuOpened && (
        <div className="col-12">
          <div className="card">
            <div className="card-body d-flex align-items-center justify-content-between">
              <div className="d-flex gap-3">
                <div className="d-flex align-items-center me-3">
                  <h6 className="my-auto me-2 p-0 ">
                    {t("table_headers.category")}:
                  </h6>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        minWidth: "190px",
                      }),
                    }}
                    className="react-select-field"
                    isClearable
                    onChange={(e) => {
                      handleSelectCategory(
                        e as SingleValue<{ label: string; value: number }>
                      );
                    }}
                    options={mappedData}
                    isSearchable={false}
                    placeholder={t("global.choose_category")}
                    required
                  />
                </div>
                <div className="d-flex align-items-center">
                  <h6 className="my-auto me-2 p-0 ">
                    {t("table_headers.per_page")}:
                  </h6>
                  <Select
                    className="react-select-field"
                    onChange={handlePerPage}
                    placeholder="Per page"
                    options={perPageOptions}
                    defaultValue={perPageOptions[0]}
                  />
                </div>
              </div>
              <button
                className="btn btn-primary"
                onClick={() => navigate("/admin/magazine/create")}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        </div>
      )}

      {isMenuOpened && (
        <div className="col-12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <CreateForm
              setImage={setImage}
              image={image}
              setIsMenuOpened={setIsMenuOpened}
              state={state}
              setState={setState}
              imageRef={imageRef}
              categories={categories}
            />
          </Form>
        </div>
      )}
      {page > 1 ? (
        <>
          <div className="col-12">
            <List
              page={page}
              handlePages={handlePages}
              totalPages={totalPages}
              list={list}
              setTriggerUpdate={setTriggerUpdate}
              editHandler={editHandler}
            />
          </div>{" "}
        </>
      ) : (
        <>
          <div className="col-12">
            <DraggableBootstrapTable
              preview={true}
              list={list!}
              setList={setList}
              headers={["id", "title", "subtitle", "category", "isActive"]}
              title="magazine"
              onEdit={editHandler}
              selectedPrompt={selectedPrompt}
              setSelectedPrompt={setSelectedPrompt}
              handleShow={handleShow}
              show={show}
              setShow={setShow}
              deleteHandler={deleteHandler}
              updatePosition={updatePosition}
            />
          </div>
        </>
      )}
      {page && (
        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      )}
    </>
  );
};
