import {requests} from "../../../../helpers/api.services";
import {FIRM_GALLERY_URL} from "../../../../helpers/api.routes";

export const FirmGalleryService = {
    getAll: (params: any) => requests.get(`${FIRM_GALLERY_URL}`, params),
    create: (params: any) => requests.post(`${FIRM_GALLERY_URL}`, params, true),
    getSingle: (id: number) => requests.get(`${FIRM_GALLERY_URL}/${id}`),
    update: (params: any, id: number) =>
        requests.put(`${FIRM_GALLERY_URL}/${id}`, params),
    delete: (id: number) => requests.delete(`${FIRM_GALLERY_URL}/${id}`),
    updatePosition: (params: any) =>
        requests.patch(`${FIRM_GALLERY_URL}`, params),
};