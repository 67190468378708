import GreenPagesTabs from "./GreenPagesTabs";

export const CreateGreenPages = () => {

    return (
        <div className="col-12">
            <GreenPagesTabs/>
        </div>
    );
};
