import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import {Form} from "react-bootstrap";

import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";

import {useTranslation} from "react-i18next";
import {CreateForm} from "./CreateForm";
import {AdminTestsService} from "../service";
// import { AdminNewsThemeService } from "../service";

export const CreateTests = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [image, setImage] = useState<File>();
  const [state, setState] = useState<any>({
    title: "",
    intro: "",
    content: "",
    date: "",
    hideDate: false,
    isVideoHeadLine: false,
    isComment: false,
    isPaidContent: false,
    isTransporterGuide: false,
    isPremium: false,
    isArval: false,
    videoEmbed: null,
    script: "",
    isActive: true,
    redirectToUrl: "",
    subtitle: "",
    author: null,
    brand: null,
    model: null,
    seoTitle: "",
    seoTags: "",
    seoDescription: "",
    profilePicture: null,
    link1: "",
    link2: "",
    link3: "",
    fileData: [
      {
        id: null,
        name: "",
        path: "",
        legend: "",
        credit: "",
        index: null,
      },
    ],
    additionalFiles: [],
  });

  const [files, setFiles] = useState<any[]>([]);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false);
  const [additionalFiles, setAdditionalFiles] = useState<any[]>();

  const submitHandler = (event: any) => {
    const newFileDataArr = state?.fileData
      ? state?.fileData?.filter((fileData: any) => fileData?.path !== "")
      : [];
    setState({ ...state, fileData: newFileDataArr });
    setDisabledSubmit(true);
    const formData = new FormData();
    image && formData.append("mainImage", image);
    for (let i = 0; i < files.length; i++) {
      formData.append("file[]", files[i]);
    }
    formData.append(
      "body",
      JSON.stringify({ ...state, fileData: newFileDataArr })
    );
    AdminTestsService.create(formData)
      .then((response) => {
        SuccessToast(t(`test.successfully_created`));
        navigate("/admin/tests");
        setState({});
      })
      .catch((error) => ErrorToast(error))
      .finally(() => setDisabledSubmit(false));
  };

  return (
    <div className="col-12" id="scrollable-form">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e);
        }}
      >
        <CreateForm
          setImage={setImage}
          disabledSubmit={disabledSubmit}
          submitHandler={submitHandler}
          state={state}
          setState={setState}
          files={files}
          setFiles={setFiles}
          additionalFiles={additionalFiles}
          setAdditionalFiles={setAdditionalFiles}
        />
      </Form>
    </div>
  );
};
