import Lottie from "lottie-react";
import React from "react";
import searchIcon from "../icons/searchIcon.json";

export const Loader = () => {
  return (
    <div id="preloader">
      <div className="d-flex align-items-center justify-content-center flex-column w-100">
        <Lottie animationData={searchIcon} loop={true} style={{ width: 100 }} />
      </div>
    </div>
  );
};
