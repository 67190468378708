import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { CreateForm } from "./CreateForm";
import "../../../assets/scss/scroll.scss";
import { multipartFormData } from "../../../helpers/api.services";
import { VIDEO_URL } from "../../../helpers/api.routes";
export const UpdateVideo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false);
  const [additionalFiles, setAdditionalFiles] = useState<any[]>();
  const [state, setState] = useState<any>({
    title: "",
    date: "",
    isActive: true,
    url: "",
    mainImg: {},
    embeddedCode: "",
  });
  const [image, setImage] = useState<File>();
  const submitHandler = (event: any) => {
    multipartFormData(
      image,
      `${VIDEO_URL}/${id}`,
      JSON.stringify({ ...state, isActive: state?.isActive ?? false }),
      "post"
    )
      .then((response) => {
        SuccessToast(t(`event.successfully_created`));
        navigate("/admin/video-gallery");
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <div className="col-12" id="scrollable-form">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e);
        }}
      >
        <CreateForm state={state} setState={setState} setImage={setImage} />
      </Form>
    </div>
  );
};
