import { ALBUM_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminAlbumService = {
  getAll: (params: any) => requests.get(`${ALBUM_URL}`, params),
  create: (params: any) => requests.post(`${ALBUM_URL}`, params),
  getSingle: (id: number) => requests.get(`${ALBUM_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.put(`${ALBUM_URL}/${id}`, params),
  delete: (id: number) => requests.delete(`${ALBUM_URL}/${id}`),
  updatePosition: (params: any) => requests.patch(`${ALBUM_URL}`, params),
};
