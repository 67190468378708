import { BRAND_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminBrandService = {
  getAll: (params: any) => requests.get(`${BRAND_URL}`, params),
  create: (params: any) => requests.post(`${BRAND_URL}`, params, true),
  getSingle: (id: number) => requests.get(`${BRAND_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.post(`${BRAND_URL}/${id}`, params, true),
  delete: (id: number) => requests.delete(`${BRAND_URL}/${id}`),
};
