import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { AdminCategoryService } from "../../category/service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export const CreateForm = ({ state, setState, submitHandler }: any) => {
  const { t } = useTranslation();
  const { siteSettings } = useSelector((state: RootState) => state.enum);
  const [categoryList, setCategoryList] = useState<any[]>();
  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const selectHandler = (e: any, index: number, name: string) => {
    let onChangeValue = [...state];
    (onChangeValue as any)[index][name] = e.value;
    setState(onChangeValue);
  };
  const changeHandler = (event: any, index: number) => {
    const { name, value } = event.target;
    let onChangeValue = [...state];
    (onChangeValue as any)[index][name] = value;
    setState(onChangeValue);
  };
  useEffect(() => {
    AdminCategoryService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;

        setCategoryList(
          data?.items.map((item: any) => ({ label: item.name, value: item.id }))
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <Card>
      <Card.Body>
        {state &&
          state?.map((item: any, index: number) => {
            return (
              <div className="row d-flex align-items-end my-3" key={index + 1}>
                <Col md={3}>
                  <label htmlFor="title" className="required-field">
                    {t("global.title")}
                  </label>
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    required
                    onChange={(e) => changeHandler(e as any, index)}
                    value={(item as any)?.title}
                  />
                </Col>
                <Col md={2} className="">
                  <label htmlFor="category" className="required-field">
                    {t("table_headers.position")}
                  </label>
                  <Select
                    isDisabled={true}
                    key={item?.key ?? index}
                    name="key"
                    defaultValue={
                      item?.key && {
                        value: item?.key,
                        label: capitalizeFirstLetter(item?.key),
                      }
                    }
                    className="react-select-field"
                    onChange={(e) => selectHandler(e as any, index, "key")}
                    options={(siteSettings as any)?.map((site: string) => ({
                      value: site,
                      label: capitalizeFirstLetter(site),
                    }))}
                  />
                </Col>
                <Col md={3} className="">
                  <label htmlFor="category" className="required-field">
                    {t("table_headers.category")}
                  </label>
                  <Select
                    className="react-select-field"
                    onChange={(e) => selectHandler(e as any, index, "category")}
                    options={categoryList}
                    value={categoryList?.find(
                      (category) =>
                        category?.value === (item as any)?.category?.id
                    )}
                  />
                </Col>
                <Col md={3}>
                  <label htmlFor="link" className="required-field">
                    {t("global.link")}
                  </label>
                  <input
                    type="text"
                    name="url"
                    className="form-control"
                    onChange={(e) => changeHandler(e as any, index)}
                    value={(item as any)?.url}
                  />
                </Col>
                <Col md={1}>
                  <button
                    className="btn btn-primary"
                    onClick={() => submitHandler(index)}
                  >
                    {t("global.save")}
                  </button>
                </Col>
              </div>
            );
          })}
      </Card.Body>
    </Card>
  );
};
