import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const CreateForm = ({ state, setState }: any) => {
  const { t } = useTranslation();

  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    // setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const switchHandler = (e: any) => {
    setState((prev: any) => {
      return prev?.map((s: any) =>
        s.module === e.target.name ? { ...s, isActive: e.target.checked } : s
      );
    });
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          {state?.map((s: { module: string; isActive: boolean }) => {
            return (
              <Col md={4} className="mb-3">
                <div className="form-check form-switch p-0">
                  <label
                    className="d-block text-start"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    {t(`settings.${s.module}`)}
                  </label>
                  <div className="row">
                    <input
                      className="form-check-input switch-custom ms-4 mt-2"
                      type="checkbox"
                      name={s.module}
                      checked={s.isActive}
                      onChange={switchHandler}
                      role="switch"
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button className="btn btn-primary ms-3" type="submit">
              {t("global.save")}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
