import { NEWS_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminFeaturedArticlesService = {
  getAll: () => requests.get(`${NEWS_URL}/featured`),
  getAllAuthors: (params: any) => requests.get(`${NEWS_URL}/authors`, params),
  updatePosition: (params: any) =>
    requests.patch(`${NEWS_URL}/featured`, params),
  create: (params: any) => requests.post(`${NEWS_URL}`, params, true),
  getSingle: (id: number) => requests.get(`${NEWS_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.post(`${NEWS_URL}/${id}`, params, true),
  delete: (id: number) => requests.delete(`${NEWS_URL}/${id}`),
};
