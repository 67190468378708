import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EVENT_URL } from "../../../helpers/api.routes";
import { multipartFormData } from "../../../helpers/api.services";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AdminEventCategoryService } from "../../eventCategory/service";
import { IEvent } from "../interface";
import { CreateForm } from "./CreateForm";
export const CreateEvent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [categories, setCategories] = useState();
  const [state, setState] = useState<IEvent>({
    website: "",
    title: "",
    location: "",
    category: { website: "", name: "", imageName: "" },
    date: "",
    url: "",
    isActive: false,
    image: undefined,
  });
  const [image, setImage] = useState<File>();
  const submitHandler = () => {
    const date = new Date(state?.date);
    const correctDate = date?.toISOString();
    if (state?.id) {
      multipartFormData(
        image,
        `${EVENT_URL}/${state?.id}`,
        JSON.stringify({
          ...state,
          date: correctDate,
          category: state?.category,
        }),
        "post",
      )
        .then((response) => {
          SuccessToast(t(`event.successfully_updated`));

          setTriggerUpdate(!triggerUpdate);
        })
        .catch((error) => ErrorToast(error));
    } else {
      multipartFormData(
        image,
        `${EVENT_URL}`,
        JSON.stringify({
          ...state,
          date: correctDate,
          category: state?.category,
        }),
        "post",
      )
        .then((response) => {
          SuccessToast(t(`event.successfully_created`));

          setTriggerUpdate(!triggerUpdate);

          navigate("/admin/event");
        })
        .catch((error) => ErrorToast(error));
    }
  };

  useEffect(() => {
    AdminEventCategoryService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm
          setImage={setImage}
          categories={categories!}
          state={state}
          setState={setState}
        />
      </Form>
    </div>
  );
};
