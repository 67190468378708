import {SUBSCRIPTION_URL} from "../../helpers/api.routes";
import {requests} from "../../helpers/api.services";

export const AdminSubscriptionService = {
  getAll: (params: any) => requests.get(`${SUBSCRIPTION_URL}`, params),
  create: (params: any) => requests.post(`${SUBSCRIPTION_URL}`, params),
  getSingle: (id: number) => requests.get(`${SUBSCRIPTION_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.put(`${SUBSCRIPTION_URL}/${id}`, params),
  delete: (id: number) => requests.delete(`${SUBSCRIPTION_URL}/${id}`),
  updatePosition: (params: any) => requests.patch(`${SUBSCRIPTION_URL}`, params),
};
