import {useEffect, useState} from "react";
import {AdminNewsLetterService} from "../../service";
import {ErrorToast} from "../../../../shared/toasters/toasters";
import {useDispatch} from "react-redux";
import {numberNewsletterSlice} from "../../../../store/slices/numberNewsletter.slice";
import InnerHTML from "dangerously-set-html-content";

const AdminNewsletterStep3 = ({
  state,
  step,
  count,
}: any) => {
  const dispatch = useDispatch();
  const [preview, setPreview] = useState<{ html: string }>({ html: "" });

  useEffect(() => {
    if (step !== 3) return;
    if (state?.breakingNews && state?.test) {
      dispatch(
        numberNewsletterSlice.actions.setNumberNewsletter(
          count?.subscribersTest + count?.subscribers
        )
      );
    } else if (state?.breakingNews) {
      dispatch(
        numberNewsletterSlice.actions.setNumberNewsletter(count?.subscribers)
      );
    } else if (state?.test) {
      dispatch(
        numberNewsletterSlice.actions.setNumberNewsletter(
          count?.subscribersTest
        )
      );
    } else {
      dispatch(
        numberNewsletterSlice.actions.setNumberNewsletter(count?.subscribers)
      );
    }
    AdminNewsLetterService.preview({
      ...state,
      misterText: state?.textMan,
      womanText: state?.textWoman,
      othersText: state?.textOther,
    })
      .then((response) => {
        setPreview(response.data);
      })
      .catch((error) => ErrorToast(error));
  }, [step]);

  return (
    <>
      {preview?.html && (
        <div className="p-3">
          <InnerHTML html={preview?.html} />
        </div>
      )}
    </>
  );
};

export default AdminNewsletterStep3;
