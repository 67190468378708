import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { AdminSettingsService } from "./service";
import { CreateForm } from "./components/CreateForm";
import { useTranslation } from "react-i18next";

export const AdminSettings = () => {
  const { t } = useTranslation();

  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [list, setList] = useState();

  const submitHandler = () => {
    AdminSettingsService.create({ modules: list })
      .then((response) => {
        SuccessToast(t(`settings.successfully_saved`));

        setTriggerUpdate(!triggerUpdate);
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    AdminSettingsService.getAll()
      .then((response) => {
        const { data } = response;

        setList(data?.modules);
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate]);

  return (
    <>
      <div className="col-12">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            submitHandler();
          }}
        >
          <CreateForm state={list} setState={setList} />
        </Form>
      </div>
    </>
  );
};
