import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorToast } from "../../../../shared/toasters/toasters";
import { AdminTestEmailService } from "../service";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
export const CreateForm = ({ state, setState }: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const { genders } = useSelector((state: RootState) => state.enum);
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const mappedData = genders?.map((item: string) => ({
    label: `${t(`genders.${item}`)}`,
    value: item,
  }));
  const selectHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, greeting: e.value }));
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminTestEmailService.getSingle(+id!)
        .then((response) => {
          const { data } = response;
          setState(data);
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);

  return (
    <>
      {" "}
      <Card>
        <Card.Body>
          <Row>
            <Col md={3} className="mb-3">
              <label htmlFor="firstName" className="required-field">
                {t("global.firstName")}
              </label>
              <input
                type="text"
                name="firstName"
                className="form-control"
                onChange={changeHandler}
                value={state?.id && state?.firstName}
              />
            </Col>
            <Col md={3} className="mb-3">
              <label htmlFor="lastName" className="required-field">
                {t("global.lastName")}
              </label>
              <input
                type="text"
                name="lastName"
                className="form-control"
                onChange={changeHandler}
                value={state?.id && state?.lastName}
              />
            </Col>
            <Col md={3} className="mb-3">
              <label htmlFor="email" className="required-field">
                {t("global.email")}
              </label>
              <input
                type="text"
                name="email"
                className="form-control"
                required
                onChange={changeHandler}
                value={state?.id && state?.email}
              />
            </Col>
            <Col md={3} className="mb-3">
              <label htmlFor="greeting">{t("table_headers.greeting")}</label>
              <Select
                className="react-select-field"
                value={mappedData?.find(
                  (item: any) => item?.value === state?.greeting
                )}
                onChange={selectHandler}
                options={mappedData}
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="title" className="required-field">
                {t("global.title")}
              </label>
              <input
                type="text"
                name="title"
                className="form-control"
                onChange={changeHandler}
                value={state?.id && state?.title}
              />
            </Col>

            <Col md={3} className="">
              <div className="form-check form-switch p-0">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.active")}
                </label>
                <div className="row">
                  <input
                    className="form-check-input switch-custom ms-4 mt-2"
                    type="checkbox"
                    name="active"
                    checked={state?.active}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <div className="col-12 my-2 d-flex justify-content-end text-align-center">
            <div>
              <button
                className="btn btn-info"
                onClick={() => navigate("/admin/newsletter-email-test")}
              >
                {t("global.cancel")}
              </button>
              <button className="btn btn-primary ms-3" type="submit">
                {state?.id
                  ? `  ${t("global.update")}`
                  : `${t("global.create")}`}
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
