import React, {useEffect, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useNavigate, useParams} from "react-router-dom";
import {ErrorToast} from "../../../shared/toasters/toasters";
import {ModelGalleryService} from "../service";
import {AdminModelService} from "../../model/service";

export const CreateForm = ({
  setIsMenuOpened,
  state,
  setState,
  fileHandler,
}: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const currentTheme = useSelector((state: RootState) => state.theme);
  const [models, setModels] = useState<any[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (!!Number(id)) {
      ModelGalleryService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;
          setState({ ...data, category: data?.category?.id });
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);

  useEffect(() => {
    AdminModelService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;
        setModels(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  const mappedData = models?.map((album: any) => ({
    label: album.title,
    value: album.id,
  }));

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4}>
            <label htmlFor="model" className="required-field">
              {t("global.model")}
            </label>
            <Select
              className="react-select-field"
              onChange={(e) => setState({ model: e?.value })}
              options={mappedData}
            />
          </Col>
          <Col md={12} className="my-3">
            <div className="">
              <label htmlFor="imageFile">{t("download.image")}</label>
              <input
                onChange={fileHandler}
                multiple={true}
                className="form-control"
                type="file"
                accept="image/*"
                id="imageFile"
              />
            </div>
          </Col>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                navigate("/admin/model-gallery");
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {`${t("global.add")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
