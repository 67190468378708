import { toast } from "react-toastify";

export function ErrorToast(e: any) {
  console.log(e);
  const callbackMsg = "Something went wrong";
  const errorMessages =
    !!e?.response && (e?.response?.data?.message ?? [callbackMsg]);
  const isMessageArray = errorMessages && isErrorArray(errorMessages);
  if (isMessageArray) {
    errorMessages?.forEach((errorMessage: string) => {
      !!errorMessage?.length && toast.error(errorMessage, { autoClose: 3000 });
    });
  } else {
    toast.error(errorMessages ? errorMessages : callbackMsg, {
      autoClose: 3000,
    });
  }
}
export function ErrorToastFileTranslate(props: string) {
  toast.error(props, { autoClose: 3000 });
}

export function SuccessToast(props: string) {
  toast.success(props, { autoClose: 3000 });
}

export function WarningToast(props: string) {
  toast.warning(props, { autoClose: 3000 });
}

const isErrorArray = (args: string[]) => {
  return Array.isArray(args);
};
// toast.success(props, { autoClose: 5000 je default });
