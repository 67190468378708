import {Card, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {PICTURE_URL} from "../../../../../helpers/api.routes";

export const CreateForm = ({
  setIsMenuOpened,
  state,
  setState,
  setImage
}: any) => {
  const { t } = useTranslation();
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const fileHandler = (e: any) => {
    setImage(e.target.files[0]);
  };
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4}>
            <label htmlFor="firstName" className="required-field">
              {t("global.firstName")}
            </label>
            <input
              type="text"
              name="firstName"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.firstName}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="lastName" className="required-field">
              {t("global.lastName")}
            </label>
            <input
                type="text"
                name="lastName"
                className="form-control"
                required
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.lastName}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="function" className="required-field">
              {t("global.function")}
            </label>
            <input
                type="text"
                name="function"
                className="form-control"
                required
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.function}
            />
          </Col>
          <Col md={4} className={'mt-2'}>
            <label htmlFor="phone" className="required-field">
              {t("global.phone")}
            </label>
            <input
                type="text"
                name="phone"
                className="form-control"
                required
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.phone}
            />
          </Col>
          <Col md={4} className={'mt-2'}>
            <label htmlFor="phone2">
              {t("global.phone2")}
            </label>
            <input
                type="text"
                name="phone2"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.phone2}
            />
          </Col>
          <Col md={4} className={'mt-2'}>
            <label htmlFor="email" className="required-field">
              {t("global.email")}
            </label>
            <input
                type="text"
                name="email"
                className="form-control"
                required
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.email}
            />
          </Col>
          <Col md={2} className=" mt-2">
            <label
              className="d-block text-start"
              htmlFor="flexSwitchCheckDefault"
            >
              {t("global.active")}
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input switch-custom"
                type="checkbox"
                name="active"
                checked={state?.active}
                onChange={switchHandler}
                role="switch"
                id="flexSwitchCheckDefault"
              />
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <label htmlFor="imageFile"> {t("download.image")}</label>
            <input
                onChange={fileHandler}
                className="form-control"
                type="file"
                accept="image/*"
                id="imageFile"
            />
          </Col>
          {state?.image && (
              <img
                  className="mt-3 rounded show-img-form"
                  alt="200x200"
                  src={PICTURE_URL + state?.image.imagePath}
                  data-holder-rendered="true"
              />
          )}
        </Row>
        <Col md={12} className="my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                setIsMenuOpened(false);
                setState({} as any);
                setImage(undefined);
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type={'submit'}>
              {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </Col>
      </Card.Body>
    </Card>
  );
};
