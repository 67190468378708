import { MENU_POSITION } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const MenuPositionService = {
  getAll: (params: any) => requests.get(`${MENU_POSITION}`, params),
  update: (params: any) => requests.put(`${MENU_POSITION}/${params.id}`, params),
  create: (params: any) => requests.post(`${MENU_POSITION}`, params, true),
  getSingle: (id: number) => requests.get(`${MENU_POSITION}/${id}`),
  delete: (id: number) => requests.delete(`${MENU_POSITION}/${id}`),
};
