import { VIDEO_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminVideoService = {
  getAll: (params: any) => requests.get(`${VIDEO_URL}`, params),
  create: (params: any) => requests.post(`${VIDEO_URL}`, params, true),
  getSingle: (id: number) => requests.get(`${VIDEO_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.put(`${VIDEO_URL}/${id}`, params),
  delete: (id: number) => requests.delete(`${VIDEO_URL}/${id}`),
};
