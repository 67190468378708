import { MODEL_GALLERY_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const ModelGalleryService = {
  getAll: (params: any) => requests.get(`${MODEL_GALLERY_URL}`, params),
  create: (params: any) => requests.post(`${MODEL_GALLERY_URL}`, params, true),
  getSingle: (id: number) => requests.get(`${MODEL_GALLERY_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.put(`${MODEL_GALLERY_URL}/${id}`, params),
  delete: (id: number) => requests.delete(`${MODEL_GALLERY_URL}/${id}`),
  updatePosition: (params: any) =>
    requests.patch(`${MODEL_GALLERY_URL}`, params),
};
