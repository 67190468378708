import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorToast } from "../../../../shared/toasters/toasters";
import { AdminNewsLetterSentService } from "../sevice";
import { useTranslation } from "react-i18next";
import { Loader } from "../../../../shared/components/Loader";
import { EmptyState } from "../../../../shared/components/EmptyState";

interface StaticsItems {
  id: number;
  title: string;
  clicks: number;
}
interface NewsletterInfo {
  totalCreated: number;
  totalError: number;
  totalPending: number;
  totalPreviews: number;
  totalSent: number;
  openRate: number;
}

export const StaticsNewsletterSent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [newsletterInfo, setNewsletterInfo] = useState({} as NewsletterInfo);
  const [newsStatics, setNewsStatics] = useState([]);
  const [totalClicks, setTotalClicks] = useState(0);
  const [subject, setSubject] = useState("");

  useEffect(() => {
    if (!!Number(id)) {
      AdminNewsLetterSentService.getSingle(Number(id))
        .then((response) => {
          const { data } = response;
          setSubject(data?.subject);
          setNewsletterInfo(data?.newsletterInfo);
          setNewsStatics(data?.newsData?.news);
          setTotalClicks(data?.newsData?.totalClicks);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);
  return (
    <>
      <h2 className="mb-4">{subject}</h2>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header px-3 pb-0">
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-sm btn-primary d-flex align-items-center mb-1"
                  onClick={() => navigate("/admin/newsletters/list")}
                >
                  <i className="ri-arrow-left-line me-1 " />
                  {t("global.back")}
                </button>
                <h4 className="card-title mg-b-0">
                  {t("newsletter_sent.newsletter_info")}
                </h4>
              </div>
            </div>
            <div className="card-body">
              <table className="table table-bordered">
                <thead className="table-light">
                  <tr>
                    <td>{t("settings.news")}</td>
                    <td style={{ width: "80px" }}>
                      {t("newsletter_sent.count")}
                    </td>
                    <td style={{ width: "70px" }}></td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{t("newsletter_sent.created")}</td>
                    <td>{newsletterInfo?.totalCreated}</td>
                    <td></td>
                  </tr>

                  <tr className="align-middle">
                    <td>{t("newsletter_sent.error")}</td>
                    <td>{newsletterInfo?.totalError}</td>
                    <td>
                      {" "}
                      <button
                        className="btn btn-sm btn-info p-1"
                        onClick={() =>
                          navigate(`/admin/newsletters/errors/list/${id}`)
                        }
                      >
                        {t("global.preview")}
                      </button>
                    </td>
                  </tr>

                  <tr>
                    <td>{t("newsletter_sent.pending")}</td>
                    <td>{newsletterInfo?.totalPending}</td>
                    <td></td>
                  </tr>

                  <tr>
                    <td>{t("newsletter_sent.previews")}</td>
                    <td>{newsletterInfo?.totalPreviews}</td>
                    <td></td>
                  </tr>
                  <tr className="align-center">
                    <td>{t("newsletter_sent.sent")}</td>
                    <td>{newsletterInfo?.totalSent}</td>
                    <td></td>
                  </tr>
                  <tr className="align-center">
                    <td>{t("newsletter_sent.open_rate")}</td>
                    <td>
                      {newsletterInfo?.openRate ?? null}
                      {newsletterInfo?.openRate && " %"}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header pb-0">
              <h4 className="card-title mg-b-0">
                {t("newsletter_sent.news_clicks")}
              </h4>
            </div>
            <div className="card-body">
              {newsStatics == undefined ? (
                <Loader />
              ) : newsStatics.length === 0 ? (
                <EmptyState />
              ) : (
                <>
                  <table className="table table-bordered mb-0">
                    <thead className="table-light">
                      <tr>
                        <td className="col-2">{t("global.id")}</td>

                        <td className="col-8">{t("global.title")}</td>

                        <td className="col-2">{t("global.clicks")}</td>
                      </tr>
                    </thead>
                    <tbody>
                      {newsStatics?.map((item: StaticsItems) => {
                        return (
                          <tr>
                            <td>{item?.id}</td>
                            <td>{item?.title}</td>
                            <td>{item?.clicks}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <td className="col-10">
                          <h4 className="mb-0">
                            {t("newsletter_sent.total_clicks")}:
                          </h4>
                        </td>
                        <td className="col-2">
                          <h4 className="mb-0">
                            <b>{totalClicks}</b>
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
