export const perPageOptions = [
    {
        label: 25,
        value: 25,
    },
    {
        label: 50,
        value: 50,
    },
    {
        label: 100,
        value: 100,
    },
    {
        label: 200,
        value: 200,
    },
    {
        label: 400,
        value: 400,
    },
]