import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { EVENT_URL } from "../../../helpers/api.routes";
import { multipartFormData } from "../../../helpers/api.services";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AdminEventCategoryService } from "../../eventCategory/service";
import { IEvent } from "../interface";
import { CreateForm } from "./CreateForm";
import { AdminEventSummaryService } from "../../eventSummary/service";
import { List } from "../../eventSummary/components/List";
import DraggableBootstrapTable from "../../../shared/components/draggableList/Draggable";
import { Pagination } from "../../../shared/paginations/Paginations";
import { AdminEventService } from "../service";

export const UpdateEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [categories, setCategories] = useState();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [state, setState] = useState<IEvent>({
    website: "",
    location: "",
    title: "",
    category: { website: "", name: "", imageName: "" },
    date: "",
    url: "",
    intro: "",
    isActive: false,
    image: undefined,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 20,
    event: id,
  });
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [eventList, setEventList] = useState([]);
  const [list, setList] = useState([]);
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const [image, setImage] = useState<File>();
  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminEventService.updatePositionCategory({ ids: newListUpdated });
  };
  const deleteHandler = () => {
    selectedPrompt?.id &&
      AdminEventSummaryService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`admin_event_summary.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const submitHandler = () => {
    const date = state?.date ? new Date(state?.date) : "";
    const correctDate = date && date?.toISOString();

    if (state?.id) {
      multipartFormData(
        image,
        `${EVENT_URL}/${state?.id}`,
        JSON.stringify({
          ...state,
          date: state?.date ? correctDate : "",
          category: state?.category,
        }),
        "post"
      )
        .then((response) => {
          SuccessToast(t(`event.successfully_updated`));
          navigate("/admin/event");
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    AdminEventCategoryService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const editHandler = (id: number) => {
    navigate(`/admin/event-summary/update/${id}`);
  };
  useEffect(() => {
    AdminEventSummaryService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);
  return (
    <>
      <div className="col-12">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            submitHandler();
          }}
        >
          <CreateForm
            setImage={setImage}
            categories={categories!}
            state={state}
            setState={setState}
          />
        </Form>
      </div>
      {page > 1 ? (
        <>
          <div className="col-12">
            <List
              page={page}
              handlePages={handlePages}
              totalPages={totalPages}
              list={list}
              setTriggerUpdate={setTriggerUpdate}
              editHandler={editHandler}
              // domain={domain}
            />
          </div>{" "}
        </>
      ) : (
        <>
          <div className="col-12">
            <DraggableBootstrapTable
              list={list}
              setList={setList}
              headers={["id", "title", "company", "isActive"]}
              title="event_sum_cat"
              onEdit={editHandler}
              selectedPrompt={selectedPrompt}
              setSelectedPrompt={setSelectedPrompt}
              handleShow={handleShow}
              show={show}
              setShow={setShow}
              deleteHandler={deleteHandler}
              updatePosition={updatePosition}
              preview={true}
            />
          </div>
        </>
      )}

      {page && (
        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      )}
    </>
  );
};
