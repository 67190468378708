import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { AdminGreenPagesService } from "../../service";
import { useNavigate, useParams } from "react-router-dom";
// import {N1ED} from "@edsdk/n1ed-react";
// import {Editor} from "tinymce";
import { PICTURE_URL } from "../../../../helpers/api.routes";
import { AdminFirmCategoryService } from "../../../greenPagesCategory/service";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { editorConfigurationCustom } from "../../../../shared/functions/editorConfigurationCustom";
// @ts-ignore
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { InitEditorMulti } from "../../../../shared/tinyMce/InitEditorMulti";
export const CreateForm = ({ state, setState }: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const firstLoad = useRef(true);
  const [selectEditor, setSelectEditor] = useState<boolean>(false);
  const [arrayOfproviderCategories, setArrayOfProviderCategories] = useState<
    Number[]
  >([]);
  const navigate = useNavigate();
  const [editorData, setEditorData] = useState<any>();
  const [firmCategories, setFirmCategories] = useState<any[]>([]);
  const [image, setImage] = useState<File>();
  const { firmType } = useSelector((state: RootState) => state.enum);
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const mappedDataWebsite = firmCategories?.map((site: any) => ({
    label: site?.title,
    value: site?.id,
  }));

  // const changeEditorHandler = (content: string, editor: Editor) => {
  //     setState((prev: any) => ({...prev, [editor?.id]: content}));
  //     if (!firstLoad.current) {
  //         setEditorData(editor);
  //     }
  //     firstLoad.current = false;
  // };
  const copyHandlerMulti = (selectedSingle: any) => {
    if (id) {
      setState((prev: any) => ({
        ...prev,
        categories: selectedSingle,
      }));
    } else {
      setArrayOfProviderCategories(
        selectedSingle?.map(
          (selectedSingle: { value: number }) => selectedSingle?.value
        )
      );
    }
  };
  const fileHandler = (e: any) => {
    setImage(e.target.files[0]);
  };

  const selectHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, firmType: e.value }));
  };
  const changeHandlerEditor = (value: string, eventName: string) => {
    setState((prev: any) => ({ ...prev, [eventName]: value }));
  };
  const submitHandler = () => {
    const formData = new FormData();
    formData.append(
      "body",
      JSON.stringify({
        ...state,
        firmCategories: state?.categories?.map((item: any) => item?.value),
      })
    );
    image && formData.append("image", image);
    const correctApiCall = state?.id
      ? AdminGreenPagesService.update
      : AdminGreenPagesService.create;
    correctApiCall(formData, state?.id)
      .then((response) => {
        if (!state?.id) {
          navigate(`/admin/green-pages/update/${response?.data?.id}`);
        }
        SuccessToast(
          t(`green_pages.successfully_${state?.id ? "updated" : "created"}`)
        );
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    AdminFirmCategoryService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;
        setFirmCategories(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  const mappedFirmTypeData = firmType?.map((type: string) => ({
    label: t(`enums.${type}`),
    value: type,
  }));
  const handleText = (description: string) => {
    setState((prev: any) => ({ ...prev, description }));
  };
  const handleTextPortrait = (companyPortrait: string) => {
    setState((prev: any) => ({ ...prev, companyPortrait }));
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminGreenPagesService.getSingle(+id!)
        .then((response) => {
          const { data } = response;
          InitEditorMulti(data?.description, handleText, ".description");
          InitEditorMulti(
            data?.companyPortrait,
            handleTextPortrait,
            ".companyPortrait"
          );
        })
        .catch((error) => ErrorToast(error));
    } else {
      InitEditorMulti(undefined, handleText, ".description");
      InitEditorMulti(undefined, handleTextPortrait, ".companyPortrait");
    }
  }, [id]);
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        submitHandler();
      }}
    >
      <Card>
        <Card.Body>
          <Row>
            <Col md={4}>
              <label htmlFor="title" className="required-field">
                {t("global.company_name")}
              </label>
              <input
                type="text"
                name="title"
                className="form-control"
                required
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.title}
              />
            </Col>
            <Col md={8} className="mt-1">
              <label htmlFor="firm_categories" className="required-field">
                {t("table_headers.firm_categories")}
              </label>
              <Select
                id="firm_categories"
                className="react-select-field"
                onChange={copyHandlerMulti}
                isMulti={true}
                value={state?.categories}
                options={mappedDataWebsite}
              />
            </Col>
            <Col md={4} className="mt-1">
              <label htmlFor="street" className="required-field">
                {t("global.street")}
              </label>
              <input
                type="text"
                name="street"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.street}
              />
            </Col>
            <Col md={4} className="mt-1">
              <label htmlFor="plz" className="required-field">
                {t("global.plz")}
              </label>
              <input
                type="text"
                name="plz"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.plz}
              />
            </Col>
            <Col md={4} className="mt-1">
              <label htmlFor="location" className="required-field">
                {t("global.location")}
              </label>
              <input
                type="text"
                name="location"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.location}
              />
            </Col>
            <Col md={6} className="mt-1">
              <label htmlFor="phone" className="required-field">
                {t("global.phone")}
              </label>
              <input
                type="text"
                name="phone"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.phone}
              />
            </Col>
            <Col md={6} className="mt-1">
              <label htmlFor="email" className="required-field">
                {t("global.email")}
              </label>
              <input
                type="email"
                name="email"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.email}
              />
            </Col>
            <Col md={6} className="mt-1">
              <label htmlFor="url" className="required-field">
                {t("global.url")}
              </label>
              <input
                type="text"
                name="url"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.url}
              />
            </Col>
            <Col md={6} className="mt-1">
              <label htmlFor="url2" className="required-field">
                {t("global.url2")}
              </label>
              <input
                type="text"
                name="url2"
                className="form-control"
                onChange={(e) => changeHandler(e as any)}
                value={state?.id && state?.url2}
              />
            </Col>
            <Col md={12} className="my-1">
              <div>
                <label className="d-block text-start" htmlFor="content">
                  {t("global.description")}
                </label>
                <textarea
                  className="editor description"
                  onChange={console.log}
                ></textarea>
                {/* <N1ED
                                    id={`description`}
                                    onFocus={(e) => setSelectEditor(true)}
                                    apiKey={process.env.REACT_APP_EDITOR_KEY}
                                    initialValue={
                                        editorData ? editorData : state ? state?.description : ""
                                    }
                                    onEditorChange={changeEditorHandler}
                                /> */}
                {/* <CKEditor
                  config={editorConfigurationCustom}
                  editor={Editor}
                  data={state?.description ?? ""}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    changeHandlerEditor(data, "description");
                  }}
                /> */}
              </div>
            </Col>
            <Col md={12} className="my-1">
              <div>
                <label className="d-block text-start" htmlFor="content">
                  {t("global.companyPortrait")}
                </label>
                <textarea
                  className="editor companyPortrait"
                  onChange={console.log}
                ></textarea>
                {/* <N1ED
                                    id={`companyPortrait`}
                                    onFocus={(e) => setSelectEditor(true)}
                                    apiKey={process.env.REACT_APP_EDITOR_KEY}
                                    initialValue={
                                        editorData ? editorData : state ? state?.companyPortrait : ""
                                    }
                                    onEditorChange={changeEditorHandler}
                                /> */}
                {/* <CKEditor
                  config={editorConfigurationCustom}
                  editor={Editor}
                  data={state?.companyPortrait ?? ""}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    changeHandlerEditor(data, "companyPortrait");
                  }}
                /> */}
              </div>
            </Col>
            <Col md={3}>
              <label htmlFor="firm_types" className="required-field mt-3">
                {t("table_headers.firm_types")}
              </label>
              <Select
                className="react-select-field"
                onChange={selectHandler}
                options={mappedFirmTypeData}
                required
                value={mappedFirmTypeData?.find(
                  (data: any) => data.value === state?.firmType
                )}
              />
            </Col>

            <Col md={12} className="mt-2">
              <div>
                <label htmlFor="imageFile"> {t("download.image")}</label>
                <input
                  onChange={fileHandler}
                  className="form-control"
                  type="file"
                  accept="image/*"
                  id="imageFile"
                />
              </div>
              {state?.image && (
                <img
                  className="mt-3 rounded show-img-form"
                  alt="200x200"
                  src={PICTURE_URL + state?.image.path}
                  data-holder-rendered="true"
                />
              )}
            </Col>
            <div className="col-12 my-2 d-flex justify-content-end text-align-center">
              <div>
                <button
                  className="btn btn-info"
                  type={"button"}
                  onClick={() => {
                    navigate(-1);
                    setState({} as any);
                  }}
                >
                  {t("global.cancel")}
                </button>
                <button className="btn btn-primary ms-3" type="submit">
                  {state?.id
                    ? `  ${t("global.update")}`
                    : `${t("global.create")}`}
                </button>
              </div>
            </div>
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
};
