import { NEWSLETTER_SUBSCRIBER_URL } from "../../../helpers/api.routes";
import { requests } from "../../../helpers/api.services";

export const AdminNewsletterEmailService = {
  getAll: (params: any) => requests.get(`${NEWSLETTER_SUBSCRIBER_URL}`, params),
  update: (params: any) =>
    requests.put(`${NEWSLETTER_SUBSCRIBER_URL}/${params.id}`, params),
  changeStatus: (id: any) =>
    requests.put(`${NEWSLETTER_SUBSCRIBER_URL}/active-status-change/${id}`),
  create: (params: any) => requests.post(`${NEWSLETTER_SUBSCRIBER_URL}`, params),
  getSingle: (id: number) => requests.get(`${NEWSLETTER_SUBSCRIBER_URL}/${id}`),
  delete: (id: number) => requests.delete(`${NEWSLETTER_SUBSCRIBER_URL}/${id}`),
};
