import {  TEST_MAIL_URL } from "../../../helpers/api.routes";
import { requests } from "../../../helpers/api.services";


export const AdminTestEmailService = {
  getAll: (params: any) => requests.get(`${TEST_MAIL_URL}`, params),
  update: (params: any) =>
    requests.put(`${TEST_MAIL_URL}/${params.id}`, params),
  create: (params: any) => requests.post(`${TEST_MAIL_URL}`, params),
  getSingle: (id: number) => requests.get(`${TEST_MAIL_URL}/${id}`),
  delete: (id: number) => requests.delete(`${TEST_MAIL_URL}/${id}`),
};
