import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { AdminTeamService } from "../service";
import { useNavigate, useParams } from "react-router-dom";
import { PICTURE_URL } from "../../../helpers/api.routes";
import { ISelect } from "../../../shared/interface";
import { AdminGreenPagesService } from "../../greenPages/service";

export const CreateForm = ({
  setArrayOfProviderCategories,
  setIsMenuOpened,
  state,
  setState,
  list,
  setImage,
}: any) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [provider, setProvider] = useState([]);
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const mappedData = provider?.map((category: any) => ({
    label: category.title,
    value: category.id,
  }));
  const fileHandler = (e: any) => {
    setImage(e.target.files[0]);
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminTeamService.getSingle(+id!)
        .then((response) => {
          const { data } = response;
          setState({
            ...data,
            isActive: state?.active,
          });
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);
  const selectHandler = (e: ISelect | null) => {
    setState((prev: any) => ({ ...prev, provider: e!.value }));
  };
  useEffect(() => {
    AdminGreenPagesService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;
        setProvider(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6}>
            <label htmlFor="firstName" className="required-field">
              {t("global.firstName")}
            </label>
            <input
              type="text"
              name="firstName"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.firstName}
            />
          </Col>
          <Col md={6} className="mt-1">
            <label htmlFor="lastName" className="required-field">
              {t("global.lastName")}
            </label>
            <input
              type="text"
              name="lastName"
              className="form-control"
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.lastName}
            />
          </Col>
          <Col md={6} className="mt-1">
            <label htmlFor="function" className="required-field">
              {t("global.function")}
            </label>
            <input
              type="text"
              name="function"
              className="form-control"
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.function}
            />
          </Col>
          <Col md={6} className="mt-1">
            <label htmlFor="email" className="required-field">
              {t("global.email")}
            </label>
            <input
              type="email"
              name="email"
              className="form-control"
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.email}
            />
          </Col>
          <Col md={6} className="mt-1">
            <label htmlFor="phone" className="required-field">
              {t("global.phone")}
            </label>
            <input
              type="text"
              name="phone"
              className="form-control"
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.phone}
            />
          </Col>
          <Col md={6} className="mt-1">
            <label htmlFor="phone2" className="required-field">
              {t("global.phone2")}
            </label>
            <input
              type="text"
              name="phone2"
              className="form-control"
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.phone2}
            />
          </Col>

          <Col md={6} className="mt-1">
            <label htmlFor="provider" className="required-field">
              {t("global.provider")}
            </label>
            <Select
              id="provider"
              className="react-select-field"
              onChange={selectHandler}
              options={mappedData}
              value={mappedData?.find(
                (category: any) => category?.value === state?.provider?.id
              )}
            />
          </Col>

          <Col md={4} className="">
            <div className="form-check form-switch p-0 mt-1">
              <label className="d-block text-start" htmlFor="isActive">
                {t("global.isActive")}
              </label>
              <div className="row">
                <input
                  className="form-check-input switch-custom ms-4 mt-2"
                  type="checkbox"
                  name="isActive"
                  checked={state?.isActive}
                  onChange={switchHandler}
                  role="switch"
                  id="isActive"
                />
              </div>
            </div>
          </Col>
          <Col md={12} className="mt-2">
            <div>
              <label htmlFor="imageFile"> {t("download.image")}</label>
              <input
                onChange={fileHandler}
                className="form-control"
                type="file"
                accept="image/*"
                id="imageFile"
              />
            </div>
            {state?.image && (
              <img
                className="mt-3 rounded show-img-form"
                alt="200x200"
                src={PICTURE_URL + state?.image.imagePath}
                data-holder-rendered="true"
              />
            )}
          </Col>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              type="button"
              onClick={() => navigate(-1)}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
