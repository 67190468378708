import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  ErrorToast,
  SuccessToast,
  WarningToast,
} from "../../../shared/toasters/toasters";
import { IProfilePassword } from "../dto/IProfile";
import { ProfileDetailsService } from "../service";

export const ChangePassword = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState<IProfilePassword>({
    password: "",
    oldPassword: "",
  });

  const [visibilityOldPassword, setVisibilityOldPassword] = useState(false);
  const [visibilityNewPassword, setVisibilityNewPassword] = useState(false);

  const submitPassword = (event: any) => {
    event.preventDefault();

    if (password.password === password.repatPassword) {
      ProfileDetailsService.updateProfilePassword(password)
        .then((response) => {
          SuccessToast(`${t("profile.successfully_password")}`);
        })
        .catch((error) => ErrorToast(error));
    } else {
      WarningToast(`${t("profile.same_passwords")}`);
    }
  };

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setPassword({
      ...password,
      [name]: value.trim(),
    });
  }

  return (
    <form onSubmit={submitPassword}>
      <div className="row">
        <div className="col-md-4 my-2">
          <label className="form-label required-field" htmlFor="password-input">
            {t("users.old_password")}
          </label>
          <div className="position-relative auth-pass-inputgroup mb-3">
            <input
              name="oldPassword"
              onChange={handleChange}
              type={`${visibilityOldPassword ? "text" : "password"}`}
              required
              className="form-control pe-5"
              onKeyDown={(event) =>
                event.key === "Enter" && submitPassword(event)
              }
              placeholder={t("profile.enter_old_password")}
              id="password-input"
            />
            <button
              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
              type="button"
              id="password-addon"
              onClick={() => setVisibilityOldPassword(!visibilityOldPassword)}
            >
              {visibilityOldPassword ? (
                <i className="ri-eye-line"></i>
              ) : (
                <i className="ri-eye-off-line"></i>
              )}
            </button>
          </div>
        </div>
        <div className="col-md-4 my-2">
          <label className="form-label required-field" htmlFor="password-input">
            {t("users.new_password")}
          </label>
          <div className="position-relative auth-pass-inputgroup mb-3">
            <input
              name="password"
              onChange={handleChange}
              type={`${visibilityNewPassword ? "text" : "password"}`}
              required
              className="form-control pe-5"
              onKeyDown={(event) =>
                event.key === "Enter" && submitPassword(event)
              }
              placeholder={t("profile.enter_new_password")}
              id="password-input"
            />
            <button
              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
              type="button"
              id="password-addon"
              onClick={() => setVisibilityNewPassword(!visibilityNewPassword)}
            >
              {visibilityNewPassword ? (
                <i className="ri-eye-line"></i>
              ) : (
                <i className="ri-eye-off-line"></i>
              )}
            </button>
          </div>
        </div>
        <div className="col-md-4 my-2">
          <label className="form-label required-field" htmlFor="password-input">
            {t("users.confirm_password")}
          </label>
          <div className="position-relative auth-pass-inputgroup mb-3">
            <input
              name="repatPassword"
              required
              onChange={handleChange}
              type="password"
              className="form-control pe-5"
              onKeyDown={(event) =>
                event.key === "Enter" && submitPassword(event)
              }
              placeholder={t("profile.enter_confirm_password")}
              id="password-input"
            />
          </div>
        </div>
        <div className="col-12 my-2 d-flex justify-content-end">
          <Link to="/app">
            <button className="btn btn-info">{t("global.cancel")}</button>
          </Link>
          <button className="btn btn-primary ms-3" type="submit">
            {t("users.change")}
          </button>
        </div>
      </div>
    </form>
  );
};
