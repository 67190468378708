import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { AdminMenuService } from "./service";
import Select from "react-select";
import { CreateForm } from "./components/CreateForm";
import { useTranslation } from "react-i18next";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import { useNavigate, useParams } from "react-router-dom";

export const AdminSubmenu = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [menus, setMenus] = useState<any>([{}]);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const navigate = useNavigate();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>({
    isActive: true,
    parent: null,
    name: "",
  });

  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [show, setShow] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [list, setList] = useState<any>();
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
  };

  const handleShow = () => setShow(true);
  const mappedData = menus?.map((position: any) => ({
    label: position.name,
    value: position.id,
  }));
  const deleteHandler = (selectedPrompt: any) => {
    selectedPrompt?.id &&
      AdminMenuService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            setList((prev: any) =>
              prev.filter((item: any) => item.id !== selectedPrompt.id)
            );
            // setTriggerUpdate(!triggerUpdate);
            SuccessToast(t(`menu.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };

  const titleClickHandler = () => {
    navigate("/admin/menu/children");
  };

  const editHandler = (brandId: number) => {
    setIsMenuOpened(true);
    AdminMenuService.getSingle(brandId)
      .then((response) => {
        const { data } = response;
        setState(data);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };
  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminMenuService.updatePosition({ ids: newListUpdated });
  };

  const submitHandler = () => {
    if (state.id) {
      AdminMenuService.update(
        {
          ...state,
          positionMenu: state?.positionMenu?.id ?? state?.positionMenu,
          parent: +id!,
        },
        state.id
      )
        .then((response) => {
          SuccessToast(t(`menu.successfully_updated`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setState({
            positionMenu: "top",
            isActive: true,
            parent: null,
            name: "",
          });
        })
        .catch((error) => ErrorToast(error));
    } else {
      AdminMenuService.create({ ...state, parent: +id! })
        .then((response) => {
          const { data } = response;
          SuccessToast(t(`menu.successfully_created`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setState({
            positionMenu: "top",
            isActive: true,
            parent: null,
            name: "",
          });
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    AdminMenuService.getAll({ ...pagination, perPage: 0, parent: +id! })
      .then((response) => {
        const { data } = response;
        setList(
          data?.items.map((item: any) => ({
            ...item,
            positionMenu: item?.positionMenu?.location,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);
  useEffect(() => {
    AdminMenuService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;

        setMenus(data.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <>
      {!isMenuOpened && (
        <div className="col-12">
          <div className="card">
            <div className="card-body d-flex align-items-center justify-content-between">
              <button
                className="btn btn-primary ms-auto"
                onClick={() => setIsMenuOpened(true)}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        </div>
      )}

      {isMenuOpened && (
        <div className="col-12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <CreateForm
              setIsMenuOpened={setIsMenuOpened}
              state={state}
              setState={setState}
              list={list}
            />
          </Form>
        </div>
      )}
      <div className="col-12">
        {/* <List
          page={page}
          handlePages={handlePages}
          totalPages={totalPages}
          list={list}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
        /> */}

        {!!list?.length && (
          <div>
            <DraggableBootstrapTable
              list={list}
              preview={true}
              setList={setList}
              headers={["id", "website", "name", "positionMenu"]}
              title="submenu"
              onEdit={editHandler}
              selectedPrompt={selectedPrompt}
              setSelectedPrompt={setSelectedPrompt}
              handleShow={handleShow}
              show={show}
              setShow={setShow}
              deleteHandler={deleteHandler}
              updatePosition={updatePosition}
              titleClickHandler={titleClickHandler}
            />
          </div>
        )}
      </div>
    </>
  );
};
