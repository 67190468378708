import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { AdminNewsLetterSentService } from "./sevice";
import { List } from "./components/List";
export const AdminNewsletterSent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [brandsList, setBrandsList] = useState(undefined);
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };

  const editHandler = (id: number) => {
    navigate(`/admin/newsletters/list/${id}`);
  };

  useEffect(() => {
    AdminNewsLetterSentService.getAll(pagination)
      .then((response) => {
        const { data } = response;

        setBrandsList(data.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);

  return (
    <>
      <div className="col-12">
        <List
          page={page}
          handlePages={handlePages}
          totalPages={totalPages}
          list={brandsList}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
        />
      </div>
    </>
  );
};
