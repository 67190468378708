import { N1ED } from "@edsdk/n1ed-react";
import { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Editor } from "tinymce";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { RootState } from "../../../store";
import { ETheme } from "../../../store/slices/theme.slice";
import { AdminBannerService } from "../service";

export const CreateForm = ({ state, setState, zones }: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const firstLoad = useRef(true);
  const [editorData, setEditorData] = useState<any>();
  const currentTheme = useSelector((state: RootState) => state.theme);
  const isDarkMode: boolean = (currentTheme as string) === ETheme.dark;
  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const selectHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, zone: e.value }));
  };
  const mappedData = zones?.map((zone: any) => ({
    label: zone.zone,
    value: zone.id,
  }));
  const changeEditorHandler = (content: string, editor: Editor) => {
    setState((prev: any) => ({ ...prev, [editor?.id]: content }));
    if (!firstLoad.current) {
      setEditorData(editor);
    }
    firstLoad.current = false;
  };
  const switchHandler2 = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminBannerService.getSingle(+id!)
        .then((response) => {
          const { data } = response;
          setState(data);
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col md={5} className="mb-3">
              <label htmlFor="title" className="required-field">
                {t("global.title")}
              </label>
              <input
                type="text"
                name="title"
                className="form-control"
                required
                onChange={changeHandler}
                value={state?.id && state?.title}
              />
            </Col>

            <Col md={5}>
              <label htmlFor="category" className="required-field">
                {t("table_headers.zone")}
              </label>
              <Select
                className="react-select-field"
                onChange={selectHandler}
                options={mappedData}
                value={mappedData?.find(
                  (category: any) => category.value === state?.zone?.id
                )}
              />
            </Col>
            <Col md={2} className=" mt-2">
              <label
                className="d-block text-start"
                htmlFor="flexSwitchCheckDefault"
              >
                {t("global.active")}
              </label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input switch-custom"
                  type="checkbox"
                  name="active"
                  checked={state?.active}
                  onChange={switchHandler2}
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
              </div>
            </Col>
            <Col md={12}>
              {/* <div
                className={`row my-2 ${isDarkMode ? "darkEditor" : "1"}`}
                key={isDarkMode ? 1 : 0}
              >
                <label className="d-block text-start" htmlFor="content">
                  {t("global.code")}
                </label>

                <N1ED
                  id={`code`}
                  apiKey={process.env.REACT_APP_EDITOR_KEY}
                  initialValue={
                    editorData ? editorData : state ? state?.code : ""
                  }
                  onEditorChange={changeEditorHandler}
                />
              </div> */}
              <label htmlFor="category" className="required-field">
                {t("global.code")}
              </label>
              <textarea
                className="form-control"
                id={`code`}
                rows={6}
                name="code"
                value={state?.code}
                onChange={(e) => changeHandler(e as any)}
              ></textarea>
            </Col>
          </Row>
          <div className="col-12 my-2 d-flex justify-content-end text-align-center">
            <div>
              <button
                className="btn btn-info"
                onClick={() => navigate("/admin/banner")}
              >
                {t("global.cancel")}
              </button>
              <button className="btn btn-primary ms-3" type="submit">
                {state?.id
                  ? `  ${t("global.update")}`
                  : `${t("global.create")}`}
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
