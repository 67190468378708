import { useEffect, useState } from "react";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { List } from "./components/List";
import { AdminAlbumService } from "./service";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import { Pagination } from "../../shared/paginations/Paginations";

export const AdminAlbum = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 20,
    term: "",
  });
  const [list, setList] = useState(undefined);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const editHandler = (id: number) => {
    navigate(`/admin/album/update/${id}`);
  };
  const deleteHandler = () => {
    selectedPrompt?.id &&
      AdminAlbumService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`album.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminAlbumService.updatePosition({ ids: newListUpdated });
  };
  useEffect(() => {
    AdminAlbumService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-3">
              <div className="search-box">
                <input
                  type="text"
                  className="form-control"
                  id="searchMemberList"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPagination((prev) => ({
                      ...prev,
                      term: e.target.value,
                    }))
                  }
                  placeholder={t("global.search")}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
            </div>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/admin/album/create")}
            >
              {t("global.add")}
            </button>
          </div>
          {/* <div className="card-body d-flex align-items-center justify-content-between">
            <h4 className="card-title mg-b-0">{t("album.create_new_album")}</h4>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/admin/album/create")}
            >
              {t("global.add")}
            </button>
          </div> */}
        </div>
      </div>
      {/* 
      {page > 1 ? ( */}
      <>
        <div className="col-12">
          <List
            // page={page}
            // handlePages={handlePages}
            // totalPages={totalPages}
            list={list!}
            setTriggerUpdate={setTriggerUpdate}
            editHandler={editHandler}
            // domain={domain}
          />
        </div>{" "}
      </>
      {/* // ) : (
      //   <> */}
      {/* <div className="col-12">
            <DraggableBootstrapTable
              preview={true}
              list={list!}
              setList={setList}
              headers={["id", "title", "date", "isActive"]}
              title="album"
              onEdit={editHandler}
              selectedPrompt={selectedPrompt}
              setSelectedPrompt={setSelectedPrompt}
              handleShow={handleShow}
              show={show}
              setShow={setShow}
              deleteHandler={deleteHandler}
              updatePosition={updatePosition}
            />
          </div> */}
      {/* //   </> */}
      {/* // )} */}
      {page && (
        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      )}
    </>
  );
};
