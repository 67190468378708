// import React from "react";

// export const UpdateFormContinuing = () => {
//   return <div>UpdateForm</div>;
// };
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { CreateForm } from "./Form";
import { EducationContinuingService } from "../../service";
export const UpdateFormContinuing = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>({
    title: "",
    isActive: true,
    fileData: [
      {
        id: null,
        name: "",
        path: "",
        legend: "",
        credit: "",
        index: null,
      },
    ],
  });
  const [image, setImage] = useState<File[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const submitHandler = (event: any) => {
    const formData = new FormData();
    console.log(files, "files");

    // if (files) {
    //   for (let i = 0; i < files?.length; i++) {
    //     if (files[i].size) {
    //       formData.append("additionalFile[]", files[i]);
    //     }
    //   }
    // }
    // formData.append("mainImage[]", image as any);
    // formData.append(
    //   "body",
    //   JSON.stringify({ title: state?.title, isActive: state?.isActive })
    // );
    // EducationContinuingService.update(formData)
    //   .then((response) => {
    //     SuccessToast(t(`event_cat.successfully_updated`));
    //     navigate("/admin/education-continuing");
    //   })
    //   .catch((error) => ErrorToast(error));
  };

  return (
    <div className="col-12" id="scrollable-form">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e);
        }}
      >
        {/* <div className="col-8">
          <label htmlFor="redirectToUrl" className="required-field">
            {t("global.redirectToUrl")}
          </label>
          <input
            id="redirectToUrl"
            type="url"
            name="redirectToUrl"
            className="form-control"
          />
        </div> */}
        <CreateForm
          setFiles={setFiles}
          files={files}
          state={state}
          setImage={setImage}
          setState={setState}
        />
      </Form>
    </div>
  );
};
