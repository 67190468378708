import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { PICTURE_URL } from "../../../helpers/api.routes";
import { IEventSummaryCategory, IForm } from "../interface";

export const CreateForm = ({
  setIsMenuOpened,
  state,
  setImage,
  setState,
}: IForm) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  function handleDate(date: Date) {
    const value = new Date(date);
    value.setHours(12);
    setStartDate(date);
    setState((prev: any) => ({ ...prev, date: value }));
  }
  const fileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImage(e.target.files![0]);
  };

  const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4}>
            <label htmlFor="name" className="required-field">
              {t("global.name")}
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              required
              onChange={changeHandler}
              value={state?.id && state?.name}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="date" className="required-field">
              {t("global.date")}
            </label>
            <div className="datepicker-container">
              <DatePicker
                className="date-picker"
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                value={state?.date}
                onChange={(date) => handleDate(date as Date)}
                isClearable
                placeholderText="Choose date..."
              />
            </div>
          </Col>
          <Col md={4} className="">
            <div className="form-check form-switch p-0">
              <label
                className="d-block text-start"
                htmlFor="flexSwitchCheckDefault"
              >
                {t("global.active")}
              </label>
              <div className="row">
                <input
                  className="form-check-input switch-custom ms-4 mt-2"
                  type="checkbox"
                  name="isActive"
                  checked={state?.isActive}
                  onChange={switchHandler}
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </Col>
          <Col md={6} className="my-3">
            <div>
              <label
                htmlFor="exampleFormControlTextarea5"
                className="form-label"
              >
                {t("download.description")}
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea5"
                rows={3}
                name="description"
                value={state?.description}
                onChange={changeHandler}
              ></textarea>
            </div>
          </Col>
          <Col md={6} className="my-3">
            <div>
              <label htmlFor="imageLegend" className="form-label">
                {t("admin_event_summary.image_legend")}
              </label>
              <textarea
                className="form-control"
                id="imageLegend"
                rows={3}
                name="imageLegend"
                value={state?.imageLegend}
                onChange={changeHandler}
              ></textarea>
            </div>
          </Col>
        </Row>

        <div className="mt-3">
          <label htmlFor="imageFile"> {t("download.image")}</label>
          <input
            onChange={fileHandler}
            className="form-control"
            type="file"
            accept="image/*"
            id="imageFile"
          />
        </div>
        {state?.image && (
          <div className="col-md-6 py-3">
            <img
              className="rounded"
              alt="200x200"
              width={200}
              src={PICTURE_URL + state?.image.imagePath}
              data-holder-rendered="true"
            />
          </div>
        )}
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                setIsMenuOpened(false);
                setState({} as IEventSummaryCategory);
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
