import React, { useEffect, useState } from "react";
import { Loader } from "../../../../shared/components/Loader";
import { EmptyState } from "../../../../shared/components/EmptyState";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Pagination } from "../../../../shared/paginations/Paginations";
import { ErrorToast } from "../../../../shared/toasters/toasters";
import { AdminNewsLetterSentService } from "../sevice";
import axios, { AxiosRequestConfig } from "axios";
export const ErrorList = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [list, setList] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    newsletter: id,
    term: "",
  });
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const downloadXLSFile = async () => {
    const website = localStorage.getItem("website");
    const appVersion = localStorage.getItem("appVersion");
    const headers = {
      "Content-Type": "blob",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "app-version": appVersion,
      "Aw-Web-Site": website ?? "aw",
    };
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_DEV_MODE
          : process.env.REACT_APP_PRO_MODE
      }admin/email/newsletter/export`,
      responseType: "arraybuffer",
      headers,
      params: {
        term: pagination?.term ?? "",
        newsletter: id,
      },
    };
    const response = await axios(config);
    const url = URL.createObjectURL(new Blob([(response as any)?.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${Date.now()}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  useEffect(() => {
    AdminNewsLetterSentService.getAllErrors(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [pagination]);
  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-sm btn-primary d-flex align-items-center mb-1"
            onClick={() => navigate(-1)}
          >
            <i className="ri-arrow-left-line me-1 " />
            {t("global.back")}
          </button>
          <button
            className="btn btn-sm btn-success d-flex align-items-center mb-1"
            onClick={() => downloadXLSFile()}
          >
            <i className="ri-download-line me-1 " />
            {t("global.export")}
          </button>
          <div className="search-box mb-1">
            <input
              type="text"
              className="form-control"
              id="searchMemberList"
              onChange={(e: any) =>
                setPagination((prev: any) => ({
                  ...prev,
                  page: 1,
                  term: e.target.value,
                }))
              }
              placeholder="Search..."
            />
            <i className="ri-search-line search-icon"></i>
          </div>
        </div>
      </div>
      <div className="card-body">
        {list == undefined ? (
          <Loader />
        ) : list.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle mb-0">
              <thead className="table-light text-center">
                <tr className="">
                  <th scope="col">{t(`table_headers.id`)}</th>
                  <th scope="col">{t(`table_headers.receiverEmail`)}</th>
                  <th scope="col">{t(`table_headers.error`)}</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {list?.map((prompt: any) => {
                  return (
                    <tr key={prompt?.id} className="">
                      <td>
                        <Link to="#" className="fw-medium">
                          #{prompt?.id}
                        </Link>
                      </td>
                      <td>{prompt?.receiverEmail ?? "-"}</td>
                      <td className="text-start">
                        {prompt?.errors?.length > 0
                          ? prompt?.errors?.map((message: any) => {
                              if (message !== null) {
                                return (
                                  <p className="d-block mb-0">{message}</p>
                                );
                              }
                            })
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}

        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
        {/* <ConfirmDelete
      show={show}
      setShow={setShow}
      itemName={selectedPrompt?.email}
      deleteHandler={deleteHandler}
      product={selectedPrompt}
      selectedProduct={selectedPrompt}
    /> */}
      </div>
    </div>
  );
};
