import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
// import { AdminTestEmailService } from "../service";
import { Loader } from "../../../../shared/components/Loader";
import { EmptyState } from "../../../../shared/components/EmptyState";
import { ConfirmDelete } from "../../../../shared/components/ConfirmDelete";
import { Pagination } from "../../../../shared/paginations/Paginations";
export const List = ({
  accessRights,
  list,
  totalPages,
  page,
  handlePages,
  editHandler,
}: any) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [selectedPrompt] = useState<any>();
  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <h4 className="card-title mg-b-0">
            {t("newsletter.list_sent_newsletter")}
          </h4>
        </div>
      </div>
      <div className="card-body">
        {list == undefined ? (
          <Loader />
        ) : list.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light text-center">
                <tr className="">
                  <th scope="col">{t(`table_headers.id`)}</th>
                  <th scope="col">{t(`newsletter.newsletter`)}</th>
                  <th scope="col">{t(`newsletter.test`)}</th>
                  <th scope="col">{t(`newsletter.breakingNews`)}</th>
                  <th scope="col">{t(`newsletter.overview`)}</th>

                  <th scope="col">{t(`global.action`)}</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {list?.map((prompt: any) => {
                  return (
                    <tr key={prompt?.id} className="">
                      <td>
                        <Link to="#" className="fw-medium">
                          #{prompt?.id}
                        </Link>
                      </td>
                      <td>{prompt?.subject ?? "-"}</td>
                      <td>{prompt?.isTest ? "Test" : "-"}</td>
                      <td>{prompt?.isBreakingNews ? "Breaking news" : "-"}</td>

                      <td>{prompt?.count}</td>

                      <td>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="btn btn-sm btn-warning d-flex align-items-center me-2"
                            onClick={() => editHandler(prompt?.id)}
                          >
                            <i className="ri-edit-2-fill me-1" />
                            {t("global.statistics")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}

        <div className="d-flex justify-content-end ">
          {totalPages <= 1 || list.length === 0 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedPrompt?.name}
          product={selectedPrompt}
          selectedProduct={selectedPrompt}
        />
      </div>
    </div>
  );
};
