import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ProfileDetailsService } from "./app/profile/service";
import { BaseRoutes } from "./app/routes";
import { GlobalService } from "./app/services";
import { authSlice } from "./store/slices/auth.slice";
import { enumSlice } from "./store/slices/enum.slice";
import i18next from "./utils/translations/i18next";
import { companySlice } from "./store/slices/company.slice";

function App() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getAuth = useSelector((state: any) => state.auth);
  useEffect(() => {
    const website = localStorage.getItem("website");

    document.title = `Centralized CMS ${
      website ? `- ${t(`enums.${website}`)}` : ``
    }`;

    return () => {
      document.title = `Centralized CMS ${
        website ? `- ${t(`enums.${website}`)}` : ``
      }`;
    };
  }, [localStorage.getItem("website"), localStorage.getItem("token")]);

  useEffect(() => {
    GlobalService.getEnums().then((response) => {
      const { data } = response;
      dispatch(enumSlice.actions.setEnums(data));
    });
  }, [dispatch]);

  useEffect(() => {
    if (!localStorage.getItem("token")) return;
    ProfileDetailsService.getProfileDetails().then((response) => {
      const data = Object.keys(response?.administrationData);

      localStorage.setItem("projectVersion", response.projectVersion);

      dispatch(companySlice.actions.setCompany(response?.companyData));

      if (!localStorage.getItem("website")) {
        const firstReady = data.filter(
          (site) => response?.administrationData[site].length
        )[0];

        localStorage.setItem("website", firstReady);

        document.title = `Centralized CMS - ${t(`enums.${firstReady}`)}`;
      }

      dispatch(authSlice.actions.setAuth(response));
    });
  }, [dispatch]);

  const currentLanguage = localStorage.getItem("language");
  useEffect(() => {
    i18next.changeLanguage(currentLanguage ?? "en");
  }, [currentLanguage]);
  return (
    <BrowserRouter>
      <BaseRoutes />
    </BrowserRouter>
  );
}

export default App;
