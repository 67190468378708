import { EVENT_SUMMARY_CAT_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminEventSummaryCatService = {
  getAll: (params: any) => requests.get(`${EVENT_SUMMARY_CAT_URL}`, params),
  create: (params: any) =>
    requests.post(`${EVENT_SUMMARY_CAT_URL}`, params, true),
  getSingle: (id: number) => requests.get(`${EVENT_SUMMARY_CAT_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.post(`${EVENT_SUMMARY_CAT_URL}/${id}`, params, true),
  updatePosition: (params: any) =>
    requests.patch(`${EVENT_SUMMARY_CAT_URL}`, params),
  delete: (id: number) => requests.delete(`${EVENT_SUMMARY_CAT_URL}/${id}`),
};
