import { PAGE_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const AdminPageService = {
  getAll: (params: any) => requests.get(`${PAGE_URL}`, params),
  create: (params: any) => requests.post(`${PAGE_URL}`, params),
  getSingle: (id: number) => requests.get(`${PAGE_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.post(`${PAGE_URL}/${id}`, params),
  delete: (id: number) => requests.delete(`${PAGE_URL}/${id}`),
};
