import React from "react";
import GreenPagesTabs from "./GreenPagesTabs";

export const UpdateGreenPages = () => {
    return (
        <div className="col-12">
            <GreenPagesTabs />
        </div>
    );
};
