import { GALLERY_URL } from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";
import {IUpdateGalleryImage} from "./components/Gallery";

export const AdminGalleryService = {
  getAll: (params: any) => requests.get(`${GALLERY_URL}`, params),
  create: (params: any) => requests.post(`${GALLERY_URL}`, params, true),
  getSingle: (id: number) => requests.get(`${GALLERY_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.put(`${GALLERY_URL}/${id}`, params),
  delete: (id: number) => requests.delete(`${GALLERY_URL}/${id}`),
  updatePosition: (params: any) => requests.patch(`${GALLERY_URL}`, params),
  getImageDesc: (id: number) => requests.get(`${GALLERY_URL}/data/${id}`),
  updateImageDesc: (params: IUpdateGalleryImage) => requests.put(`${GALLERY_URL}/data/${params.id}`, params),
};
