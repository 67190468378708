import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { CreateForm } from "./components/CreateForm";
import { AdminCategoryService } from "./service";
import { List } from "./components/List";
import { Pagination } from "../../shared/paginations/Paginations";

export const AdminCategory = () => {
  const { t } = useTranslation();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState<File>();
  const handleShow = () => setShow(true);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    term: "",
  });
  const [list, setList] = useState<any>(undefined);

  const deleteHandler = () => {
    selectedPrompt?.id &&
      AdminCategoryService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`category.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const editHandler = (brandId: number) => {
    setIsMenuOpened(true);
    AdminCategoryService.getSingle(brandId)
      .then((response) => {
        const { data } = response;
        setState(data);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };

  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminCategoryService.updatePosition({ ids: newListUpdated });
  };
  const submitHandler = () => {
    const formData = new FormData();
    formData.append("body", JSON.stringify(state));
    image && formData.append("image", image);
    if (state.id) {
      AdminCategoryService.update(formData, state.id)
        .then((response) => {
          SuccessToast(t(`category.successfully_updated`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    } else {
      AdminCategoryService.create(formData)
        .then((response) => {
          SuccessToast(t(`category.successfully_created`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    }
  };

  useEffect(() => {
    AdminCategoryService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);

  return (
    <>
      {!isMenuOpened && (
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row g-2">
                <div className="col-sm-4">
                  <div className="search-box">
                    <input
                      type="text"
                      onChange={(e: any) =>
                        setPagination((prev: any) => ({
                          ...prev,
                          term: e.target.value,
                        }))
                      }
                      className="form-control"
                      id="searchMemberList"
                      placeholder="Search for name or designation..."
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </div>
                <div className="col-sm-auto ms-auto">
                  <div className="list-grid-nav hstack gap-1">
                    <button
                      className="btn btn-info"
                      onClick={() => setIsMenuOpened(true)}
                    >
                      {t("global.add")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isMenuOpened && (
        <div className="col-12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <CreateForm
              setIsMenuOpened={setIsMenuOpened}
              state={state}
              setState={setState}
              image={image}
              setImage={setImage}
            />
          </Form>
        </div>
      )}
      {/* <div className="col-12">
        <DraggableBootstrapTable
          list={list}
          setList={setList}
          headers={["id", "name"]}
          title="category"
          onEdit={editHandler}
          selectedPrompt={selectedPrompt}
          setSelectedPrompt={setSelectedPrompt}
          handleShow={handleShow}
          show={show}
          setShow={setShow}
          deleteHandler={deleteHandler}
          updatePosition={updatePosition}
        />
      </div> */}
      {page > 1 || (pagination as any)?.term !== "" ? (
        <>
          <div className="col-12">
            <List
              page={page}
              handlePages={handlePages}
              totalPages={totalPages}
              list={list}
              setTriggerUpdate={setTriggerUpdate}
              editHandler={editHandler}
              // domain={domain}
            />
          </div>{" "}
        </>
      ) : (
        <>
          <div className="col-12">
            <DraggableBootstrapTable
              list={list}
              setList={setList}
              headers={["id", "name"]}
              title="category"
              onEdit={editHandler}
              selectedPrompt={selectedPrompt}
              setSelectedPrompt={setSelectedPrompt}
              handleShow={handleShow}
              show={show}
              setShow={setShow}
              deleteHandler={deleteHandler}
              updatePosition={updatePosition}
              preview={true}
            />
          </div>
        </>
      )}

      {page && (
        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      )}
    </>
  );
};
