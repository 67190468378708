import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useNavigate, useParams } from "react-router-dom";
// import { AdminGalleryService } from "../service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { AdminAlbumService } from "../../album/service";
import { AdminVideoService } from "../service";
import DatePicker from "react-datepicker";
import { CustomImageHandler } from "../../../shared/components/CustomImageHandler";

export const CreateForm = ({
  setIsMenuOpened,
  state,
  setImage,
  setState,
  fileHandler,
}: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [mainImage, setMainImage] = useState<any>("");
  const currentTheme = useSelector((state: RootState) => state.theme);
  const [albums, setAlbums] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const navigate = useNavigate();

  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  function handleDate(date: Date) {
    const value = new Date(date);
    value.setHours(12);
    setStartDate(date);
    setState((prev: any) => ({ ...prev, date: value }));
  }
  const handleMainImage = (e: any) => {
    setImage(e.target.files[0]);
    setMainImage(URL.createObjectURL(e.target.files[0]!));
  };
  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminVideoService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;
          setState({ ...data, category: data?.category?.id });
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);
  useEffect(() => {
    AdminAlbumService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;
        setAlbums(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6} className="mt-2">
            <label>{t("global.title")}</label>
            <input
              name="title"
              type="text"
              className="form-control"
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.title}
            />
          </Col>
          <Col md={6}>
            <label htmlFor="date" className="required-field">
              {t("global.date")}
            </label>
            <div className="datepicker-container">
              <DatePicker
                className="date-picker custom_zindex"
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                value={state?.date}
                onChange={(date) => handleDate(date as Date)}
                isClearable
                placeholderText="Choose date..."
              />
            </div>
          </Col>
          <Col md={9} className="mt-2">
            <label htmlFor="redirectToUrl" className="required-field">
              {t("global.url")}
            </label>
            <input
              id="url"
              type="url"
              name="url"
              className="form-control"
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.url}
            />
          </Col>
          <Col md={3} className=" mt-2">
            <label
              className="d-block text-start"
              htmlFor="flexSwitchCheckDefault"
            >
              {t("global.active")}
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input switch-custom"
                type="checkbox"
                name="isActive"
                checked={state?.isActive}
                onChange={switchHandler}
                role="switch"
                id="flexSwitchCheckDefault"
              />
            </div>
          </Col>
          <Col md={12} className="my-2">
            <label htmlFor="seoDescription" className="required-field">
              {t("global.description")}
            </label>
            <textarea
              id="description"
              name="description"
              rows={3}
              className="form-control"
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.description}
            />
          </Col>

          <Col md={12} className="my-2">
            <div>
              <label htmlFor={`embedded_code`} className="form-label">
                {t("table_headers.embedded_code")}
              </label>
              <textarea
                className="form-control"
                id={`embedded_code`}
                rows={3}
                name="embeddedCode"
                value={state?.embeddedCode}
                onChange={(e) => changeHandler(e as any)}
              ></textarea>
            </div>
          </Col>
          <Col md={12}>
            <div>
              <label htmlFor=""> {t("news.main_image")}</label>
            </div>
            <div className="profile-user position-relative d-inline-block mx-auto">
              {mainImage || state?.image?.path ? (
                <CustomImageHandler
                  path={mainImage ? mainImage : state?.image?.path}
                  classes="avatar-xl img-thumbnail user-profile-image mx-auto"
                  alt="user-profile-image"
                />
              ) : (
                <img
                  src="/assets/images/img-placeholder.png"
                  className="avatar-xl img-thumbnail user-profile-image"
                  alt="user-profile-image"
                />
              )}
              <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                <input
                  name="path"
                  type="file"
                  accept="image/*"
                  id="main"
                  // ref={imageRef}
                  className="profile-img-file-input "
                  onChange={(e) => {
                    handleMainImage(e);
                  }}
                />
                <label
                  // htmlFor="profile-img-file-input"\
                  htmlFor="main"
                  className="profile-photo-edit avatar-xs"
                >
                  <span className="avatar-title rounded-circle bg-light text-body">
                    <i className="ri-camera-fill" />
                  </span>
                </label>
              </div>
            </div>
          </Col>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                navigate("/admin/video-gallery");
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `${t("global.edit")}` : `${t("global.add")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
