import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EVENT_URL } from "../../helpers/api.routes";
import { multipartFormData } from "../../helpers/api.services";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { AdminEventCategoryService } from "../eventCategory/service";
import { CreateForm } from "./components/CreateForm";
import { List } from "./components/List";
import { AdminAdminService } from "./service";
export const AdminAdmin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [categories, setCategories] = useState();
  const [currentState, setCurrentState] = useState<any>({ isActive: true });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [image, setImage] = useState<File>();

  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [brandsList, setBrandsList] = useState(undefined);
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };

  const editHandler = (id: number) => {
    navigate(`/admin/admin/update/${id}`);
  };
  const submitHandler = () => {
    // const date = new Date(currentState?.date);
    // const correctDate = date?.toISOString();

    if (currentState?.id) {
      multipartFormData(
        image,
        `${EVENT_URL}/${currentState?.id}`,
        JSON.stringify({
          ...currentState,
          // date: correctDate,
          category: currentState?.category,
        }),
        "post"
      )
        .then((response) => {
          SuccessToast(t(`event.successfully_updated`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setCurrentState({});
        })
        .catch((error) => ErrorToast(error));
    } else {
      multipartFormData(
        image,
        `${EVENT_URL}`,
        JSON.stringify({
          ...currentState,
          // date: correctDate,
          category: currentState?.category,
        }),
        "post"
      )
        .then((response) => {
          SuccessToast(t(`event.successfully_created`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setCurrentState({});
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    AdminAdminService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setBrandsList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);

  useEffect(() => {
    AdminEventCategoryService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  return (
    <>
      {!isMenuOpened && (
        <div className="col-12">
          <div className="card">
            <div className="card-body d-flex align-items-centent">
              <div className="d-flex align-items-center gap-3">
                <div className="search-box">
                  <input
                    type="text"
                    className="form-control"
                    id="searchMemberList"
                    onChange={(e: any) =>
                      setPagination((prev: any) => ({
                        ...prev,
                        term: e.target.value,
                      }))
                    }
                    placeholder="Search for something..."
                  />
                  <i className="ri-search-line search-icon"></i>
                </div>
              </div>
              <button
                className="btn btn-primary ms-auto"
                onClick={() => navigate("/admin/admin/create")}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        </div>
      )}

      {isMenuOpened && (
        <div className="col-12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <CreateForm
              image={image}
              setImage={setImage}
              setIsMenuOpened={setIsMenuOpened}
              categories={categories}
              state={currentState}
              setState={setCurrentState}
            />
          </Form>
        </div>
      )}
      <div className="col-12">
        <List
          page={page}
          handlePages={handlePages}
          totalPages={totalPages}
          brandsList={brandsList}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
        />
      </div>
    </>
  );
};
