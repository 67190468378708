import { createSlice } from "@reduxjs/toolkit";

export enum ETheme {
  dark = "dark",
  light = "light",
}

const initialState: ETheme = ETheme.light;
export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});
