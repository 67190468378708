import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { SingleValue } from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { AdminUserService } from "../service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { themeSelect } from "../../../shared/components/Select";
import { List } from "./List";
export const UserInactive = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userStatus } = useSelector((state: RootState) => state.enum);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [image, setImage] = useState(undefined);

  // const status = ["Imported", "Crawled", "Manually"];
  //   const handleStatus = () => {
  //     if (document.location.pathname.includes("active"a)) {
  //       return "ACTIVE";
  //     } else if (document.location.pathname.includes("inactive")) {
  //       return "INACTIVE";
  //     } else {
  //       return "PENDING";
  //     }
  //   };

  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    status: "INACTIVE",
  });
  const [list, setList] = useState(undefined);
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const editHandler = (id: number) => {
    navigate(`/admin/user/update/${id}`);
  };
  const handleSelectStatus = (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => {
    if (selectedSingle?.value as string) {
      setPagination({
        ...pagination,
        page: 1,
        perPage: 10,
        status: selectedSingle?.value as string,
      } as any);
    } else {
      setPagination({
        ...pagination,
        page: 1,
        perPage: 10,
        status: null,
      } as any);
    }
  };
  useEffect(() => {
    setTriggerUpdate((prev: boolean) => !prev);
  }, []);

  useEffect(() => {
    AdminUserService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body d-flex  align-items-center">
            <div className="d-flex gap-3 test-user">
              <div className="search-box">
                <input
                  type="text"
                  className="form-control"
                  id="searchMemberList"
                  onChange={(e: any) =>
                    setPagination((prev: any) => ({
                      ...prev,
                      term: e.target.value,
                    }))
                  }
                  placeholder="Search for user"
                />
                <i className="ri-search-line search-icon"></i>
              </div>
              <div style={{ width: "200px" }} className="nesto">
                <Select
                  isDisabled={true}
                  isClearable={true}
                  theme={themeSelect}
                  placeholder={t("global.status")}
                  defaultValue={
                    pagination?.status && {
                      label: t(`global.${pagination?.status?.toLowerCase()}`),
                      value: pagination?.status,
                    }
                  }
                  //   options={userStatus?.map((item: string) => ({
                  //     label: item,
                  //     value: item,
                  //   }))}
                  onChange={(e) => {
                    handleSelectStatus(
                      e as SingleValue<{ label: string; value: string }>
                    );
                  }}
                />
              </div>
            </div>

            <button
              className="btn btn-primary ms-auto"
              onClick={() => navigate("/admin/user/create")}
            >
              {t("global.add")}
            </button>
          </div>
        </div>
      </div>

      <div className="col-12">
        <List
          page={page}
          handlePages={handlePages}
          totalPages={totalPages}
          list={list}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
        />
      </div>
    </>
  );
};
