import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CreateForm } from "./CreateForm";
import { Form } from "react-bootstrap";

import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";

import { useTranslation } from "react-i18next";
import { multipartFormData } from "../../../helpers/api.services";
import { USERS_URL } from "../../../helpers/api.routes";
import { AdminUserService } from "../service";
export const UpdateUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState<any>();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [image, setImage] = useState(undefined);
  const [password, setPassword] = useState("");

  // const changePassword = () => {
  //   AdminUserService.changePassword(id, { password }).then((res) => {
  //     navigate("/admin/user");
  //   });
  // };

  const submitHandler = (event: React.FormEvent, id?: number) => {
    if (state?.id) {
      multipartFormData(
        image,
        `${USERS_URL}/${state?.id}`,
        JSON.stringify({
          ...state,
        }),
        "post"
      )
        .then((response) => {
          SuccessToast(t(`user.successfully_updated`));
          navigate("/admin/user");
        })
        .catch((error) => ErrorToast(error));
    }
  };

  return (
    <div className="col-12">
      <CreateForm
        state={state}
        setState={setState}
        // setPassword={setPassword}
        // changePassword={changePassword}
        submitHandler={submitHandler}
      />
    </div>
  );
};
//  submitHandler(e, state?.id);
