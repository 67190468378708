import React, {useEffect, useState} from "react";

import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {AdminWaitingNewsService} from "./service";
import {List} from "./components/List";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import {Pagination} from "../../shared/paginations/Paginations";
import Select from "react-select";
import {perPageOptions} from "../../shared/paginations/perPageOptions";

export const AdminWaitingNews = () => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleShow = () => setShow(true);
    const [selectedPrompt, setSelectedPrompt] = useState<any>();
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const domain = useSelector((state: any) => state.company.clientDomain);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 25,
        term: "",
    });
    const [list, setList] = useState([]);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);

    const handlePages = (updatePage: number) => {
        setPagination({ ...pagination, page: updatePage });
        setPage(updatePage);
    };
    const editHandler = (id: number): void => {
        navigate(`/admin/news/update/${id}`);
    };
    const handlePerPage = (e: any) => {
        setPagination((prev) => ({ ...prev, page: 1, perPage: e.value }));
    };
    const moveToWaitingList = async () => {
        await AdminWaitingNewsService.update(selectedIds)
            .then(response => {
                if (response?.status === 'success') {
                    setSelectedIds([]);
                    SuccessToast(t('news.successfully_updated'));
                    setTriggerUpdate((prev) => !prev);
                }
            }).catch(err => ErrorToast(err));
    }
    const handleCheckAll = (e: any) => {
        if (e.target.checked) {
            const mapped = list?.map((product: any) => product.id);
            setSelectedIds(mapped as number[]);
        } else {
            setSelectedIds([]);
        }
    };
    useEffect(() => {
        AdminWaitingNewsService.getAll(pagination)
            .then((response) => {
                const {data} = response;
                if (data.items.length === 0 && data?.page > 1) {
                    handlePages(1);
                } else {
                    setList(data?.items);
                    setPage(data?.page);
                    setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
                }
            })
            .catch((error) => ErrorToast(error));
    }, [triggerUpdate, pagination]);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-2">
                                    {/* <h6 className="my-auto me-2 p-0 ">Per page:</h6> */}
                                    <Select
                                        className="react-select-field"
                                        onChange={handlePerPage}
                                        placeholder="Per page"
                                        options={perPageOptions}
                                        defaultValue={perPageOptions[0]}
                                    />
                                </div>
                                <div className="col-3">
                                    <div className="search-box">
                                        <input
                                            style={{ height: "38px" }}
                                            type="text"
                                            className="form-control"
                                            id="searchMemberList"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setPagination((prev: any) => ({
                                                    ...prev,
                                                    term: e.target.value,
                                                }))
                                            }
                                            placeholder={t("global.search")}
                                        />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {page > 1 || (pagination as any)?.term !== "" ? (
                <>
                    <div className="row">
                        <div className="col-12">
                            <List
                                page={page}
                                handlePages={handlePages}
                                totalPages={totalPages}
                                list={list}
                                setTriggerUpdate={setTriggerUpdate}
                                editHandler={editHandler}
                                domain={domain}
                                setList={setList}
                                ctaHeaderBtnTitle={t('news.move_to_news_list')}
                                ctaHeaderBtnClick={moveToWaitingList}
                                selectedIds={selectedIds}
                                setSelectedIds={setSelectedIds}
                                handleCheckAll={handleCheckAll}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="row">
                        <div className="col-12">
                            <DraggableBootstrapTable
                                preview={false}
                                list={list}
                                setList={setList}
                                headers={["id", "theme", "title", "date"]}
                                title="news"
                                onEdit={editHandler}
                                selectedPrompt={selectedPrompt}
                                setSelectedPrompt={setSelectedPrompt}
                                handleShow={handleShow}
                                show={show}
                                setShow={setShow}
                                setTriggerUpdate={setTriggerUpdate}
                                ctaHeaderBtnTitle={t('news.move_to_news_list')}
                                ctaHeaderBtnClick={moveToWaitingList}
                                selectedIds={selectedIds}
                                setSelectedIds={setSelectedIds}
                                handleCheckAll={handleCheckAll}
                            />
                        </div>
                    </div>
                </>
            )}

            {page && (
                <div className="d-flex justify-content-end ">
                    {totalPages <= 1 ? (
                        ""
                    ) : (
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    )}
                </div>
            )}
        </>
    );
};
