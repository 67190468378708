import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Form } from "react-bootstrap";

import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";

import { useTranslation } from "react-i18next";
import { CreateForm } from "./CreateForm";
import { AdminNewsService } from "../service";
// import { AdminNewsThemeService } from "../service";

export const CreateNews = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState<any>({
    website: "",
    title: "",
    intro: "",
    content: "",
    date: "",
    hideDate: false,
    isVideoHeadLine: false,
    videoEmbed: null,
    script: "",
    isActive: true,
    redirectToUrl: "",
    subtitle: "",
    isKnowledgeNews: false,
    isEditorChoice: false,
    theme: null,
    themeText: "",
    profilePicture: null,
    series: "",
    embeddedCode: "",
    link1: "",
    link2: "",
    link3: "",
    mailed: false,
    categories: [],
    authors: [],
    brands: [],
    fileData: [
      {
        id: null,
        name: "",
        path: "",
        legend: "",
        credit: "",
        index: null,
      },
    ],
    additionalFiles: [],
  });

  const [files, setFiles] = useState<any[]>([]);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false);
  const [additionalFiles, setAdditionalFiles] = useState<any[]>();

  const submitHandler = (event: any) => {
    const newFileDataArr = state?.fileData
      ? state?.fileData?.filter((fileData: any) => fileData?.path !== "")
      : [];
    setState({ ...state, fileData: newFileDataArr });
    const filteredElements = files.filter((item1: any) =>
      newFileDataArr.some((item2: any) => item1.name == item2.name)
    );
    setDisabledSubmit(true);
    const formData = new FormData();
    for (let i = 0; i < filteredElements?.length; i++) {
      formData.append("file[]", filteredElements[i]);
    }
    if (additionalFiles) {
      for (let i = 0; i < additionalFiles.length; i++) {
        formData.append("additionalFile[]", additionalFiles[i]);
      }
    }

    formData.append(
      "body",
      JSON.stringify({ ...state, fileData: newFileDataArr })
    );
    AdminNewsService.create(formData)
      .then((response) => {
        SuccessToast(t(`event_cat.successfully_updated`));
        navigate("/admin/news");
        setState({});
      })
      .catch((error) => ErrorToast(error))
      .finally(() => setDisabledSubmit(false));
  };

  return (
    <div className="col-12" id="scrollable-form">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e);
        }}
      >
        <CreateForm
          disabledSubmit={disabledSubmit}
          submitHandler={submitHandler}
          state={state}
          setState={setState}
          files={files}
          setFiles={setFiles}
          additionalFiles={additionalFiles}
          setAdditionalFiles={setAdditionalFiles}
        />
      </Form>
    </div>
  );
};
